function* idMaker(): Generator<string, string, unknown> {
  let id = 1

  while (true) {
    id += 1
    yield id.toString()
  }
}

const gen = idMaker()

export function getUniqueId() {
  return gen.next().value
}
