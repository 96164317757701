import { AdSizeBean, AdSizeControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class AdSizeApi {
  api = new AdSizeControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ id, name, active, page }: ListFilters) =>
    this.api.adSizeFilteredList(id, name, active, { query: { size: LIST_PAGE_SIZE, page } })

  listAll = () => this.api.adSizeListAll()

  getByAdUnitId = (adUnitId?: number) => {
    if (!adUnitId) {
      return Promise.resolve([])
    }
    return this.api.adSizeListByAdUnitId(adUnitId)
  }

  entity = (id: number) => this.api.adSize(id)

  create = (bean: AdSizeBean) => this.api.createAdSize(bean)

  edit = (bean: AdSizeBean) => this.api.updateAdSize(bean, bean.id as number)

  delete = (id: number) => this.api.deleteAdSize(id)
}

export const adSizeApi = new AdSizeApi()
