import { SiteBean, SiteControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class SiteApi {
  api = new SiteControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ id, page, publisherId, active, name, siteType }: ListFilters) =>
    this.api.siteListByFilters(id, publisherId, active, name, siteType, { query: { size: LIST_PAGE_SIZE, page } })

  listAll = () => this.api.siteListAll()

  listByPublisher = (publisherId: number | undefined) =>
    publisherId ? this.api.siteListByPublisherId(publisherId) : Promise.resolve([])

  entityForList = (id: number) => this.api.siteForList(id)

  entity = (id: number) => this.api.site(id)

  create = (bean: SiteBean) => this.api.createSite(bean)

  edit = (bean: SiteBean) => this.api.updateSite(bean, bean.id as number)

  delete = (id: number) => this.api.deleteSite(id)

  generateAdsText = (id: number) => this.api.generateAdsTxt(id)
}

export const siteApi = new SiteApi()
