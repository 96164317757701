import { Col, Row, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { DirectAdBeanInList } from 'api/base/api'
import { directAdApi } from 'api/directAd'
import { directCampaignApi } from 'api/directCampaign'

import { boolToText } from 'utils/boolToText'
import {
  contentTypeToName,
  creativeTypeToName,
  directAdCreativeTypeToName,
  rtbDetailsTypeToName,
} from 'utils/enumToName'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  NumberFilter,
  SelectFilter,
  TextFilter,
  useListFilters,
  useSelectListEntity,
} from 'components/list'

import RtbDetailsTypeEnum = DirectAdBeanInList.RtbDetailsTypeEnum
import VideoStatusEnum = DirectAdBeanInList.VideoStatusEnum

export const DirectAdList: React.FC = () => {
  const location = useLocation<{ linkedEntityId: number | undefined }>()

  const { filters, setFilter } = useListFilters(
    location.state && {
      linkedEntityId: location.state.linkedEntityId,
    }
  )
  const [list, { totalEntities, isLoading }] = useGetList(() => directAdApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity(
    (id) => directAdApi.entityForList(id),
    routes.directAd.list,
    list
  )

  function videoStatusText(status: DirectAdBeanInList.VideoStatusEnum | undefined) {
    switch (status) {
      case VideoStatusEnum.CREATED:
        return 'Saved on the Server'
      case VideoStatusEnum.CONNECTEDWITHAD:
        return 'No Compression'
      case VideoStatusEnum.STARTEDCOMPRESSION:
        return 'Compression Started'
      case VideoStatusEnum.COMPRESSIONFAILED:
        return 'Compression Failed'
      case VideoStatusEnum.COMPRESSED:
        return 'Compressed'
      default:
        return ''
    }
  }

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(2)

  const columns: ColumnsType<DirectAdBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
      filterIcon: <FilterIcon active={!!filters.id} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.id} onChange={(value) => setFilter('id', value)} filterProps={props} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.directAd.edit(id)}>{name}</ListLink>,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Creative Type',
      dataIndex: 'directAdCreativeType',
      width: columnWidth,
      ellipsis: true,
      render: (_, { directAdCreativeType }) => directAdCreativeTypeToName(directAdCreativeType),
      filterIcon: <FilterIcon active={filters.creativeType !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.creativeType}
          onChange={(value) => setFilter('creativeType', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Banner', value: String(DirectAdBeanInList.DirectAdCreativeTypeEnum.BANNER) },
              { text: 'Video', value: String(DirectAdBeanInList.DirectAdCreativeTypeEnum.VIDEO) },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Ad Size',
      width: columnWidth,
      ellipsis: true,
      render: (_, { adSizeName }) => adSizeName,
      filterIcon: <FilterIcon active={!!filters.adSizeName} />,
      filterDropdown: (props) => (
        <TextFilter
          value={filters.adSizeName}
          onChange={(value) => setFilter('adSizeName', value)}
          filterProps={props}
        />
      ),
    },
    {
      title: 'Traffic Type',
      dataIndex: 'rtbDetailsType',
      width: columnWidth,
      ellipsis: true,
      render: (_, { rtbDetailsType }) => rtbDetailsTypeToName(rtbDetailsType),
      filterIcon: <FilterIcon active={filters.siteType !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.siteType}
          onChange={(value) => setFilter('directAdType', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Site', value: String(RtbDetailsTypeEnum.SITE) },
              { text: 'App', value: String(RtbDetailsTypeEnum.APP) },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Campaigns',
      dataIndex: 'linkedCampaignsCount',
      align: 'center',
      width: columnWidth,
      render: (_, { linkedCampaigns }) => (
        <Tooltip title={linkedCampaigns?.join(',\n') || 'No linked campaigns'}>
          <div style={{ minWidth: 5 }}>{linkedCampaigns?.length || 0}</div>
        </Tooltip>
      ),
      filterIcon: <FilterIcon active={filters.linkedEntityId !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.linkedEntityId}
          onChange={(value) => setFilter('linkedEntityId', value)}
          getOptions={() =>
            directCampaignApi
              .allNamed()
              .then((beans) => beans.map(({ id, name }) => ({ text: name as string, value: id })))
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: columnWidth,
      ellipsis: true,
      render: (_, { active }) => boolToText(active),
      filterIcon: <FilterIcon active={filters.active !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.active}
          onChange={(value) => setFilter('active', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Active', value: true },
              { text: 'Inactive', value: false },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
  ]

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="direct ad"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.directAd.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.directAd.edit(selected.id)}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Active', value: boolToText(selected.active) },
                  { name: 'Name', value: selected.name },
                  { name: 'Creative type', value: creativeTypeToName(selected.directAdCreativeType) },
                  { name: 'Content type', value: contentTypeToName(selected.contentType) },
                  { name: 'Ad size', value: selected.adSizeName },
                  { name: 'Click url', value: selected.clickUrl },
                  { name: 'Traffic Type', value: rtbDetailsTypeToName(selected.rtbDetailsType) },
                  { name: 'Direct Campaigns', value: selected.linkedCampaigns?.join(', ') },
                  ...(selected.videoStatus
                    ? [{ name: 'Video status', value: videoStatusText(selected.videoStatus) }]
                    : []),
                ],
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
