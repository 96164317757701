import { AdUnitBean, AdUnitControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

import AdUnitTypeEnum = AdUnitBean.AdUnitTypeEnum
import AuctionTypeEnum = AdUnitBean.AuctionTypeEnum

function adUnitTypeToFilterValue(type: AdUnitBean.AdUnitTypeEnum | undefined) {
  switch (type) {
    case AdUnitTypeEnum.BANNER:
      return 'BANNER'
    case AdUnitTypeEnum.VIDEO:
      return 'VIDEO'
    case AdUnitTypeEnum.MULTI:
      return 'MULTI'
    case AdUnitTypeEnum.NATIVE:
      return 'NATIVE'
    default:
      return ''
  }
}

function auctionTypeToFilterValue(type: AdUnitBean.AuctionTypeEnum | undefined) {
  switch (type) {
    case AuctionTypeEnum.FIRSTPRICE:
      return 'FIRST_PRICE'
    case AuctionTypeEnum.SECONDPRICE:
      return 'SECOND_PRICE'
    default:
      return ''
  }
}

export interface AdUnitFilters {
  siteId: number | undefined
  name: string | undefined
  bidFloor: number | undefined
  adUnitType: AdUnitBean.AdUnitTypeEnum | undefined
  auctionType: AdUnitBean.AuctionTypeEnum | undefined
  privateAuction: boolean | undefined
  test: boolean | undefined
  active: boolean | undefined
}

class AdUnitApi {
  api = new AdUnitControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ id, publisherId, siteId, active, test, name, page }: ListFilters) =>
    this.api.filteredAdUnitPage(id, publisherId, siteId, active, test, name, { query: { size: LIST_PAGE_SIZE, page } })

  listAll = () => this.api.adUnitListAll()

  filteredList = ({ siteId, name, bidFloor, adUnitType, auctionType, privateAuction, test, active }: AdUnitFilters) =>
    this.api.filteredAdUnitList(
      siteId,
      name,
      bidFloor,
      adUnitTypeToFilterValue(adUnitType),
      auctionTypeToFilterValue(auctionType),
      privateAuction,
      test,
      active
    )

  entityForList = (id: number) => this.api.adUnitForList(id)

  entity = (id: number) => this.api.adUnit(id)

  create = (bean: AdUnitBean) => this.api.createAdUnit(bean)

  edit = (bean: AdUnitBean) => this.api.updateAdUnit(bean, bean.id as number)

  delete = (id: number) => this.api.deleteAdUnit(id)
}

export const adUnitApi = new AdUnitApi()
