import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { adUnitApi } from 'api/adUnit'
import { DealBean } from 'api/base/api'
import { dealApi } from 'api/deal'
import { publisherApi } from 'api/publisher'
import { siteApi } from 'api/site'

import { useGetList } from 'utils/useGetList'

import { getDefaultFilterBean, useFormState } from 'components/form'

import DealTypeEnum = DealBean.DealTypeEnum

export function useDealFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<DealBean>()

  useEffect(() => {
    if (id) {
      dealApi.entity(+id).then((bean) => {
        setFormName(bean.name)
        setFormState({
          ...bean,
          filterBean: bean.filterBean ?? getDefaultFilterBean(),
        })
      })
    } else {
      setFormState({
        name: '',
        dealType: DealTypeEnum.PRIVATEAUCTION,
        filterBean: getDefaultFilterBean(),
      })
    }
  }, [])

  const [publisherList] = useGetList(() => publisherApi.listAll())
  const [siteList] = useGetList(() => siteApi.listAll())
  const [adUnitList] = useGetList(() => adUnitApi.listAll())

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
    publisherList,
    siteList,
    adUnitList,
  }
}
