import dayjs from 'dayjs'

import { DashboardStatRequestBean, FilterEntry, PublisherBean, StatRequestBean, StatsFilter } from 'api/base/api'

import { useFormStateWithInitState } from 'components/form/utils/useFormState'

import PeriodTypeEnum = StatRequestBean.PeriodTypeEnum

const { UserCurrencyEnum } = PublisherBean
const { TypeEnum } = StatsFilter

export function useDashboardFormData() {
  const [formState, setFormState] = useFormStateWithInitState<DashboardStatRequestBean>({
    currency: UserCurrencyEnum.USD.toString(),
    startDate: dayjs().utc().format('YYYY-MM-DD'),
    endDate: dayjs().utc().format('YYYY-MM-DD'),
    periodType: PeriodTypeEnum.TODAY,
    filters: [
      { keys: [FilterEntry.KeysEnum.PublisherName], filter: { type: TypeEnum.INLIST, params: [] } },
      { keys: [FilterEntry.KeysEnum.SiteName], filter: { type: TypeEnum.INLIST, params: [] } },
      { keys: [FilterEntry.KeysEnum.DspName], filter: { type: TypeEnum.INLIST, params: [] } },
      { keys: [FilterEntry.KeysEnum.AdUnitName], filter: { type: TypeEnum.INLIST, params: [] } },
      { keys: [FilterEntry.KeysEnum.Device], filter: { type: TypeEnum.INLIST, params: [] } },
      { keys: [FilterEntry.KeysEnum.Country], filter: { type: TypeEnum.INLIST, params: [] } },
    ],
    audit: false,
  })

  return {
    isFormLoading: !formState,
    formState: formState ?? {},
    setFormState,
  }
}
