import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { DealList } from 'views/deal/DealList'
import { DealForm } from 'views/deal/form/DealForm'

export const DealRoot: React.FC = () => (
  <Switch>
    <Route path={routes.deal.list('forRoute')} component={DealList} />
    <Route path={routes.deal.create} component={DealForm} />
    <Route path={routes.deal.edit()} component={DealForm} />
    <Redirect from="*" to={routes.deal.list()} />
  </Switch>
)
