import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

import { accountApi } from 'api/account'
import { AccountBeanInList } from 'api/base/api'

import { roleToName } from 'utils/enumToName'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  useListFilters,
  useSelectListEntity,
} from 'components/list'

export const AccountList: React.FC = () => {
  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => accountApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity(
    (id) => accountApi.entityForList(id),
    routes.account.list,
    list
  )

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(2)

  const columns: ColumnsType<AccountBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.account.edit(id)}>{name}</ListLink>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: columnWidth,
      ellipsis: true,
      render: (text) => roleToName(text),
    },
  ]

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="account"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.account.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.account.edit(selected.id)}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Name', value: selected.name },
                  { name: 'Email', value: selected.email },
                  { name: 'Role', value: selected.role },
                  { name: 'Publisher', value: selected.publisherName },
                ],
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
