import React from 'react'
import { useHistory } from 'react-router-dom'

import { adUnitApi } from 'api/adUnit'
import { AdUnitBean } from 'api/base/api'

import { routes } from 'utils/routes'

import { FormActionButtons, FormContainer } from 'components/form'
import { FormTypedDspMappingTab } from 'components/form/tabs/FormTypedDspMappingTab'

import { MainTab } from 'views/adUnit/form/MainTab'
import { useAdUnitFormData } from 'views/adUnit/form/useAdUnitFormData'

export const AdUnitForm: React.FC = () => {
  const history = useHistory()
  const {
    isCreation,
    isFormLoading,
    formName,
    formState,
    setFormState,
    dspList,
    siteList,
    adSizeList,
    dealList,
    campaignList,
  } = useAdUnitFormData()

  const redirectToList = () => {
    history.push(routes.adUnit.list())
  }

  const submit = () => {
    const bean = formState as AdUnitBean

    if (isCreation) {
      adUnitApi.create(bean).then(redirectToList)
    } else {
      adUnitApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    adUnitApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer
      title={isCreation ? 'New ad unit' : formName}
      isLoading={isFormLoading}
      tabs={[
        {
          name: 'Main',
          tab: (
            <MainTab
              formState={formState}
              setFormState={setFormState}
              isCreation={isCreation}
              dspList={dspList}
              siteList={siteList}
              adSizeList={adSizeList}
              dealList={dealList}
              campaignList={campaignList}
            />
          ),
        },
        // filters removed to avoid conflicts with direct campaigns
        {
          name: 'DSP ID Mapping',
          tab: (
            <FormTypedDspMappingTab
              entityId={formState.id}
              dspList={dspList}
              mappingList={formState.dspIdMappings || []}
              setMappingList={(dspIdMappings) => setFormState({ ...formState, dspIdMappings })}
            />
          ),
        },
      ]}
    >
      <FormActionButtons isCreation={isCreation} onBack={redirectToList} onDelete={remove} onSubmit={submit} />
    </FormContainer>
  )
}
