import { Col, Row, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React from 'react'

import { DirectCampaignBeanInList } from 'api/base/api'
import { directCampaignApi } from 'api/directCampaign'

import { boolToText } from 'utils/boolToText'
import { cappingTypeToName, passingTypeToName } from 'utils/enumToName'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  NumberFilter,
  SelectFilter,
  TextFilter,
  useFiltersOverviewItems,
  useListFilters,
  useSelectListEntity,
} from 'components/list'

export const DirectCampaignList: React.FC = () => {
  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => directCampaignApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity(
    (id) => directCampaignApi.entityForList(id),
    routes.directCampaign.list,
    list
  )

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(2)

  const columns: ColumnsType<DirectCampaignBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
      filterIcon: <FilterIcon active={!!filters.id} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.id} onChange={(value) => setFilter('id', value)} filterProps={props} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.directCampaign.edit(id)}>{name}</ListLink>,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Start time',
      dataIndex: 'startedAt',
      width: columnWidth,
      ellipsis: true,
      render: (_, { startedAt }) => dayjs(startedAt).format('DD MMM YYYY HH:mm'),
    },
    {
      title: 'End time',
      dataIndex: 'endedAt',
      width: columnWidth,
      ellipsis: true,
      render: (_, { endedAt }) => (endedAt ? dayjs(endedAt).format('DD MMM YYYY HH:mm') : 'Unlimited'),
    },
    {
      title: 'Limit',
      dataIndex: 'impressionLimit',
      width: columnWidth,
      ellipsis: true,
      render: (_, { impressionLimit }) => impressionLimit,
    },
    {
      title: 'CPM',
      dataIndex: 'usdCpm',
      width: columnWidth,
      ellipsis: true,
      render: (_, { usdCpm }) => `$ ${usdCpm}`,
    },
    {
      title: 'Direct Ads',
      dataIndex: 'linkedAds',
      width: columnWidth,
      ellipsis: true,
      render: (_, { id, linkedAdNames }) => (
        <ListLink
          to={{
            pathname: routes.directAd.list(),
            state: {
              linkedEntityId: id,
            },
          }}
        >
          <Tooltip title="Show Direct Ads">{linkedAdNames?.length || 0}</Tooltip>
        </ListLink>
      ),
    },
    {
      title: 'Ad Units',
      dataIndex: 'linkedAdUnits',
      width: columnWidth,
      ellipsis: true,
      render: (_, { linkedAdUnitNames }) => (
        <Tooltip title={linkedAdUnitNames?.join(',\n') || 'No linked ad units'}>
          <div style={{ minWidth: 5 }}>{linkedAdUnitNames?.length || 0}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: columnWidth,
      ellipsis: true,
      render: (_, { active }) => boolToText(active),
      filterIcon: <FilterIcon active={filters.active !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.active}
          onChange={(value) => setFilter('active', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Active', value: true },
              { text: 'Inactive', value: false },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
  ]

  const filtersOverviewItems = useFiltersOverviewItems(selected?.targeting)

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="direct campaign"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.directCampaign.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.directCampaign.edit(selected.id)}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Active', value: boolToText(selected.active) },
                  { name: 'Name', value: selected.name },
                  { name: 'CPM $', value: selected.usdCpm },
                  { name: 'Impression Limit', value: selected.impressionLimit },
                  { name: 'Started at', value: dayjs(selected.startedAt).format('DD MMM YYYY HH:mm') },
                  { name: 'Ended at', value: dayjs(selected.endedAt).format('DD MMM YYYY HH:mm') },
                  { name: 'Capping type', value: cappingTypeToName(selected.cappingType) },
                  { name: 'Passing type', value: passingTypeToName(selected.cappingPassingType) },
                  { name: 'Ad Units', value: selected.linkedAdUnitNames?.join(', ') },
                  { name: 'Direct Ads', value: selected.linkedAdNames?.join(', ') },
                ],
              },
              {
                name: 'Filters',
                items: filtersOverviewItems,
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
