import { Col, Modal, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { accountApi } from 'api/account'

import { userUpdateStore } from 'utils/userUpdateStore'

export const LogOut: React.FC = () => {
  const history = useHistory()
  const [shouldLogOut, setShouldLogOut] = useState(false)

  useEffect(() => {
    if (!shouldLogOut) return

    accountApi.logout().then(userUpdateStore.updateInfo)
  }, [shouldLogOut])

  return (
    <>
      <Modal
        title="Confirm log out"
        open={!shouldLogOut}
        onOk={() => setShouldLogOut(true)}
        onCancel={() => history.goBack()}
      >
        <p>Are you sure you want to log out?</p>
      </Modal>

      {shouldLogOut && (
        <Row style={{ height: '100%' }} justify="space-around" align="middle">
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      )}
    </>
  )
}
