import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  to: string | { pathname: string; state: object }
  children: React.ReactNode
}

export const ListLink: React.FC<Props> = ({ to, children }) => (
  <Link onClick={(e) => e.stopPropagation()} to={to}>
    {children}
  </Link>
)
