import { CountrySubdivisionControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { cacheApiMethod } from 'utils/cacheApiMethod'

class CountrySubdivisionApi {
  api = new CountrySubdivisionControllerApi(configuration, configuration.basePath, customFetch)

  listAll = () => cacheApiMethod('countries', () => this.api.subdivisions())

  listByCountryId = (countryIds: number[]) => this.api.countrySubdivisions(countryIds)
}

export const countrySubdivisionApi = new CountrySubdivisionApi()
