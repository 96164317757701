import React from 'react'

import { DealBean, NamedBean, SimpleAdUnitBean, SimpleSiteBean } from 'api/base/api'

import { biddingCurrencyToName } from 'utils/enumToName'

import { FormNumberInput, FormRow, FormSelect, FormTextAreaInput, FormTextInput } from 'components/form'

import DealTypeEnum = DealBean.DealTypeEnum

const { AuctionTypeEnum } = DealBean

interface Props {
  formState: Partial<DealBean>
  setFormState: (formState: Partial<DealBean>) => void
  isCreation: boolean
  publisherList: NamedBean[]
  siteList: SimpleSiteBean[]
  adUnitList: SimpleAdUnitBean[]
}

export const MainTab: React.FC<Props> = ({
  formState,
  setFormState,
  isCreation,
  publisherList,
  siteList,
  adUnitList,
}) => {
  const ownerSiteIds = siteList
    .filter((site) => site.publisherId === formState.publisherOwnerId || formState.publisherOwnerId === undefined)
    .map((site) => site.id)
  return (
    <>
      <FormRow>
        <FormTextInput
          span={8}
          label="Public id"
          value={formState.dealId}
          onChange={(dealId) => setFormState({ dealId })}
          required
          inputProps={{ disabled: !isCreation }}
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          label="Owner"
          value={formState.publisherOwnerId}
          onChange={(publisherOwnerId) => setFormState({ publisherOwnerId })}
          options={publisherList.map((publisher) => ({ value: publisher.id, label: publisher.name }))}
          disabled={!isCreation}
          required
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Name"
          value={formState.name}
          onChange={(name) => setFormState({ name })}
          required
        />
      </FormRow>

      <FormRow>
        <FormNumberInput
          span={8}
          label="Bid floor"
          value={formState.bidFloor}
          onChange={(bidFloor) => setFormState({ bidFloor })}
          required
          inputProps={{
            prefix: biddingCurrencyToName(
              siteList.find((site) => site.publisherId === formState.publisherOwnerId)?.biddingCurrency
            ),
            min: 0,
          }}
        />
      </FormRow>

      <FormRow style={{ alignItems: 'center' }}>
        <FormSelect
          span={4}
          label="Auction type"
          value={formState.auctionType}
          onChange={(auctionType) => setFormState({ auctionType })}
          options={[
            { value: AuctionTypeEnum.FIRSTPRICE, label: 'First price' },
            { value: AuctionTypeEnum.SECONDPRICE, label: 'Second price' },
          ]}
          required
        />
        <FormSelect
          span={4}
          label="Prefering Deals"
          value={formState.dealType}
          onChange={(dealType) => setFormState({ dealType })}
          options={[
            { value: DealTypeEnum.PREFERREDDEAL, label: 'Preferred Deal' },
            { value: DealTypeEnum.PRIVATEAUCTION, label: 'Private Auction' },
          ]}
          required
        />
      </FormRow>

      <FormRow>
        <FormTextAreaInput
          span={8}
          textAreaProps={{ autoSize: { minRows: 3, maxRows: 5 } }}
          label="White list seat ids (comma-separated list)"
          value={formState.whiteListSeatIds}
          onChange={(whiteListSeatIds) => setFormState({ ...formState, whiteListSeatIds })}
        />
      </FormRow>

      <FormRow>
        <FormTextAreaInput
          span={8}
          textAreaProps={{ autoSize: { minRows: 3, maxRows: 5 } }}
          label="White list domains (comma-separated list)"
          value={formState.whiteListDomains}
          onChange={(whiteListDomains) => setFormState({ ...formState, whiteListDomains })}
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          label="Ad Units"
          value={formState.linkedAdUnitIds}
          onChange={(linkedAdUnitIds) => setFormState({ linkedAdUnitIds })}
          options={adUnitList
            .filter((adUnit) => ownerSiteIds.includes(adUnit.siteId))
            .map((adUnit) => ({ value: adUnit.id, label: adUnit.name }))}
          selectProps={{
            mode: 'multiple',
            allowClear: true,
          }}
        />
      </FormRow>
    </>
  )
}
