import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { accountApi } from 'api/account'
import { AccountBean } from 'api/base/api'

import { createMessage } from 'utils/createMessage'
import { routes } from 'utils/routes'

import { FormActionButtons, FormContainer, FormRow, FormSelect, FormTextInput } from 'components/form'

import { useAccountFormData } from 'views/account/form/useAccountFormData'

const { RoleEnum } = AccountBean

export const AccountForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formState, formName, setFormState, publisherList } = useAccountFormData()

  const [passwordConfirmed, setPasswordConfirmed] = useState('')

  const redirectToList = () => {
    history.push(routes.account.list())
  }

  const submit = () => {
    const bean = formState as AccountBean

    if (passwordConfirmed !== formState.password && (passwordConfirmed || formState.password)) {
      createMessage('error', 'Passwords do not match')
      return
    }

    if (isCreation) {
      accountApi.create(bean).then(redirectToList)
    } else {
      accountApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    accountApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer title={isCreation ? 'New account' : formName} isLoading={isFormLoading}>
      <FormRow>
        <FormTextInput
          span={8}
          label="E-mail"
          value={formState.email}
          onChange={(email) => setFormState({ email })}
          required
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Name"
          value={formState.name}
          onChange={(name) => setFormState({ name })}
          required
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Password"
          value={formState.password}
          onChange={(password) => setFormState({ password })}
          inputProps={{ type: 'Password' }}
          itemProps={{ required: isCreation }}
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Confirm password"
          value={passwordConfirmed}
          onChange={(password) => setPasswordConfirmed(password)}
          inputProps={{ type: 'Password' }}
          itemProps={{ required: isCreation }}
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          label="Role"
          value={formState.role}
          onChange={(role) => setFormState({ role, publisherId: undefined })}
          options={[
            { value: RoleEnum.Admin, label: 'Admin' },
            { value: RoleEnum.Publisher, label: 'Publisher' },
          ]}
          required
        />
      </FormRow>

      {formState.role === RoleEnum.Publisher && (
        <FormRow>
          <FormSelect
            span={8}
            label="Publisher"
            value={formState.publisherId}
            onChange={(publisherId) => setFormState({ publisherId })}
            options={publisherList.map((publisher) => ({ value: publisher.id, label: publisher.name }))}
            required
          />
        </FormRow>
      )}

      <FormActionButtons isCreation={isCreation} onBack={redirectToList} onDelete={remove} onSubmit={submit} />
    </FormContainer>
  )
}
