import { Col, DatePicker, Form } from 'antd'
import { Dayjs } from 'dayjs'
import React from 'react'

interface Props {
  span: string | number
  label?: string
  value?: Dayjs
  onChange: (date: Dayjs) => void
  required?: boolean
  disabled?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  datePickerProps?: React.ComponentProps<typeof DatePicker>
}

export const FormDatePicker: React.FC<Props> = ({
  span,
  label,
  value,
  onChange,
  required,
  disabled,
  colProps,
  itemProps,
  datePickerProps,
}) => (
  <Col span={span} {...colProps}>
    <Form.Item label={label} required={required} {...itemProps}>
      <DatePicker
        value={value}
        format="DD MMM YYYY"
        onChange={(date) => (date != null ? onChange(date) : null)}
        disabled={disabled}
        {...datePickerProps}
        style={{ ...datePickerProps?.style, width: '100%' }}
      />
    </Form.Item>
  </Col>
)
