import { Col, Divider } from 'antd'
import React from 'react'

import { PublisherBean } from 'api/base/api'
import { categoriesApi } from 'api/categories'

import { useGetList } from 'utils/useGetList'

import { FormRow } from 'components/form/containers/FormRow'
import { FormSelect } from 'components/form/inputs/FormSelect'
import { FormCategoriesInput } from 'components/form/tabs/formCategoriesTab/FormCategoriesInput'

const { IabTaxonomyVersionEnum } = PublisherBean

interface Props {
  version: PublisherBean.IabTaxonomyVersionEnum | undefined
  setVersion: (version: PublisherBean.IabTaxonomyVersionEnum | undefined) => void
  linked?: number[] | undefined
  setLinked?: (linked: number[] | undefined) => void
  blocked?: number[]
  setBlocked?: (blocked: number[] | undefined) => void
}

export const FormCategoriesTab: React.FC<Props> = ({ version, setVersion, linked, setLinked, blocked, setBlocked }) => {
  const [version1List] = useGetList(() => categoriesApi.list(IabTaxonomyVersionEnum._10))
  const [version2List] = useGetList(() => categoriesApi.list(IabTaxonomyVersionEnum._22))

  const source = version === IabTaxonomyVersionEnum._10 ? version1List : version2List
  const options = source.map((bean) => ({ value: bean.id as number, label: bean.name as string }))

  return (
    <>
      <FormRow>
        <FormSelect
          span={8}
          label="Taxonomy version"
          value={version}
          onChange={(version) => {
            setVersion(version)
            setLinked?.([])
            setBlocked?.([])
          }}
          options={[
            { value: IabTaxonomyVersionEnum._10, label: '1.0' },
            { value: IabTaxonomyVersionEnum._22, label: '2.2' },
          ]}
        />
      </FormRow>

      {!!setLinked && (
        <>
          <FormRow>
            <Col span={8}>
              <Divider orientation="left" style={{ marginTop: 0 }}>
                Linked Categories
              </Divider>
            </Col>
          </FormRow>

          <FormCategoriesInput
            categories={linked ?? []}
            setCategories={setLinked}
            options={options}
            disabled={!version}
          />
        </>
      )}

      {!!setBlocked && (
        <>
          <FormRow>
            <Col span={8}>
              <Divider orientation="left" style={{ marginTop: 0 }}>
                Blocked Categories
              </Divider>
            </Col>
          </FormRow>

          <FormCategoriesInput
            categories={blocked ?? []}
            setCategories={setBlocked}
            options={options}
            disabled={!version}
          />
        </>
      )}
    </>
  )
}
