import { CurrencyControllerApi, PaymentBean, PaymentControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class PaymentApi {
  api = new PaymentControllerApi(configuration, configuration.basePath, customFetch)

  currencyApi = new CurrencyControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ range, publisherId, period, status, page }: ListFilters) =>
    this.api.filteredPaymentList(range, publisherId, period?.format('MMM YYYY'), status, {
      query: { size: LIST_PAGE_SIZE, page },
    })

  entity = (id: number) => this.api.retrieve(id)

  create = (bean: PaymentBean) => this.api.create(bean)

  edit = (bean: PaymentBean) => this.api.update(bean)

  delete = (id: number) => this.api._delete(id)

  getKRWRate = (date: string) => this.currencyApi.retrieve1(date)
}

export const paymentApi = new PaymentApi()
