import { GenerateTagFormData } from 'views/generateTag/useGenerateTagFormData'

export const generateTag = (formData: GenerateTagFormData) => {
  const adapterTemplate = `bidder: 'admaru',
params: {
   siteId: '${formData.siteId}',
   adUnitId: '${formData.adUnitId}'
}`

  const jsTemplate = `<script async src="${process.env.REACT_APP_TAG_ENDPOINT}"></script>
<script>
   var admarutag = admarutag || {};
   admarutag.cmd = admarutag.cmd || [];
   admarutag.cmd.push(function() {
      admarutag.add({site: ${formData.siteId}, adunit: ${formData.adUnitId}, div: 'admarutag-${formData.adUnitId}'});
      admarutag.request();
   });
</script>
// inject the line below in the place where you want to show the ad
<div id="admarutag-${formData.adUnitId}"></div>`

  const vastUrlTemplate = `${formData.sspUrl}/bid/vast?siteId=${formData.siteId}&adUnitId=${formData.adUnitId}&nocache=[NOCACHE]`

  switch (formData.type) {
    case 'Prebid.js adapter':
      return adapterTemplate
    case 'JavaScript Tag':
      return jsTemplate
    case 'VAST URL':
      return vastUrlTemplate
    default:
      return 'Error occurred at tag generation'
  }
}
