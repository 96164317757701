import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, InputRef, Space } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  value: string
  onChange: (value: string) => void
  filterProps: FilterDropdownProps
}

export const TextFilter: React.FC<Props> = ({ value, onChange, filterProps }) => {
  const [tempValue, setTempValue] = useState(value)

  const filter = () => {
    onChange(tempValue)
    filterProps.close()
  }

  const reset = () => {
    setTempValue('')
    onChange('')
    filterProps.close()
  }

  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    setTimeout(() => inputRef.current?.focus())
  }, [filterProps.visible])

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={inputRef}
        placeholder="Search"
        value={tempValue}
        onChange={(e) => setTempValue(e.target.value)}
        onPressEnter={filter}
        style={{ marginBottom: 8, display: 'block' }}
      />

      <Space>
        <Button type="primary" onClick={filter} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
          Search
        </Button>

        <Button onClick={reset} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  )
}
