import { Card, Col, Input, InputRef, Row, Table, Typography } from 'antd'
import { ColumnType } from 'antd/es/table'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'

import { PublisherBean, RawStatRow } from 'api/base/api'

import { getUniqueId } from 'utils/getUniqueId'

import { FilterIcon } from 'components/list'

import { ReportDictionaryElem, ReportResultData } from 'views/report/utils/reportTypes'

import styles from 'views/report/result/reportResult.module.scss'

const { UserCurrencyEnum } = PublisherBean

interface Props {
  data: ReportResultData
}

export const ReportResult: React.FC<Props> = ({ data }) => {
  const { keys, values, response, currency } = data

  const formatValue = (value: RawStatRow[keyof RawStatRow], format: ReportDictionaryElem['format']) => {
    switch (format) {
      case 'rate':
        return `${value?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 0}%`
      case 'money':
        return `${currency === UserCurrencyEnum.USD ? '$' : '₩'}${value?.toLocaleString('en-US', {
          maximumFractionDigits: 4,
        })}`
      case 'number':
        return value?.toLocaleString('en-US', { maximumFractionDigits: 0 })
      default:
        return value
    }
  }

  const searchInput = useRef<InputRef>(null)

  const columns: ColumnType<RawStatRow>[] = [...keys, ...values].map((elem) => {
    const getValue = (row: RawStatRow) =>
      row[elem.value as unknown as keyof RawStatRow] ?? (elem.format === 'string' ? '' : 0)

    return {
      title: <div className={`${styles.cell} ${styles.cell_title}`}>{elem.label}</div>,
      dataIndex: elem.value,
      render: (value) => <div className={styles.cell}>{formatValue(value, elem.format)}</div>,

      sorter: (a, b) => (getValue(a) === getValue(b) ? 0 : getValue(a) > getValue(b) ? 1 : -1),

      filterIcon: (filtered) => <FilterIcon active={filtered} />,
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => (
        <div className={styles.filterWrapper}>
          <Input
            ref={searchInput}
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({ closeDropdown: false })
            }}
          />
        </div>
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100)
        }
      },
      onFilter: (value, row) =>
        formatValue(getValue(row), elem.format)
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()) ?? false,
    }
  })

  const summary = (
    <Table.Summary.Row>
      {keys.map((elem, index) => (
        <Table.Summary.Cell key={elem.value} index={0}>
          {index === 0 && (
            <Typography.Text strong className={styles.cell}>
              Total
            </Typography.Text>
          )}
        </Table.Summary.Cell>
      ))}

      {values.map((elem) => {
        const value = response.total?.[elem.value]

        return (
          <Table.Summary.Cell key={elem.value} index={0}>
            <Typography.Text strong className={styles.cell}>
              {typeof value === 'number' && formatValue(value, elem.format)}
            </Typography.Text>
          </Table.Summary.Cell>
        )
      })}
    </Table.Summary.Row>
  )

  const csvData = [
    [...keys, ...values].map((elem) => elem.label),
    ...(response.rows?.map((row) =>
      [...keys, ...values].map((elem) => formatValue(row[elem.value as unknown as keyof RawStatRow], 'non-format'))
    ) ?? []),
  ]

  const [tableKey, setTableKey] = useState(1)
  useEffect(() => setTableKey((key) => key + 1), [response])

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Col style={{ fontWeight: '400', fontSize: '18px' }}>Result on {dayjs().format('HH:mm, DD MMM YYYY')}</Col>

          <Col>
            <CSVLink filename={`Report on ${dayjs().format('HH-mm, DD MMM YYYY')}`} data={csvData}>
              Download as CSV
            </CSVLink>
          </Col>
        </Row>
      }
      headStyle={{ border: 'none' }}
    >
      <Table
        key={tableKey}
        columns={columns}
        dataSource={response.rows}
        rowKey={() => getUniqueId()}
        summary={() => summary}
        className={styles.table}
        scroll={{ x: true }}
        bordered
        pagination={{ pageSize: 100, position: ['bottomCenter'] }}
        getPopupContainer={(trigger) => trigger.parentNode?.parentNode as HTMLElement}
      />
    </Card>
  )
}
