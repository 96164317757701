import { Col, Row, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { SiteBean, SiteBeanInList } from 'api/base/api'
import { publisherApi } from 'api/publisher'
import { siteApi } from 'api/site'

import { boolToText } from 'utils/boolToText'
import { downloadFile } from 'utils/downloadFile'
import { rtbDetailsTypeToName } from 'utils/enumToName'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  NumberFilter,
  SelectFilter,
  TextFilter,
  useFiltersOverviewItems,
  useListFilters,
  useSelectListEntity,
} from 'components/list'
import { useCategoriesOverviewItems } from 'components/list/utils/useCategoriesOverviewItems'
import { useDspMappingOverviewItems } from 'components/list/utils/useDspMappingOverviewItems'

import RtbDetailsTypeEnum = SiteBean.RtbDetailsTypeEnum

export const SiteList: React.FC = () => {
  const location = useLocation<{ publisherId: number | undefined }>()
  const { filters, setFilter } = useListFilters(location.state && { publisherId: location.state.publisherId })
  const [list, { totalEntities, isLoading }] = useGetList(() => siteApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity((id) => siteApi.entityForList(id), routes.site.list, list)

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(3)

  const columns: ColumnsType<SiteBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
      filterIcon: <FilterIcon active={!!filters.id} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.id} onChange={(value) => setFilter('id', value)} filterProps={props} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.site.edit(id)}>{name}</ListLink>,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Publisher',
      dataIndex: 'publisherId',
      width: columnWidth,
      ellipsis: true,
      render: (_, { publisherName }) => publisherName,
      filterIcon: <FilterIcon active={!!filters.publisherId} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.publisherId}
          onChange={(value) => setFilter('publisherId', value)}
          getOptions={() =>
            publisherApi.listAll().then((beans) => beans.map(({ id, name }) => ({ text: name as string, value: id })))
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: columnWidth,
      ellipsis: true,
      render: (_, { active }) => boolToText(active),
      filterIcon: <FilterIcon active={filters.active !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.active}
          onChange={(value) => setFilter('active', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Active', value: true },
              { text: 'Inactive', value: false },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Type',
      dataIndex: 'rtbDetailsType',
      width: columnWidth,
      ellipsis: true,
      render: (_, { rtbDetailsType }) => rtbDetailsTypeToName(rtbDetailsType),
      filterIcon: <FilterIcon active={filters.siteType !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.siteType}
          onChange={(value) => setFilter('siteType', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Site', value: String(RtbDetailsTypeEnum.SITE) },
              { text: 'App', value: String(RtbDetailsTypeEnum.APP) },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Ad Units',
      align: 'center',
      width: columnWidth,
      render: (_, { id, publisherId, adUnitCount }) => (
        <ListLink
          to={{
            pathname: routes.adUnit.list(),
            state: {
              publisherId,
              siteId: id,
            },
          }}
        >
          <Tooltip title="Show ad units">{adUnitCount}</Tooltip>
        </ListLink>
      ),
    },
  ]

  const filtersOverviewItems = useFiltersOverviewItems(selected?.filterBean)
  const categoriesOverviewItems = useCategoriesOverviewItems(
    selected?.iabTaxonomyVersion,
    selected?.categories ?? [],
    selected?.blockedCategories ?? []
  )
  const dspMappingOverviewItems = useDspMappingOverviewItems(selected?.dspIdMappingBeans)

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="site/app"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.site.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.site.edit(selected.id)}
            additionalLinks={[
              {
                name: 'Generate "ads.txt"',
                onClick: () =>
                  siteApi
                    .generateAdsText(selected.id as number)
                    .then((data) => downloadFile('ads.txt', new Blob([data.adsTxt], { type: 'text/plain' }))),
              },
              { name: 'Create ad unit', to: routes.adUnit.createWithSite(selected.id) },
            ]}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Name', value: selected.name },
                  { name: 'Active', value: boolToText(selected.active) },
                  { name: 'Domain', value: selected.domain },
                  { name: 'Publisher', value: selected.publisherName },
                  { name: 'Type', value: rtbDetailsTypeToName(selected.rtbDetailsType) },
                  { name: 'Blocked domains', value: selected.blockedDomains },
                  {
                    name: 'Sampling rate',
                    value: selected.samplingRate === undefined ? 'not specified' : selected.samplingRate,
                  },
                ],
              },
              {
                name: 'Filters',
                items: filtersOverviewItems,
              },
              {
                name: 'Categories',
                items: categoriesOverviewItems,
              },
              {
                name: 'Dsp Mappings',
                items: dspMappingOverviewItems,
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
