import { Col } from 'antd'
import React from 'react'

import { DspIdMappingBean, NamedBean } from 'api/base/api'

import { FormEntityList } from 'components/form/inputs/FormEntityList'
import { FormSelect } from 'components/form/inputs/FormSelect'
import { FormTextInput } from 'components/form/inputs/FormTextInput'

interface Props {
  entityId?: number
  dspList: NamedBean[]
  mappingList: DspIdMappingBean[]
  setMappingList: (value: DspIdMappingBean[]) => void
}

export const FormDspMappingTab: React.FC<Props> = ({ mappingList, dspList, entityId, setMappingList }) => (
  <FormEntityList
    label={
      <>
        <Col span={6} style={{ marginBottom: 10 }}>
          DSP
        </Col>
        <Col span={4} style={{ marginBottom: 10 }}>
          ID
        </Col>
      </>
    }
    list={mappingList}
    updateList={(newMapping) => setMappingList(newMapping)}
    getNewEntity={() => ({ entityId, dspId: undefined, entityIdInDsp: '' })}
    renderRow={(dspMapping, index) => (
      <>
        <FormSelect
          span={6}
          value={dspMapping.dspId}
          onChange={(dspId) =>
            setMappingList(mappingList.map((mapping, i) => (i === index ? { ...dspMapping, dspId } : mapping)))
          }
          options={dspList
            .filter((dsp) => {
              const isNotSelectedInMapping = !mappingList.some((mappping) => mappping.dspId === dsp.id)
              return dsp.id === dspMapping.dspId || isNotSelectedInMapping
            })
            .map((dsp) => ({ value: dsp.id, label: dsp.name }))}
        />

        <FormTextInput
          span={4}
          value={dspMapping.entityIdInDsp}
          onChange={(entityIdInDsp) =>
            setMappingList(mappingList.map((mapping, i) => (i === index ? { ...dspMapping, entityIdInDsp } : mapping)))
          }
        />
      </>
    )}
  />
)
