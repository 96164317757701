import React from 'react'

import { DspIdMappingBean } from 'api/base/api'
import { dspApi } from 'api/dsp'

import { useGetList } from 'utils/useGetList'

import { ListOverviewArray } from 'components/list'

export function useDspMappingOverviewItems(mapping: DspIdMappingBean[] | undefined) {
  const [dspList] = useGetList(() => dspApi.listAll())

  return mapping?.length !== undefined && mapping?.length > 0
    ? [
        {
          name: 'Dsp to Id',
          value: (
            <ListOverviewArray
              data={[...mapping]
                .map((bean) => {
                  const dspName = dspList.find((dsp) => dsp.id === bean.dspId)?.name
                  return `${dspName} - ${bean.entityIdInDsp}`
                })
                .sort()}
            />
          ),
        },
      ]
    : []
}
