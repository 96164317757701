import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { Dashboard } from 'views/dashboard/Dashboard'

export const DashboardRoot: React.FC = () => (
  <Switch>
    <Route path={routes.dashboard.root} component={Dashboard} />
    <Redirect from="*" to={routes.dashboard.root} />
  </Switch>
)
