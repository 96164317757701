import { Col, Form, Select } from 'antd'
import React, { useRef, useState } from 'react'

import { getUniqueId } from 'utils/getUniqueId'

import { FormSortableSelectTag } from 'components/form/inputs/formSortableSelect/FormSortableSelectTag'

import styles from 'components/form/inputs/formSortableSelect/formSortableSelect.module.scss'

interface Option<T> {
  label: string
  value: T
}

interface Props<T> {
  span: string | number
  label?: string
  placeholder?: string
  value?: T[]
  onChange: (value: T[]) => void
  options: Option<T>[]
  required?: boolean
  disabled?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  selectProps?: React.ComponentProps<typeof Select>
}

export const FormSortableSelect = <T,>({
  span,
  label,
  placeholder,
  value,
  onChange,
  options,
  required,
  disabled,
  colProps,
  itemProps,
  selectProps,
}: Props<T>) => {
  const containerIdRef = useRef(`selectDragContainer${getUniqueId()}`)

  const getDraggableElements = (withDragged?: 'withDragged') => {
    const container = document.querySelector(`#${containerIdRef.current}`)
    if (!container) return []

    return [...container.getElementsByClassName(styles.draggable.replaceAll('+', ''))].filter((element) =>
      withDragged ? true : !element.classList.contains(styles.dragging.replaceAll('+', ''))
    )
  }

  const getElementOnRight = (cursorX: number, cursorY: number) => {
    const draggableElements = getDraggableElements()

    return draggableElements.reduce(
      (closest, element, index) => {
        const box = element.getBoundingClientRect()
        const nextBox = draggableElements[index + 1]?.getBoundingClientRect()

        const inSameRowAsCursor = cursorY - (box.bottom + 4) <= 0 && cursorY - box.top >= 0
        const offset = cursorX - (box.left + box.width / 2)

        if (!inSameRowAsCursor) return closest

        if (offset < 0 && offset > closest.offset) {
          return { offset, element }
        }

        if (
          // handle row ends,
          nextBox && // there is a box after this one.
          cursorY - nextBox.top <= 0 && // the next is in a new row
          closest.offset === Number.NEGATIVE_INFINITY // we didn't find a fit yet
        ) {
          return { offset: 0, element: draggableElements[index + 1] }
        }

        return closest
      },
      { offset: Number.NEGATIVE_INFINITY } as { offset: number; element?: Element }
    ).element
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    const elementOnRight = getElementOnRight(e.clientX, e.clientY)
    const draggable = document.querySelector(`.${styles.dragging}`)
    if (!draggable) return

    if (elementOnRight) {
      elementOnRight.insertAdjacentElement('beforebegin', draggable)
    } else {
      const draggableElements = getDraggableElements()
      draggableElements[draggableElements.length - 1].insertAdjacentElement('afterend', draggable)
    }
  }

  const [key, setKey] = useState(getUniqueId())

  const onDragEnd = () => {
    const draggableElements = getDraggableElements('withDragged')

    const labels = draggableElements.map((element) => element.textContent)
    const newValues = labels.map((label) => options.find((option) => option.label === label)?.value) as T[]

    onChange(newValues)
    setKey(getUniqueId())
  }

  return (
    <Col span={span} {...colProps}>
      <Form.Item label={label} required={required} {...itemProps}>
        <div id={containerIdRef.current} onDragOver={onDragOver}>
          <Select
            key={key}
            value={value}
            onChange={(e) => onChange(e as T[])}
            placeholder={placeholder}
            disabled={disabled}
            filterOption={(input, option) => option?.label.toLowerCase().includes(input.toLowerCase())}
            filterSort={(a, b) => (a.label === b.label ? 0 : a.label > b.label ? 1 : -1)}
            mode="multiple"
            allowClear
            tagRender={(props) => <FormSortableSelectTag onDragEnd={onDragEnd} {...props} />}
            options={options}
            {...selectProps}
          />
        </div>
      </Form.Item>
    </Col>
  )
}
