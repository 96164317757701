import { Card, Col, Form, Row, Spin } from 'antd'
import React, { useState } from 'react'

interface Props {
  title: string | undefined
  isLoading: boolean
  tabs?: { name: string; tab: React.ReactNode }[]
  children?: React.ReactNode
}

export const FormContainer: React.FC<Props> = ({ title, isLoading, tabs, children }) => {
  const [activeTabName, setActiveTabName] = useState(tabs?.[0].name)

  if (tabs && !tabs?.find((tab) => tab.name === activeTabName)) {
    setActiveTabName(tabs?.[0].name)
  }

  return isLoading ? (
    <Card title={title}>
      <Row justify="space-around">
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    </Card>
  ) : (
    <Form requiredMark layout="vertical">
      <Card
        title={title}
        tabList={tabs?.map(({ name }) => ({ key: name, tab: name }))}
        activeTabKey={activeTabName}
        onTabChange={(name) => setActiveTabName(name)}
      >
        {tabs?.find((tab) => tab.name === activeTabName)?.tab}
        {children}
      </Card>
    </Form>
  )
}
