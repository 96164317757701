import { Col, Form, Switch } from 'antd'
import React from 'react'

interface Props {
  span: string | number
  label?: string
  checked?: boolean
  onChange: () => void
  disabled?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  switchProps?: React.ComponentProps<typeof Switch>
}

export const FormSwitch: React.FC<Props> = ({
  span,
  label,
  checked,
  onChange,
  disabled,
  colProps,
  itemProps,
  switchProps,
}) => (
  <Col span={span} {...colProps}>
    <Form.Item label={label} valuePropName="checked" {...itemProps}>
      <Switch checked={checked} onChange={onChange} disabled={disabled} {...switchProps} />
    </Form.Item>
  </Col>
)
