import { Card, Col, Descriptions, Divider, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

interface AdditionalLink {
  name: string
  to?: string | { pathname: string; state: object }
  onClick?: () => void
}

interface Section {
  name: 'Main' | 'Filters' | 'Categories' | 'Dsp Mappings'
  items: { name: string; value: React.ReactNode }[]
}

interface Props {
  title: string | undefined
  editLink: string
  additionalLinks?: AdditionalLink[]
  sections?: Section[]
  children?: React.ReactNode
}

export const ListOverview: React.FC<Props> = ({ title, editLink, additionalLinks, sections, children }) => {
  const formatValue = (value: React.ReactNode) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return 'Empty'
    }

    return value ?? 'Empty'
  }

  return (
    <Card
      title={
        <Row justify="space-between">
          <Col>{title}</Col>
          <Col>
            {additionalLinks?.map(({ name, to, onClick }) => (
              <React.Fragment key={name}>
                <Link to={to ?? '#'} onClick={onClick}>
                  {name}
                </Link>
                <Divider type="vertical" style={{ backgroundColor: 'black' }} />
              </React.Fragment>
            ))}
            <Link to={editLink}>Edit</Link>
          </Col>
        </Row>
      }
      bordered={false}
    >
      {sections?.map((section, sectionIndex) => (
        <React.Fragment key={section.name}>
          {section.name !== 'Main' && <Divider style={{ marginTop: 0 }}>{section.name}</Divider>}

          <Descriptions column={1}>
            {section.items.map(({ name, value }, itemIndex) => {
              const isLast = sectionIndex === sections.length - 1 && itemIndex === section.items.length - 1

              return (
                <Descriptions.Item key={name} label={name} style={isLast ? { paddingBottom: 0 } : {}}>
                  {formatValue(value)}
                </Descriptions.Item>
              )
            })}
          </Descriptions>
        </React.Fragment>
      ))}

      {children}
    </Card>
  )
}
