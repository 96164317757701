import { AccountBean, AccountControllerApi, InfoControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class AccountApi {
  api = new AccountControllerApi(configuration, configuration.basePath, customFetch)

  infoApi = new InfoControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ page }: ListFilters) => this.api.accountList(LIST_PAGE_SIZE, page)

  entityForList = (id: number) => this.api.accountForList(id)

  entity = (id: number) => this.api.account(id)

  create = (bean: AccountBean) => this.api.createAccount(bean)

  edit = (bean: AccountBean) => this.api.updateAccount(bean, bean.id as number)

  delete = (id: number) => this.api.deleteAccount(id)

  login = (username: string, password: string) => {
    const form = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`

    const options = {
      method: 'POST',
      body: form,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    return customFetch(`${configuration.basePath}/api/login`, options)
  }

  logout = () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    return customFetch(`${configuration.basePath}/api/logout`, options)
  }

  checkUserInfo = () => this.infoApi.self()
}

export const accountApi = new AccountApi()
