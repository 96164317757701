import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { ChangePasswordForm } from 'views/changePassword/form/ChangePasswordForm'

export const ChangePasswordRoot: React.FC = () => (
  <Switch>
    <Route path={routes.changePassword.root} component={ChangePasswordForm} />
    <Redirect from="*" to={routes.account.list()} />
  </Switch>
)
