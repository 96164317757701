import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { adSizeApi } from 'api/adSize'
import { adUnitApi } from 'api/adUnit'
import { generateTagApi } from 'api/generateTag'
import { publisherApi } from 'api/publisher'
import { siteApi } from 'api/site'

import { useGetList } from 'utils/useGetList'

import { useFormState } from 'components/form'

export const generatedTagTypes = ['Prebid.js adapter', 'JavaScript Tag', 'VAST URL'] as const

export interface GenerateTagFormData {
  type: (typeof generatedTagTypes)[number]
  sspUrl?: string
  publisherId?: number
  siteId?: number
  adUnitId?: number
}

export function useGenerateTagFormData() {
  const location = useLocation<{
    publisherId: number | undefined
    siteId: number | undefined
    adUnitId: number | undefined
  }>()
  const [formState, setFormState] = useFormState<GenerateTagFormData>()

  useEffect(() => {
    setFormState({
      type: 'JavaScript Tag',
      publisherId: location.state?.publisherId,
      siteId: location.state?.siteId,
      adUnitId: location.state?.adUnitId,
    })

    generateTagApi.getSspUrl().then(({ sspUrl }) => setFormState({ sspUrl }))
  }, [])

  const [publisherList] = useGetList(() => publisherApi.listAll())
  const [siteList] = useGetList(() => siteApi.listAll())
  const [adUnitList] = useGetList(() => adUnitApi.listAll())
  const [adSizeFilteredList, adSizeListOptions] = useGetList(() => adSizeApi.getByAdUnitId(formState?.adUnitId))

  useEffect(() => {
    adSizeListOptions.refreshList()
  }, [formState?.adUnitId])

  const siteFilteredList = siteList.filter(({ publisherId }) => publisherId === formState?.publisherId)
  const adUnitFilteredList = adUnitList.filter(({ siteId }) => siteId === formState?.siteId)

  return {
    isFormLoading: !formState,
    formState: formState ?? {},
    setFormState,
    publisherList,
    siteFilteredList,
    adUnitFilteredList,
    adSizeFilteredList,
  }
}
