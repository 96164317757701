import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { adSizeApi } from 'api/adSize'
import { DirectAdBean } from 'api/base/api'
import { directAdApi } from 'api/directAd'

import { useGetList } from 'utils/useGetList'

import { useFormState } from 'components/form'

const { ContentTypeEnum, DirectAdCreativeTypeEnum } = DirectAdBean

export function useDirectAdFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<DirectAdBean>()

  useEffect(() => {
    if (id) {
      directAdApi.entity(+id).then((bean) => {
        setFormName(bean.name ?? '')
        setFormState(bean)
      })
    } else {
      setFormState({
        active: true,
        unwrapVast: false,
        directAdCreativeType: DirectAdCreativeTypeEnum.BANNER,
        contentType: ContentTypeEnum.ADM,
      })
    }
  }, [])

  const [adSizeList] = useGetList(() => adSizeApi.listAll())

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
    adSizeList,
  }
}
