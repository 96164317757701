/* eslint-disable @typescript-eslint/no-explicit-any */
import { createMessage } from 'utils/createMessage'

let lastUnauthorizedTimestamp = 0

export function processGlobalError(err?: any) {
  const resp = err as Response
  let parsedError: string | undefined

  if (typeof resp.json === 'function') {
    return resp
      .json()
      .catch(() => {
        createMessage('error', "ERROR: can't connect to the server")
        return Promise.reject(new Error())
      })
      .then((json) => {
        if (json.errors) {
          Object.values(json.errors).forEach((messages) => {
            ;(messages as string[]).forEach((message: string) => createMessage('error', message))
          })
          return Promise.reject(json.errors)
        }

        parsedError = (json.error || json) as string

        const isUnauthorized = parsedError === 'Unauthorized'
        const isRepeatedUnauthorized = isUnauthorized && Date.now() - lastUnauthorizedTimestamp < 1000
        if (isUnauthorized) {
          lastUnauthorizedTimestamp = Date.now()
        }

        if (!isRepeatedUnauthorized) {
          createMessage('error', parsedError)
        }

        return Promise.reject(parsedError)
      })
  }
  if ((resp as any).response && (resp as any).response.data) {
    const { data } = (resp as any).response
    parsedError = (data.error || data) as string
    createMessage('error', parsedError)
  } else {
    createMessage('error', "ERROR: can't connect to the server")
  }

  return Promise.reject(parsedError)
}
