import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { AdSizeList } from 'views/adSize/AdSizeList'
import { AdSizeForm } from 'views/adSize/form/AdSizeForm'

export const AdSizeRoot: React.FC = () => (
  <Switch>
    <Route path={routes.adSize.list('forRoute')} component={AdSizeList} />
    <Route path={routes.adSize.create} component={AdSizeForm} />
    <Route path={routes.adSize.edit()} component={AdSizeForm} />
    <Redirect from="*" to={routes.adSize.list()} />
  </Switch>
)
