import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { DirectAdList } from 'views/directAd/DirectAdList'
import { DirectAdForm } from 'views/directAd/form/DirectAdForm'

export const DirectAdRoot: React.FC = () => (
  <Switch>
    <Route path={routes.directAd.list('forRoute')} component={DirectAdList} />
    <Route path={routes.directAd.create} component={DirectAdForm} />
    <Route path={routes.directAd.edit()} component={DirectAdForm} />
    <Redirect from="*" to={routes.directAd.list()} />
  </Switch>
)
