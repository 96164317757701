import { CountryControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { cacheApiMethod } from 'utils/cacheApiMethod'

class CountryApi {
  api = new CountryControllerApi(configuration, configuration.basePath, customFetch)

  listAll = () => cacheApiMethod('countries', () => this.api.countries())
}

export const countryApi = new CountryApi()
