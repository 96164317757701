import { Col, Form, InputNumber } from 'antd'
import React from 'react'

interface Props {
  span: string | number
  label?: string
  value?: number
  onChange: (value?: number) => void
  required?: boolean
  disabled?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  inputProps?: React.ComponentProps<typeof InputNumber>
}

export const FormNumberInput: React.FC<Props> = ({
  span,
  label,
  value,
  onChange,
  required,
  disabled,
  colProps,
  itemProps,
  inputProps,
}) => (
  <Col span={span} {...colProps}>
    <Form.Item label={label} required={required} {...itemProps}>
      <InputNumber
        value={value}
        onChange={(e) => onChange((e as number) ?? undefined)}
        controls={false}
        disabled={disabled}
        {...inputProps}
        style={{ width: '100%', ...inputProps?.style }}
      />
    </Form.Item>
  </Col>
)
