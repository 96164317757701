import {
  DashboardChartRequestBean,
  DashboardControllerApi,
  DashboardStatRequestBean,
  DashboardTopRequestBean,
} from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class DashboardApi {
  api = new DashboardControllerApi(configuration, configuration.basePath, customFetch)

  statistics = (bean: DashboardStatRequestBean) => this.api.getDashboardStat(bean)

  trend = (bean: DashboardChartRequestBean) => this.api.getDashboardChart(bean)

  top = (bean: DashboardTopRequestBean) => this.api.getDashboardTop(bean)
}

export const dashboardApi = new DashboardApi()
