import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PublisherBean } from 'api/base/api'
import { publisherApi } from 'api/publisher'

import { getDefaultFilterBean, useFormState } from 'components/form'

const { IabTaxonomyVersionEnum } = PublisherBean

export function usePublisherFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<PublisherBean>()

  useEffect(() => {
    if (id) {
      publisherApi.entity(+id).then((bean) => {
        setFormName(bean.name)
        setFormState({ ...bean, filterBean: bean.filterBean ?? getDefaultFilterBean() })
      })
    } else {
      setFormState({
        name: '',
        iabTaxonomyVersion: IabTaxonomyVersionEnum._10,
        filterBean: getDefaultFilterBean(),
        dspIdMappings: [],
      })
    }
  }, [])

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
  }
}
