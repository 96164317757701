import React from 'react'

import { PublisherBean } from 'api/base/api'

import { taxonomyVersionToName } from 'utils/enumToName'

import { ListOverviewArray } from 'components/list/layout/ListOverviewArray'

export function useCategoriesOverviewItems(
  version: PublisherBean.IabTaxonomyVersionEnum | undefined,
  categories?: string[],
  blockedCategories?: string[]
) {
  return [
    { name: 'Taxonomy version', value: taxonomyVersionToName(version) },
    ...(categories
      ? [
          {
            name: 'Linked categories',
            value: (
              <ListOverviewArray data={[...categories].sort().map((category) => category.replaceAll('/', ' / '))} />
            ),
          },
        ]
      : []),
    ...(blockedCategories
      ? [
          {
            name: 'Blocked categories',
            value: (
              <ListOverviewArray
                data={[...blockedCategories].sort().map((category) => category.replaceAll('/', ' / '))}
              />
            ),
          },
        ]
      : []),
  ]
}
