import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { AccountList } from 'views/account/AccountList'
import { AccountForm } from 'views/account/form/AccountForm'

export const AccountRoot: React.FC = () => (
  <Switch>
    <Route path={routes.account.list('forRoute')} component={AccountList} />
    <Route path={routes.account.create} component={AccountForm} />
    <Route path={routes.account.edit()} component={AccountForm} />
    <Redirect from="*" to={routes.account.list()} />
  </Switch>
)
