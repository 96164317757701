import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { DirectCampaignList } from 'views/directCampaign/DirectCampaignList'
import { DirectCampaignForm } from 'views/directCampaign/form/DirectCampaignForm'

export const DirectCampaignRoot: React.FC = () => (
  <Switch>
    <Route path={routes.directCampaign.list('forRoute')} component={DirectCampaignList} />
    <Route path={routes.directCampaign.create} component={DirectCampaignForm} />
    <Route path={routes.directCampaign.edit()} component={DirectCampaignForm} />
    <Redirect from="*" to={routes.directCampaign.list()} />
  </Switch>
)
