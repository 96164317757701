import { Card, Col } from 'antd'
import React from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { DashboardChartBean, DashboardChartRequestBean } from 'api/base/api'

import { formatDashboardResult } from 'views/dashboard/utils/formatDashboardResult'
import { ReportDictionaryElem } from 'views/report/utils/reportTypes'
import { reportValues } from 'views/report/utils/reportValues'

const { ValuesEnum } = DashboardChartRequestBean

interface Props {
  title: string
  values: DashboardChartRequestBean.ValuesEnum[]
  response?: DashboardChartBean
  currency: string
  format: ReportDictionaryElem['format']
}

export const DashboardTrendChart: React.FC<Props> = ({ title, values, response, currency, format }) => {
  const dataKey = response?.chart?.[0].hour ? 'hour' : 'day'
  const interval = Math.floor((response?.chart?.length ?? 0) / 6)

  function getYAxisID(value: DashboardChartRequestBean.ValuesEnum) {
    switch (value) {
      case ValuesEnum.PubReq:
        return 'request'
      case ValuesEnum.PubBid:
        return 'event'
      case ValuesEnum.PubWin:
        return 'event'
      case ValuesEnum.Impr:
        return 'event'
      default:
        return null
    }
  }

  return (
    <Col span={12}>
      <Card title={title} headStyle={{ border: 'none' }} bodyStyle={{ paddingTop: 0 }} size="small">
        <ResponsiveContainer height={200}>
          <LineChart
            data={response?.chart}
            margin={{
              top: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />

            <XAxis dataKey={dataKey} interval={interval} />
            {values.map((el, index) => (
              <YAxis
                tickFormatter={(value) => formatDashboardResult(value as number, format, currency) as string}
                scale="auto"
                orientation={index % 2 === 0 ? 'left' : 'right'}
                yAxisId={getYAxisID(el) ?? index}
              />
            ))}

            <Tooltip formatter={(value) => formatDashboardResult(value as number, format, currency)} />
            <Legend verticalAlign="top" align="right" />

            {values.map((el, index) => (
              <Line
                yAxisId={getYAxisID(el) ?? index}
                connectNulls
                type="monotone"
                dataKey={el}
                name={reportValues.find((value) => value.value === el)?.label}
                stroke={['#597ef7', '#73d13d', '#ffc53d', '#f759ab'][index]}
                activeDot={{ r: 6 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </Col>
  )
}
