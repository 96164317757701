import { FilterOutlined } from '@ant-design/icons'
import React from 'react'

interface Props {
  active: boolean
}

export const FilterIcon: React.FC<Props> = ({ active }) => (
  <FilterOutlined
    style={{
      color: active ? '#1677ff' : '#444444',
    }}
  />
)
