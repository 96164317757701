import { DirectAdBean, DirectAdControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class DirectAdApi {
  api = new DirectAdControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ id, name, adSizeName, active, page, creativeType, directAdType, linkedEntityId }: ListFilters) =>
    this.api.directAdFilteredList(
      id,
      name,
      adSizeName,
      active,
      directAdType,
      creativeType,
      linkedEntityId ? [linkedEntityId] : undefined,
      {
        query: { size: LIST_PAGE_SIZE, page },
      }
    )

  entityForList = (id: number) => this.api.directAdForList(id)

  entity = (id: number) => this.api.directAd(id)

  create = (bean: DirectAdBean) => this.api.createDirectAd(bean)

  edit = (bean: DirectAdBean) => this.api.updateDirectAd(bean, bean.id as number)

  delete = (id: number) => this.api.deleteDirectAd(id)

  listAll = () => this.api.allDirectAdsAsSimple()
}

export const directAdApi = new DirectAdApi()
