import classNames from 'classnames'
import React, { ComponentProps } from 'react'

import styles from 'components/application/materialUiButton/materialUiButton.module.scss'

export const MaterialUiButton: React.FC<ComponentProps<'button'>> = ({ className, onClick, children, ...props }) => {
  function createRipple(event: React.MouseEvent<HTMLButtonElement>) {
    const button = event.currentTarget

    const circle = document.createElement('span')
    const diameter = Math.max(button.clientWidth, button.clientHeight)
    const radius = diameter / 2

    circle.style.width = `${diameter}px`
    circle.style.height = `${diameter}px`
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`
    circle.classList.add(styles.circle)

    const ripple = button.getElementsByClassName(styles.circle)[0]

    if (ripple) {
      ripple.remove()
    }

    button.appendChild(circle)
  }

  return (
    <button
      className={classNames(styles.button, className)}
      onClick={(e) => {
        createRipple(e)
        onClick?.(e)
      }}
      {...props}
    >
      {children}
    </button>
  )
}
