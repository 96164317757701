import React, { useEffect, useState } from 'react'

import { FilterEntry } from 'api/base/api'

import { FormRow, FormSelect } from 'components/form'

import { reportKeys } from 'views/report/utils/reportKeys'
import { ReportFilterOption } from 'views/report/utils/reportTypes'

interface Props {
  filters: FilterEntry[]
  setFilters: (filters: FilterEntry[]) => void
}

export const DashboardFormFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const [filterSelectOptions, setFilterSelectOptions] = useState<{ [key: string]: ReportFilterOption[] }>({})

  useEffect(() => {
    reportKeys
      .filter((key) => filters.some((filter) => filter.keys?.includes(key.value as FilterEntry.KeysEnum)))
      .forEach((key) => {
        if (key.filter === 'select') {
          key
            .getFilterOptions?.()
            .then((options) =>
              setFilterSelectOptions((filterSelectOptions) => ({ ...filterSelectOptions, [key.value]: options }))
            )
        }
      })
  }, [])

  const updateFilter = (params: (string | number | boolean)[], index: number) => {
    const newFilters = [...filters]
    const newFilter = { ...newFilters[index] }

    if (newFilter.filter) {
      newFilter.filter.params = params
    }

    newFilters[index] = newFilter
    setFilters(newFilters)
  }

  return (
    <FormRow>
      {filters.map((filter, index) => {
        const reportKey = reportKeys.find(({ value }) => value === filter.keys?.[0])

        if (!reportKey) {
          return null
        }

        return (
          <FormSelect
            span={4}
            placeholder={reportKey.label}
            value={filter.filter?.params}
            onChange={(params) => updateFilter(params, index)}
            options={(filterSelectOptions[reportKey.value] ?? []).map(({ value, label }) => ({ value, label }))}
            selectProps={{
              mode: 'multiple',
              allowClear: true,
            }}
          />
        )
      })}
    </FormRow>
  )
}
