import React from 'react'

import { DashboardStatRequestBean, PublisherBean } from 'api/base/api'

import { FormContainer, FormPeriodPicker, FormRow, FormSelect, FormSwitch } from 'components/form'

import { DashboardFormFilters } from 'views/dashboard/form/DashboardFormFilters'

const { UserCurrencyEnum } = PublisherBean

interface Props {
  isFormLoading: boolean
  formState: Partial<DashboardStatRequestBean>
  setFormState: (state: Partial<DashboardStatRequestBean>) => void
}

export const DashboardForm: React.FC<Props> = ({ isFormLoading, formState, setFormState }) => (
  <FormContainer title={undefined} isLoading={isFormLoading}>
    <FormRow>
      <FormPeriodPicker
        periodType={formState.periodType}
        setPeriodType={(periodType) => setFormState({ periodType })}
        startDate={formState.startDate}
        setStartDate={(startDate) => setFormState({ startDate })}
        endDate={formState.endDate}
        setEndDate={(endDate) => setFormState({ endDate })}
      />

      <FormSelect
        span={10}
        label="Currency"
        value={formState.currency}
        onChange={(currency) => setFormState({ currency })}
        options={[
          { value: UserCurrencyEnum.USD, label: 'USD' },
          { value: UserCurrencyEnum.KRW, label: 'KRW' },
        ]}
      />

      <FormSwitch
        span={2}
        label="Audit"
        checked={formState.audit}
        onChange={() => setFormState({ audit: !formState.audit })}
        colProps={{ style: { paddingLeft: 30 } }}
      />
    </FormRow>

    <DashboardFormFilters filters={formState.filters ?? []} setFilters={(filters) => setFormState({ filters })} />
  </FormContainer>
)
