import { Col, Form, Select } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import React from 'react'

interface Props<T> {
  span: string | number
  label?: string
  placeholder?: string
  value?: T
  onChange: (value: T) => void
  options: DefaultOptionType[]
  required?: boolean
  disabled?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  selectProps?: React.ComponentProps<typeof Select>
}

export const FormSelect = <T,>({
  span,
  label,
  placeholder,
  value,
  onChange,
  options,
  required,
  disabled,
  colProps,
  itemProps,
  selectProps,
}: Props<T>) => (
  <Col span={span} {...colProps}>
    <Form.Item label={label} required={required} {...itemProps}>
      <Select
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e as T)}
        options={options}
        disabled={disabled}
        filterOption={(input, option) => option?.label.toLowerCase().includes(input.toLowerCase())}
        filterSort={(a, b) =>
          a.label?.toLowerCase() === b.label?.toLowerCase()
            ? 0
            : a.label?.toLowerCase() > b.label?.toLowerCase()
            ? 1
            : -1
        }
        {...selectProps}
      />
    </Form.Item>
  </Col>
)
