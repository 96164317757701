import React from 'react'
import { useHistory } from 'react-router-dom'

import { DspBean, FilterBean } from 'api/base/api'
import { dspApi } from 'api/dsp'

import { routes } from 'utils/routes'

import { FormActionButtons, FormCategoriesTab, FormContainer, FormFiltersTab } from 'components/form'

import { MainTab } from 'views/dsp/form/MainTab'
import { useDspFormData } from 'views/dsp/form/useDspFormData'

export const DspForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formName, formState, setFormState } = useDspFormData()

  const redirectToList = () => {
    history.push(routes.dsp.list())
  }

  const submit = () => {
    const bean = formState as DspBean

    if (isCreation) {
      dspApi.create(bean).then(redirectToList)
    } else {
      dspApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    dspApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer
      title={isCreation ? 'New DSP' : formName}
      isLoading={isFormLoading}
      tabs={[
        { name: 'Main', tab: <MainTab formState={formState} setFormState={setFormState} /> },
        {
          name: 'Filters',
          tab: (
            <FormFiltersTab
              filterBean={formState.filterBean as FilterBean}
              setFilterBean={(filterBean) => setFormState({ filterBean })}
            />
          ),
        },
        {
          name: 'Categories',
          tab: (
            <FormCategoriesTab
              version={formState.iabTaxonomyVersion}
              setVersion={(iabTaxonomyVersion) => setFormState({ iabTaxonomyVersion })}
              blocked={formState.blockedCategories}
              setBlocked={(blockedCategories) => setFormState({ blockedCategories })}
            />
          ),
        },
      ]}
    >
      <FormActionButtons isCreation={isCreation} onBack={redirectToList} onDelete={remove} onSubmit={submit} />
    </FormContainer>
  )
}
