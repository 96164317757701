const cache: { [key: string]: unknown } = {}

export function cacheApiMethod<Data>(key: string, method: () => Promise<Data>) {
  if (cache[key]) {
    return Promise.resolve(cache[key] as Data)
  }

  return method().then((data) => {
    cache[key] = data
    return data as Data
  })
}
