import { Col, Form, Input } from 'antd'
import React from 'react'

interface Props {
  span: string | number
  label?: string
  value?: string
  onChange: (value: string) => void
  required?: boolean
  disabled?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  textAreaProps?: React.ComponentProps<typeof Input.TextArea>
}

export const FormTextAreaInput: React.FC<Props> = ({
  span,
  label,
  value,
  onChange,
  required,
  disabled,
  colProps,
  itemProps,
  textAreaProps,
}) => (
  <Col span={span} {...colProps}>
    <Form.Item label={label} required={required} {...itemProps}>
      <Input.TextArea value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled} {...textAreaProps} />
    </Form.Item>
  </Col>
)
