import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { GenerateTagForm } from 'views/generateTag/GenerateTagForm'

export const GenerateTagRoot: React.FC = () => (
  <Switch>
    <Route path={routes.generateTag.root} component={GenerateTagForm} />
    <Redirect from="*" to={routes.generateTag.root} />
  </Switch>
)
