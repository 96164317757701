import { DspBean, DspControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class DspApi {
  api = new DspControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ page }: ListFilters) => this.api.dspList({ query: { size: LIST_PAGE_SIZE, page } })

  listAll = () => this.api.dspListAll()

  entityForList = (id: number) => this.api.dspForList(id)

  entity = (id: number) => this.api.dsp(id)

  create = (bean: DspBean) => this.api.createDsp(bean)

  edit = (bean: DspBean) => this.api.updateDsp(bean, bean.id as number)

  delete = (id: number) => this.api.deleteDsp(id)
}

export const dspApi = new DspApi()
