import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ExpandableConfig } from 'antd/es/table/interface'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

interface Props<Entity> {
  entityName: string
  columns: ColumnsType<Entity>
  list: Entity[]
  isLoading: boolean

  selected?: Entity
  selectEntity?: (id: number | undefined) => void

  currentPage: number
  totalEntities: number | undefined
  onPageChange: (page: number) => void

  createLink?: string

  expandable?: ExpandableConfig<Entity>
}

export const LIST_PAGE_SIZE = 50

export const ListTable = <Entity extends { id?: number }>({
  entityName,
  columns,
  list,
  isLoading,
  selected,
  selectEntity,
  currentPage,
  totalEntities,
  onPageChange,
  createLink,
  expandable,
}: Props<Entity>) => {
  useEffect(() => {
    if (totalEntities === 0) {
      return
    }
    // if page number exceeds available pages count
    if (currentPage !== 0 && (totalEntities ?? 0) / LIST_PAGE_SIZE <= currentPage) {
      onPageChange(0)
    }
  }, [currentPage, totalEntities, LIST_PAGE_SIZE])

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={list}
      loading={isLoading}
      size="middle"
      tableLayout="fixed"
      onRow={(row) => ({
        onClick: () => selectEntity?.(row.id),
      })}
      rowSelection={
        selectEntity
          ? {
              type: 'radio',
              selectedRowKeys: [selected?.id as number],
              onChange: (keys) => selectEntity(+keys[0]),
              renderCell: () => null,
              columnWidth: 0,
            }
          : undefined
      }
      pagination={{
        current: currentPage + 1,
        onChange: (page) => onPageChange(page - 1),
        pageSize: LIST_PAGE_SIZE,
        position: ['bottomCenter'],
        showSizeChanger: false,
        total: totalEntities,
      }}
      footer={
        createLink
          ? () => (
              <Link to={createLink} style={{ marginLeft: 16 }}>
                Create new {entityName}
              </Link>
            )
          : undefined
      }
      expandable={expandable}
    />
  )
}
