type Subscriber = () => void

class UserUpdateStore {
  private subscribers: Subscriber[] = []

  subscribe = (subscriber: Subscriber) => {
    this.subscribers.push(subscriber)

    return () => this.unsubscribe(subscriber)
  }

  unsubscribe = (subscriber: Subscriber) => {
    this.subscribers = this.subscribers.filter((sb) => sb !== subscriber)
  }

  updateInfo = () => {
    this.subscribers.forEach((sb) => sb())
  }
}

export const userUpdateStore = new UserUpdateStore()
