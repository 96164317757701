import { useState } from 'react'

export function useFormState<Bean>() {
  const [formState, setFormState] = useState<Partial<Bean>>()

  return [
    formState,
    (state: Partial<Bean>) => setFormState((formState) => (state ? { ...formState, ...state } : undefined)),
  ] as const
}

export function useFormStateWithInitState<Bean>(initialState: Partial<Bean>) {
  const [formState, setFormState] = useState<Partial<Bean>>(initialState)

  return [formState, (state: Partial<Bean>) => setFormState((formState) => ({ ...formState, ...state }))] as const
}
