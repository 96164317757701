import React, { useEffect } from 'react'

import { FilterBean } from 'api/base/api'
import { countryApi } from 'api/country'
import { countrySubdivisionApi } from 'api/countrySubdivision'

import { getUniqueId } from 'utils/getUniqueId'
import { useGetList } from 'utils/useGetList'

import { FormRow } from 'components/form/containers/FormRow'
import { FormEntityList } from 'components/form/inputs/FormEntityList'
import { FormHourRangePicker, HourRange } from 'components/form/inputs/FormHourRangePicker'
import { FormSelect } from 'components/form/inputs/FormSelect'
import { FormSwitch } from 'components/form/inputs/FormSwitch'

const { CountryListTypeEnum, CountrySubdivisionListTypeEnum, DeviceListEnum, DeviceListTypeEnum } = FilterBean

interface Props {
  filterBean: FilterBean
  dayPartExcluded?: boolean
  setFilterBean: (value: FilterBean) => void
}

const subdivisionSupportedCountry = ['usa']

export const FormFiltersTab: React.FC<Props> = ({ filterBean, dayPartExcluded, setFilterBean }) => {
  const [countryList] = useGetList(() => countryApi.listAll())
  const [subdivisionList, subdivisionListOptions] = useGetList(() =>
    countrySubdivisionApi.listByCountryId(filterBean.countryIds || [])
  )

  useEffect(() => {
    subdivisionListOptions.refreshList()
  }, [filterBean?.countryIds])
  const subdivisionSupportedCountryIds = () =>
    countryList
      .filter((country) => subdivisionSupportedCountry.findIndex((isoName) => isoName === country.iso3) > -1)
      .map((country) => country.id || -1)
      .filter((id) => id > 0)

  const needShowSubdivisions = () => {
    const countryIds = filterBean.countryIds || []
    for (let i = 0; i < countryIds.length; i += 1) {
      const subdivisionIds = subdivisionSupportedCountryIds()
      const isCountryFound =
        subdivisionIds.findIndex((subdivisionSupportedCountryId) => subdivisionSupportedCountryId === countryIds[i]) >=
        0
      if (isCountryFound) {
        return true
      }
    }
    return false
  }

  const parseHourRanges = (dayPart?: string): HourRange[] => {
    if (!dayPart) return []

    return dayPart
      .split(';')
      .map((el) => ({ id: getUniqueId(), from: el.split(',')[0].slice(0, 2), to: el.split(',')[1].slice(0, 2) }))
  }

  const stringifyHourRanges = (hourRanges: HourRange[]) =>
    hourRanges.reduce((acc, hourRange) => `${acc ? `${acc};` : ''}${hourRange.from}:00:00,${hourRange.to}:00:00`, '')

  const hourRanges = parseHourRanges(filterBean.dayPart)

  return (
    <>
      <FormRow>
        <FormSwitch
          span={8}
          label="Activate filters"
          checked={filterBean.active}
          onChange={() => setFilterBean({ ...filterBean, active: !filterBean.active })}
        />
      </FormRow>

      {filterBean.active && (
        <>
          <FormRow>
            <FormSelect
              span={8}
              label="Device list type"
              value={filterBean.deviceListType}
              onChange={(deviceListType) => setFilterBean({ ...filterBean, deviceListType })}
              options={[
                { value: DeviceListTypeEnum.INCLUDEALL, label: 'Include all' },
                { value: DeviceListTypeEnum.INCLUDESELECTED, label: 'Include selected' },
                { value: DeviceListTypeEnum.EXCLUDESELECTED, label: 'Exclude selected' },
              ]}
            />
          </FormRow>

          <FormRow>
            <FormSelect
              span={8}
              label="Device list"
              value={filterBean.deviceList}
              onChange={(deviceList) => setFilterBean({ ...filterBean, deviceList })}
              options={[
                { value: DeviceListEnum.CONNECTEDDEVICE, label: 'Connected device' },
                { value: DeviceListEnum.PC, label: 'PC' },
                { value: DeviceListEnum.CONNECTEDTV, label: 'Connected TV' },
                { value: DeviceListEnum.PHONE, label: 'Phone' },
                { value: DeviceListEnum.MOBILETABLET, label: 'Mobile tablet' },
                { value: DeviceListEnum.SETTOPBOX, label: 'Set-top box' },
                { value: DeviceListEnum.TABLET, label: 'Tablet' },
              ]}
              selectProps={{
                mode: 'multiple',
                disabled: filterBean.deviceListType === DeviceListTypeEnum.INCLUDEALL,
              }}
            />
          </FormRow>

          <FormRow>
            <FormSelect
              span={8}
              label="Country list type"
              value={filterBean.countryListType}
              onChange={(countryListType) => setFilterBean({ ...filterBean, countryListType })}
              options={[
                { value: CountryListTypeEnum.INCLUDEALL, label: 'Include all' },
                { value: CountryListTypeEnum.INCLUDESELECTED, label: 'Include selected' },
                { value: CountryListTypeEnum.EXCLUDESELECTED, label: 'Exclude selected' },
              ]}
            />
            {needShowSubdivisions() && (
              <FormSelect
                span={8}
                label="Country Subdivision list type"
                value={filterBean.countrySubdivisionListType}
                onChange={(countrySubdivisionListType) => setFilterBean({ ...filterBean, countrySubdivisionListType })}
                options={[
                  { value: CountryListTypeEnum.INCLUDEALL, label: 'Include all' },
                  { value: CountryListTypeEnum.INCLUDESELECTED, label: 'Include selected' },
                  { value: CountryListTypeEnum.EXCLUDESELECTED, label: 'Exclude selected' },
                ]}
              />
            )}
          </FormRow>

          <FormRow>
            <FormSelect
              span={8}
              label="Country list"
              value={filterBean.countryIds}
              onChange={(countryIds) => setFilterBean({ ...filterBean, countryIds })}
              options={countryList.map((country) => ({ value: country.id, label: country.name }))}
              selectProps={{
                mode: 'multiple',
                disabled: filterBean.countryListType === CountryListTypeEnum.INCLUDEALL,
              }}
            />
            {needShowSubdivisions() && (
              <FormSelect
                span={8}
                label="Country Subdivision list"
                value={filterBean.countrySubdivisionIds}
                onChange={(countrySubdivisionIds) => setFilterBean({ ...filterBean, countrySubdivisionIds })}
                options={subdivisionList.map((subdivision) => ({ value: subdivision.id, label: subdivision.name }))}
                selectProps={{
                  mode: 'multiple',
                  disabled: filterBean.countrySubdivisionListType === CountrySubdivisionListTypeEnum.INCLUDEALL,
                }}
              />
            )}
          </FormRow>

          {!dayPartExcluded && (
            <FormEntityList
              label="Day part"
              list={hourRanges}
              updateList={(newHours) => setFilterBean({ ...filterBean, dayPart: stringifyHourRanges(newHours) })}
              getNewEntity={() => ({ id: getUniqueId(), from: '00', to: '00' })}
              renderRow={(hourRange, index) => (
                <FormHourRangePicker
                  value={hourRange}
                  onChange={(range) => {
                    const newHourRanges = [...hourRanges]
                    newHourRanges[index] = range
                    setFilterBean({ ...filterBean, dayPart: stringifyHourRanges(newHourRanges) })
                  }}
                />
              )}
            />
          )}
        </>
      )}
    </>
  )
}
