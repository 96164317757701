import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { PublisherBean, SavedReportBean, StatRequestBean } from 'api/base/api'
import { generalInfoApi } from 'api/generalInfo'
import { reportApi } from 'api/report'

import { useGetList } from 'utils/useGetList'

import { useFormState } from 'components/form'

const { KeysEnum, ValuesEnum, PeriodTypeEnum } = StatRequestBean
const { UserCurrencyEnum } = PublisherBean

dayjs.extend(utc)

export function useReportFormData() {
  const { id } = useParams<{ id: string }>()

  const [formState, setFormState] = useFormState<StatRequestBean | undefined>()
  const [savedReport, setSavedReport] = useFormState<SavedReportBean | undefined>()

  const [timeZones] = useGetList(() => generalInfoApi.allTimeZones())

  useEffect(() => {
    if (id) {
      setSavedReport(undefined)
      setFormState(undefined)

      reportApi.entity(Number(id)).then((response) => {
        setSavedReport(response)
        setFormState(JSON.parse(response.statRequestBeanJson))
      })
    } else {
      setSavedReport({
        id: -1,
        favorite: false,
        shared: false,
        name: undefined,
        statRequestBeanJson: undefined,
      })
      setFormState({
        keys: [KeysEnum.PublisherName, KeysEnum.SiteName],
        values: [
          ValuesEnum.PubReq,
          ValuesEnum.PubBid,
          ValuesEnum.PubBidRate,
          ValuesEnum.PubBidFloorAvg,
          ValuesEnum.PubBidPriceAvg,

          ValuesEnum.PubWin,
          ValuesEnum.PubWinRate,
          ValuesEnum.DspWinPriceAvg,

          ValuesEnum.Impr,
          ValuesEnum.ImprRate,
          ValuesEnum.NetImprPrice,
          ValuesEnum.NetImprPriceAvg,
        ],
        currency: UserCurrencyEnum.USD.toString(),
        audit: false,
        startDate: dayjs().utc().format('YYYY-MM-DD'),
        endDate: dayjs().utc().format('YYYY-MM-DD'),
        periodType: PeriodTypeEnum.TODAY,
        timeZone: 'UTC',
        filters: [],
      })
    }
  }, [id])

  return {
    isFormLoading: !formState || !savedReport,
    formState: formState ?? {},
    setFormState,
    savedReport: savedReport ?? {},
    setSavedReport,
    timeZones,
  }
}
