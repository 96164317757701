import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { SiteForm } from 'views/site/form/SiteForm'
import { SiteList } from 'views/site/SiteList'

export const SiteRoot: React.FC = () => (
  <Switch>
    <Route path={routes.site.list('forRoute')} component={SiteList} />
    <Route path={routes.site.create} component={SiteForm} />
    <Route path={routes.site.createWithPublisher()} component={SiteForm} />
    <Route path={routes.site.edit()} component={SiteForm} />
    <Redirect from="*" to={routes.site.list()} />
  </Switch>
)
