import { MinusCircleOutlined } from '@ant-design/icons'
import { Col, List } from 'antd'
import React from 'react'

import { FormRow } from 'components/form/containers/FormRow'
import { FormSelect } from 'components/form/inputs/FormSelect'

interface Props {
  categories: number[]
  setCategories: (categories: number[]) => void
  options: { value: number; label: string }[]
  disabled: boolean
}

export const FormCategoriesInput: React.FC<Props> = ({ categories, setCategories, options, disabled }) => (
  <>
    <FormRow>
      <FormSelect
        span={8}
        value={[] as number[]}
        placeholder="Search categories..."
        onChange={(newCategories) => setCategories([...categories, ...newCategories])}
        options={options.filter((option) => !categories?.includes(option.value as number))}
        disabled={disabled}
        selectProps={{ mode: 'multiple' }}
      />
    </FormRow>

    {!!categories?.length && (
      <FormRow style={{ marginBottom: 24 }}>
        <Col style={{ minWidth: 'calc(100% / 3)' }}>
          <List
            size="small"
            bordered
            dataSource={options.filter((option) => categories.includes(option.value))}
            renderItem={({ value, label }) => (
              <List.Item
                actions={[
                  <MinusCircleOutlined
                    onClick={() => setCategories(categories.filter((category) => category !== value))}
                  />,
                ]}
              >
                {label.replaceAll('/', ' / ')}
              </List.Item>
            )}
          />
        </Col>
      </FormRow>
    )}
  </>
)
