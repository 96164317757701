import { DirectVideoControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class DirectVideoApi {
  api = new DirectVideoControllerApi(configuration, configuration.basePath, customFetch)

  upload = (video: Blob) => {
    const formData = new FormData()
    formData.append('video', video)

    return fetch(`${configuration.basePath}/api/direct-video`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'secure-cookie': '*' },
      body: formData,
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      }
      throw response
    })
  }

  delete = (id: number) => this.api.deleteDirectVideo(id)
}

export const directVideoApi = new DirectVideoApi()
