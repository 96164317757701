import { FilterBean } from 'api/base/api'

import CountrySubdivisionListTypeEnum = FilterBean.CountrySubdivisionListTypeEnum

const { CountryListTypeEnum, DeviceListTypeEnum } = FilterBean

export function getDefaultFilterBean() {
  return {
    active: false,
    deviceListType: DeviceListTypeEnum.INCLUDEALL,
    deviceList: [],
    countryListType: CountryListTypeEnum.INCLUDEALL,
    countryIds: [],
    countrySubdivisionListType: CountrySubdivisionListTypeEnum.INCLUDEALL,
    countrySubdivisionIds: [],
  }
}
