import classNames from 'classnames'
import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { UserContext } from 'utils/userContext'

import { menuItems } from 'components/application/appLayout/menuItems'
import { MaterialUiButton } from 'components/application/materialUiButton/MaterialUiButton'

import { admaruLogoWhiteImage } from 'assets/images'

import styles from 'components/application/appLayout/sidebarMenu.module.scss'

export const SidebarMenu: React.FC = () => {
  const location = useLocation()
  const { canRead } = useContext(UserContext)

  const filteredItems = menuItems.filter(({ entity }) => !entity || canRead(entity))
  const selectedKeys = filteredItems
    .map((item) => item?.key as string)
    .filter((key) => location.pathname.startsWith(key as string))

  return (
    <nav className={styles.menu}>
      <img src={admaruLogoWhiteImage} className={styles.logo} />

      {filteredItems.map((item) =>
        item.group ? (
          <div key={item.key} className={styles.group}>
            {item.label}
          </div>
        ) : (
          <MaterialUiButton key={item.key} className={styles.button}>
            <Link
              to={item.key}
              className={classNames(styles.link, { [styles.link_selected]: selectedKeys.includes(item.key) })}
            >
              {item.icon}
              {item.label}
            </Link>
          </MaterialUiButton>
        )
      )}
    </nav>
  )
}
