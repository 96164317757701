import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Col } from 'antd'
import React, { useEffect } from 'react'

import { FormRow } from 'components/form/containers/FormRow'

interface Props<Entity> {
  label: React.ReactNode
  list: Entity[]
  idKey?: keyof Entity | ((entity: Entity) => number | string)
  updateList: (list: Entity[]) => void
  getNewEntity: () => Entity
  renderRow: (entity: Entity, index: number) => React.ReactNode
  minimumOneRow?: boolean
}

export const FormEntityList = <Entity extends object>({
  label,
  list,
  idKey,
  updateList,
  getNewEntity,
  renderRow,
  minimumOneRow,
}: Props<Entity>) => {
  useEffect(() => {
    if (minimumOneRow && !list.length) {
      updateList(minimumOneRow ? [getNewEntity()] : [])
    }
  }, [list])

  return (
    <>
      <FormRow>
        {typeof label === 'string' ? <Col style={{ marginBottom: 10 }}>{label}</Col> : label}

        {!minimumOneRow && !list.length && (
          <Col>
            <PlusCircleOutlined style={{ marginTop: 5 }} onClick={() => updateList([...list, getNewEntity()])} />
          </Col>
        )}
      </FormRow>

      {list.map((entity, index) => {
        const key = idKey
          ? typeof idKey === 'function'
            ? idKey(entity)
            : (entity[idKey] as string)
          : (entity as { id: string }).id

        return (
          <FormRow key={key} style={{ marginBottom: index === list.length - 1 ? 0 : -15 }}>
            {renderRow(entity, index)}

            {list.length > (minimumOneRow ? 1 : 0) && (
              <Col>
                <MinusCircleOutlined
                  style={{ marginTop: 9 }}
                  onClick={() => updateList([...list].filter((_, i) => i !== index))}
                />
              </Col>
            )}

            {index === list.length - 1 && (
              <Col>
                <PlusCircleOutlined style={{ marginTop: 9 }} onClick={() => updateList([...list, getNewEntity()])} />
              </Col>
            )}
          </FormRow>
        )
      })}
    </>
  )
}
