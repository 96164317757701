import { Card, Col, Row, Space, Spin } from 'antd'
import React, { useState } from 'react'

import { PublisherBean, StatRequestBean } from 'api/base/api'
import { reportApi } from 'api/report'

import { ReportForm } from 'views/report/form/ReportForm'
import { ReportResult } from 'views/report/result/ReportResult'
import { reportKeys } from 'views/report/utils/reportKeys'
import { ReportDictionaryElem, ReportResultData } from 'views/report/utils/reportTypes'
import { reportValues } from 'views/report/utils/reportValues'

const { UserCurrencyEnum } = PublisherBean

export const Report: React.FC = () => {
  const [isGenerating, setIsGenerating] = useState(false)

  const [resultData, setResultData] = useState<ReportResultData>({
    keys: [],
    response: {},
    values: [],
    currency: UserCurrencyEnum.USD,
  })

  const generate = (formData: StatRequestBean) => {
    setIsGenerating(true)
    reportApi
      .statistics({
        startDate: formData.startDate,
        endDate: formData.endDate,
        currency: formData.currency,
        timeZone: formData.timeZone,
        audit: formData.audit,
        keys: formData.keys,
        values: formData.values,
        filters: formData.filters,
        periodType: formData.periodType,
      })
      .then((response) =>
        setResultData({
          keys: formData.keys.map((key) => reportKeys.find((elem) => elem.value === key) as ReportDictionaryElem),
          values: formData.values.map(
            (value) => reportValues.find((elem) => elem.value === value) as ReportDictionaryElem
          ),
          response,
          currency: formData.currency as unknown as PublisherBean.UserCurrencyEnum,
        })
      )
      .finally(() => setIsGenerating(false))
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ReportForm generate={generate} isGenerating={isGenerating} />

      {isGenerating ? (
        <Card>
          <Row justify="space-around">
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
        </Card>
      ) : (
        resultData.response.rows && <ReportResult data={resultData} />
      )}
    </Space>
  )
}
