import { Col, Form, Select } from 'antd'
import React from 'react'

import { FormSelect } from 'components/form/inputs/FormSelect'

const options = Array.from(Array(24).keys()).map((value) => `${value <= 9 ? '0' : ''}${value}`)

export interface HourRange {
  id: string
  from: string
  to: string
}

interface Props {
  value: HourRange
  onChange: (value: HourRange) => void

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  selectProps?: React.ComponentProps<typeof Select>
}

export const FormHourRangePicker: React.FC<Props> = ({ value, onChange, colProps, itemProps, selectProps }) => (
  <>
    <Col {...colProps} style={{ marginTop: 4 }}>
      From:
    </Col>

    <FormSelect
      span={2}
      value={value.from}
      onChange={(from) => onChange({ ...value, from })}
      options={options.map((option) => ({ value: option, label: option }))}
      colProps={colProps}
      itemProps={itemProps}
      selectProps={selectProps}
    />

    <Col {...colProps} style={{ marginTop: 4 }}>
      To:
    </Col>

    <FormSelect
      span={2}
      value={value.to}
      onChange={(to) => onChange({ ...value, to })}
      options={options.map((option) => ({ value: option, label: option }))}
      colProps={colProps}
      itemProps={itemProps}
      selectProps={selectProps}
    />
  </>
)
