export const routes = {
  account: {
    ...createTypicalEntityUrls('account'),
  },
  adSize: {
    ...createTypicalEntityUrls('ad-size'),
  },
  adUnit: {
    ...createTypicalEntityUrls('ad-unit'),
    createWithSite: (siteId?: number) => `/ad-unit/create-with-site/${siteId ?? ':siteId'}`,
  },
  changePassword: {
    root: '/change-password',
  },
  dashboard: {
    root: '/dashboard',
  },
  deal: {
    ...createTypicalEntityUrls('deal'),
  },
  dsp: {
    ...createTypicalEntityUrls('dsp'),
  },
  generateTag: {
    root: '/generate-tag',
  },
  directAd: {
    ...createTypicalEntityUrls('direct-ad'),
  },
  directCampaign: {
    ...createTypicalEntityUrls('direct-campaign'),
  },
  logIn: {
    root: '/log-in',
  },
  logOut: {
    root: '/log-out',
  },
  payment: {
    ...createTypicalEntityUrls('payment'),
  },
  publisher: {
    ...createTypicalEntityUrls('publisher'),
  },
  report: {
    root: (id?: number | 'forRoute') => `/report${id === 'forRoute' ? '/:id?' : id ? `/${id}` : ''}`,
  },
  savedReport: {
    ...createTypicalEntityUrls('savedReport'),
  },
  site: {
    ...createTypicalEntityUrls('site'),
    createWithPublisher: (publisherId?: number) => `/site/create-with-publisher/${publisherId ?? ':publisherId'}`,
  },
} as const

function createTypicalEntityUrls(entity: string) {
  return {
    root: `/${entity}`,
    list: (id?: number | 'forRoute') => `/${entity}/list${id === 'forRoute' ? '/:id?' : id ? `/${id}` : ''}`,
    create: `/${entity}/create`,
    edit: (id?: number) => `/${entity}/edit/${id ?? ':id'}`,
  }
}
