import { Col, Row, Spin } from 'antd'
import React, { createContext, useEffect, useMemo, useState } from 'react'

import { accountApi } from 'api/account'
import { AccountBean } from 'api/base/api'

import { AbilityEntity, defineAbilities } from 'utils/ability'
import { userUpdateStore } from 'utils/userUpdateStore'

interface Values {
  isAuthorized: boolean
  canRead: (entity: AbilityEntity) => boolean
}

const defaultValues: Values = {
  isAuthorized: false,
  canRead: () => false,
}

export const UserContext = createContext(defaultValues)

interface Props {
  children: React.ReactNode
}

export const UserContextProvider: React.FC<Props> = ({ children }) => {
  const [role, setRole] = useState<AccountBean.RoleEnum>()
  const [isInfoRequested, setIsInfoRequested] = useState(false)

  const refreshUserInfo = () =>
    accountApi
      .checkUserInfo()
      .then((bean) => setRole(bean.isLoggedIn ? bean.accountBean?.role : undefined))
      .catch(() => setRole(undefined))

  useEffect(() => {
    refreshUserInfo().finally(() => setIsInfoRequested(true))

    const unsubscribe = userUpdateStore.subscribe(refreshUserInfo)

    return unsubscribe
  }, [])

  const value = useMemo<Values>(
    () => ({ isAuthorized: !!role, canRead: (entity) => defineAbilities(role).can('read', entity) }),
    [role]
  )

  return isInfoRequested ? (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  ) : (
    <Row style={{ height: '100vh' }} justify="space-around" align="middle">
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  )
}
