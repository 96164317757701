import { ColumnsType } from 'antd/es/table'
import React, { useContext, useState } from 'react'

import { PaymentBeanInList, PublisherBean } from 'api/base/api'
import { paymentApi } from 'api/payment'
import { publisherApi } from 'api/publisher'

import { paymentStatusToName } from 'utils/enumToName'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'
import { UserContext } from 'utils/userContext'

import { FormRadioGroup, FormRow, FormSelect } from 'components/form'
import {
  DateFilter,
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListTable,
  SelectFilter,
  useListFilters,
} from 'components/list'

import { PaymentListExpandable } from 'views/payment/list/PaymentListExpandable'
import { PaymentStatusIndicator } from 'views/payment/list/PaymentStatusIndicator'

const { StatusEnum } = PaymentBeanInList
const { UserCurrencyEnum } = PublisherBean

export const PaymentList: React.FC = () => {
  const { canRead } = useContext(UserContext)

  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => paymentApi.list(filters), filters)

  const [currency, setCurrency] = useState(UserCurrencyEnum.USD)
  const currencySign = currency === UserCurrencyEnum.USD ? '$' : '₩'
  const convertCurrency = (usdAmount: number | undefined, krwRate: number | undefined) =>
    currency === UserCurrencyEnum.USD ? usdAmount ?? 0 : +((usdAmount ?? 0) * (krwRate ?? 1)).toFixed(2)

  const { columnWidth } = getListColumnWidth(canRead('paymentPublisherColumn') ? 8 : 7, 'noNameColumn')

  const columns: ColumnsType<PaymentBeanInList> = [
    {
      title: 'Publisher',
      ellipsis: true,
      width: columnWidth,
      render: (_, { sellerName }) => sellerName,
      filterIcon: <FilterIcon active={!!filters.publisherId} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.publisherId}
          onChange={(value) => setFilter('publisherId', value)}
          getOptions={() =>
            publisherApi.listAll().then((beans) => beans.map(({ id, name }) => ({ text: name as string, value: id })))
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Payment Period',
      ellipsis: true,
      width: columnWidth,
      render: (_, { period }) => period,
      filterIcon: <FilterIcon active={!!filters.period} />,
      filterDropdown: (props) => (
        <DateFilter
          value={filters.period}
          onChange={(value) => setFilter('period', value)}
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Status',
      ellipsis: true,
      width: columnWidth,
      render: (_, { status }) => (
        <>
          <PaymentStatusIndicator status={status} />
          {paymentStatusToName(status)}
        </>
      ),
      filterIcon: <FilterIcon active={!!filters.status} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.status}
          onChange={(value) => setFilter('status', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Draft', value: StatusEnum.DRAFT.toString() },
              { text: 'Pending', value: StatusEnum.PENDING.toString() },
              { text: 'Paid', value: StatusEnum.PAID.toString() },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Date Paid (ET)',
      ellipsis: true,
      width: columnWidth,
      render: (_, { paidDate }) => paidDate ?? '-',
    },
    {
      title: `Net Revenue (${currency})`,
      ellipsis: true,
      width: columnWidth,
      render: (_, { calculatedCostUSD, calculatedCostKRW }) =>
        `${currencySign}${(currency === UserCurrencyEnum.USD ? calculatedCostUSD : calculatedCostKRW) ?? 0}`,
    },
    {
      title: `Adjustments (${currency})`,
      ellipsis: true,
      width: columnWidth,
      render: (_, { adjustments, rateKRW }) => {
        const amount = adjustments?.reduce((acc, item) => acc + convertCurrency(item.amountUSD, rateKRW), 0) ?? 0

        return Intl.NumberFormat('en-US', {
          maximumFractionDigits: 4,
          currency: currency.toString(),
          style: 'currency',
        }).format(amount)
      },
    },
    {
      title: `Adjusted Net Revenue (${currency})`,
      ellipsis: true,
      width: columnWidth,
      render: (_, { finalCostUSD, finalCostKRW }) =>
        `${currencySign}${(currency === UserCurrencyEnum.USD ? finalCostUSD : finalCostKRW) ?? 0}`,
    },
    {
      title: 'Action',
      width: columnWidth,
      render: (_, { id }) => <ListLink to={routes.payment.edit(id)}>Edit</ListLink>,
    },
  ]

  return (
    <>
      <FormRow>
        <FormSelect
          span={4}
          value={currency}
          onChange={(value) => setCurrency(value)}
          options={[
            { value: UserCurrencyEnum.USD, label: 'USD' },
            { value: UserCurrencyEnum.KRW, label: 'KRW' },
          ]}
        />
        <FormRadioGroup
          value={filters.range}
          onChange={(range) => setFilter('range', range)}
          options={[
            { value: 'YEAR_TO_DATE', label: 'YEAR-TO-DATE' },
            { value: 'LAST_12_MONTHS', label: 'LAST 12 MONTHS' },
            { value: 'LAST_24_MONTH', label: 'LAST 24 MONTHS' },
            { value: 'ALL', label: 'ALL' },
          ]}
        />
      </FormRow>

      <ListTable
        entityName="payment"
        columns={columns.filter((column) => canRead('paymentPublisherColumn') || column.title !== 'Publisher')}
        list={list}
        isLoading={isLoading}
        currentPage={filters.page}
        totalEntities={totalEntities}
        onPageChange={(page) => setFilter('page', page)}
        createLink={routes.payment.create}
        expandable={{
          expandedRowRender: (payment) => (
            <PaymentListExpandable
              payment={payment}
              currency={currency}
              currencySign={currencySign}
              convertCurrency={convertCurrency}
            />
          ),
        }}
      />
    </>
  )
}
