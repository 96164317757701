import { Card, Col } from 'antd'
import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { DashboardTopBean, DashboardTopRequestBean } from 'api/base/api'

import { formatDashboardResult } from 'views/dashboard/utils/formatDashboardResult'
import { reportKeys } from 'views/report/utils/reportKeys'
import { reportValues } from 'views/report/utils/reportValues'

interface Props {
  topKey: DashboardTopRequestBean.KeysEnum
  topValues: DashboardTopRequestBean.ValuesEnum[]
  response?: DashboardTopBean
  currency: string
}

export const DashboardTopChart: React.FC<Props> = ({ topKey, topValues, response, currency }) => (
  <Col span={8} style={{ marginBottom: 10 }}>
    <Card
      title={`Top ${reportKeys.find((el) => el.value === topKey)?.labelPlural}`}
      headStyle={{ border: 'none' }}
      bodyStyle={{ paddingTop: 0 }}
      size="small"
    >
      <ResponsiveContainer height={150}>
        <BarChart data={response?.data?.[topKey]}>
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis dataKey={topKey} tickFormatter={(value) => (value.length > 10 ? `${value.slice(0, 10)}...` : value)} />
          <YAxis tickFormatter={(value) => formatDashboardResult(value as number, 'money', currency) as string} />

          <Tooltip formatter={(value) => formatDashboardResult(value as number, 'money', currency)} />
          <Legend verticalAlign="top" align="right" />

          {topValues.map((value) => (
            <Bar dataKey={value} name={reportValues.find((el) => el.value === value)?.label} fill="#597ef7" />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Card>
  </Col>
)
