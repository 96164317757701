import { LoadingOutlined } from '@ant-design/icons'
import { Button, Col, Form, Upload, UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import React from 'react'

interface Props {
  span: string | number
  label?: string
  imageUrl?: string
  onChange: (info: UploadChangeParam<UploadFile>) => void
  customRequest: React.ComponentProps<typeof Upload>['customRequest']
  required?: boolean
  disabled?: boolean
  loading: boolean
  width?: number
  height?: number

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  uploadProps?: React.ComponentProps<typeof Upload>
}

const uploadButton = (loading: boolean) => (
  <Button style={{ marginTop: 16 }}>{loading ? <LoadingOutlined /> : 'Upload file'}</Button>
)

export const FormUploadImage: React.FC<Props> = ({
  span,
  label,
  imageUrl,
  onChange,
  customRequest,
  required,
  disabled,
  loading,
  width,
  height,
  colProps,
  itemProps,
  uploadProps,
}) => (
  <Col span={span} {...colProps}>
    <Form.Item label={label} required={required} {...itemProps}>
      <Upload
        showUploadList={false}
        customRequest={customRequest}
        onChange={onChange}
        disabled={disabled}
        {...uploadProps}
      >
        {imageUrl && <img src={imageUrl} style={{ width: `${width}px`, height: `${height}px` }} alt="file" />}
        {uploadButton(loading)}
      </Upload>
    </Form.Item>
  </Col>
)
