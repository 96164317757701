import { Form, Input } from 'antd'
import React from 'react'

import { accountApi } from 'api/account'

import { userUpdateStore } from 'utils/userUpdateStore'

import { MaterialUiButton } from 'components/application'

import { admaruLogoBlackImage } from 'assets/images'

import styles from 'views/logIn/logIn.module.scss'

export const LogIn: React.FC = () => {
  const onFinish = (values: { username: string; password: string }) => {
    accountApi.login(values.username, values.password).then(userUpdateStore.updateInfo)
  }

  return (
    <div className={styles.container}>
      <Form className={styles.form} onFinish={onFinish}>
        <img src={admaruLogoBlackImage} className={styles.logo} />

        <Form.Item className={styles.formItem} name="username" rules={[{ required: true, message: 'Required*' }]}>
          <Input className={styles.input} placeholder="Email Address" />
        </Form.Item>

        <Form.Item className={styles.formItem} name="password" rules={[{ required: true, message: 'Required*' }]}>
          <Input className={styles.input} type="password" placeholder="Password" />
        </Form.Item>

        <MaterialUiButton type="submit" className={styles.button}>
          SIGN IN
        </MaterialUiButton>
      </Form>
    </div>
  )
}
