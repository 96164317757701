import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Row, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useState } from 'react'

import { AdUnitBean, DirectCampaignBean, NamedBean, SimpleSiteBean, SiteBean } from 'api/base/api'

import { createMessageWithDuration } from 'utils/createMessage'

import { FormRow, FormSelect } from 'components/form'

interface Props {
  formState: Partial<DirectCampaignBean>
  setFormState: (formState: Partial<DirectCampaignBean>) => void
  publisherList: NamedBean[]
  siteList: SimpleSiteBean[]
  adUnitList: AdUnitBean[]
}

interface LinkageRow {
  publisher: NamedBean
  site: SiteBean
  adUnit: AdUnitBean
}

export const DirectCampaignAdUnitTab: React.FC<Props> = ({
  formState,
  setFormState,
  publisherList,
  adUnitList,
  siteList,
}) => {
  const [chosenPublisher, setChosenPublisher] = useState<number>()
  const [chosenSite, setChosenSite] = useState<number>()
  const [chosenAdUnit, setChosenAdUnit] = useState<number>()

  function buildLinkageRowFromAdUnitId(adUnitId: number) {
    const adUnit = adUnitList.find((adUnit) => adUnit.id === adUnitId) || ({ id: adUnitId } as AdUnitBean)
    const site = siteList.find((siteElem) => siteElem.id === adUnit.siteId) || ({ id: adUnit.siteId } as SiteBean)
    const publisher =
      publisherList.find((pub) => pub.id === site.publisherId) || ({ id: site.publisherId } as NamedBean)
    return { publisher, site, adUnit } as LinkageRow
  }

  const [mappingList, setMappingList] = useState<LinkageRow[]>(
    () => formState.linkedAdUnits?.map((adUnitId) => buildLinkageRowFromAdUnitId(adUnitId)) || []
  )

  function addAdUnit() {
    if (!chosenAdUnit) {
      createMessageWithDuration('error', 'Ad Unit not chosen', 2)
      return
    }
    if (mappingList.some((row) => row.adUnit.id === chosenAdUnit)) {
      createMessageWithDuration('warning', 'Ad Unit is already added', 2)
      return
    }
    const newMappingList = [...mappingList]
    newMappingList.push(buildLinkageRowFromAdUnitId(chosenAdUnit))
    setMappingList(newMappingList)
    setFormState({ linkedAdUnits: newMappingList.map((row) => row.adUnit.id || 0).filter((id) => id !== 0) })
    createMessageWithDuration('success', `Ad Unit ${chosenAdUnit} was added`, 1.5)
  }

  function removeAdUnit(adUnitId: number) {
    if (mappingList.some((elem) => elem.adUnit.id === adUnitId)) {
      const newList = mappingList.filter((elem) => elem.adUnit.id !== adUnitId)
      setMappingList(newList)
      setFormState({ linkedAdUnits: newList.map((row) => row.adUnit.id || 0).filter((id) => id !== 0) })
      createMessageWithDuration('success', `Unlink Ad unit ${adUnitId}`, 1.5)
    } else {
      createMessageWithDuration('warning', 'Ad Unit id mismatch', 2)
    }
  }

  const columns: ColumnsType<LinkageRow> = [
    {
      title: 'Publisher',
      width: '25%',
      render: (_, { publisher }) => `${publisher.name} (id=${publisher.id})`,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const pub1 = a.publisher.name || ''
        const pub2 = b.publisher.name || ''
        return pub1.localeCompare(pub2)
      },
    },
    {
      title: 'Site',
      width: '25%',
      render: (_, { site }) => `${site.name} (id=${site.id})`,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const first = a.site.name || ''
        const second = b.site.name || ''
        return first.localeCompare(second)
      },
    },
    {
      title: 'Ad Unit',
      width: '30%',
      render: (_, { adUnit }) => `${adUnit.name} (id=${adUnit.id})`,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const first = a.adUnit.name || ''
        const second = b.adUnit.name || ''
        return first.localeCompare(second)
      },
    },
    {
      title: '',
      width: '5%',
      render: (_, { adUnit }) => (
        <Tooltip title="Unlink">
          <DeleteOutlined onClick={() => removeAdUnit(adUnit.id || 0)} />
        </Tooltip>
      ),
    },
  ]

  return (
    <>
      <FormRow>
        <FormSelect
          span={6}
          label="Publisher"
          placeholder="Select Publisher"
          value={chosenPublisher}
          onChange={(chosenPublisher) => {
            setChosenPublisher(chosenPublisher)
            setChosenSite(undefined)
            setChosenAdUnit(undefined)
          }}
          options={publisherList.map((publisher) => ({
            value: publisher.id,
            label: `${publisher.name} (id=${publisher.id})`,
          }))}
        />

        <FormSelect
          span={6}
          label="Site"
          placeholder={chosenPublisher ? 'Select site' : 'Select Site after Publisher'}
          value={chosenSite}
          disabled={!chosenPublisher}
          onChange={(chosenSite) => {
            setChosenSite(chosenSite)
            setChosenAdUnit(undefined)
          }}
          options={siteList
            .filter((site) => site.publisherId === chosenPublisher)
            .map((site) => ({
              value: site.id,
              label: `${site.name} (id=${site.id})`,
            }))}
        />

        <FormSelect
          span={6}
          label="Ad Unit"
          placeholder={chosenSite ? 'Select Ad Unit' : 'Select Ad Unit after Site'}
          value={chosenAdUnit}
          disabled={!chosenSite}
          onChange={(chosenAdUnit) => setChosenAdUnit(chosenAdUnit)}
          options={adUnitList
            .filter((adUnit) => adUnit.siteId === chosenSite)
            .map((adUnit) => ({
              value: adUnit.id,
              label: `${adUnit.name} (id=${adUnit.id})`,
            }))}
        />
        <Button onClick={() => addAdUnit()} type="primary" style={{ marginTop: 30, marginLeft: 6 }}>
          Add
        </Button>
      </FormRow>
      <Row style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={mappingList}
            rowKey="adUnitLinkage"
            size="small"
            pagination={false}
            style={{ marginBottom: 8 }}
          />
        </Col>
      </Row>
    </>
  )
}
