import { DirectCampaignBean, DirectCampaignControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class DirectCampaignApi {
  api = new DirectCampaignControllerApi(configuration, configuration.basePath, customFetch)

  allNamed = () => this.api.getDirectCampaignList()

  list = ({ id, page, active, name, cappingType, passingType }: ListFilters) =>
    this.api.directCampaignListByFilters(id, active, name, cappingType, passingType, {
      query: {
        size: LIST_PAGE_SIZE,
        page,
      },
    })

  entityForList = (id: number) => this.api.getDirectCampaignForList(id)

  entity = (id: number) => this.api.getDirectCampaign(id)

  create = (bean: DirectCampaignBean) => this.api.createDirectCampaign(bean)

  edit = (bean: DirectCampaignBean) => this.api.updateDirectCampaign(bean, bean.id as number)

  delete = (id: number) => this.api.deleteDirectCampaign(id)
}

export const directCampaignApi = new DirectCampaignApi()
