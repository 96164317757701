import { AbilityBuilder, createMongoAbility } from '@casl/ability'

import { AccountBean } from 'api/base/api'

const { RoleEnum } = AccountBean

export type AbilityEntity =
  | 'account'
  | 'adSize'
  | 'adUnit'
  | 'changePassword'
  | 'dashboard'
  | 'deal'
  | 'dsp'
  | 'generatedTag'
  | 'grossReport'
  | 'directAd'
  | 'directCampaign'
  | 'logout'
  | 'payment'
  | 'paymentEdit'
  | 'paymentPublisherColumn'
  | 'publisher'
  | 'report'
  | 'savedReport'
  | 'site'

export function defineAbilities(role: AccountBean.RoleEnum | undefined) {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  const adminAbilities: AbilityEntity[] = [
    'account',
    'adSize',
    'adUnit',
    'dashboard',
    'deal',
    'dsp',
    'generatedTag',
    'grossReport',
    'directAd',
    'directCampaign',
    'logout',
    'payment',
    'paymentEdit',
    'paymentPublisherColumn',
    'publisher',
    'report',
    'savedReport',
    'site',
  ]
  const publisherAbilities: AbilityEntity[] = [
    'adUnit',
    'changePassword',
    'dashboard',
    'deal',
    'generatedTag',
    'logout',
    'payment',
    'report',
    'savedReport',
    'site',
  ]

  if (role === RoleEnum.Admin) {
    adminAbilities.forEach((entity) => can('read', entity))
  }

  if (role === RoleEnum.Publisher) {
    publisherAbilities.forEach((entity) => can('read', entity))
  }

  return build()
}
