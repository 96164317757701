import React, { useContext } from 'react'

import { AdUnitBean, NamedBean, SimpleSiteBean, SiteBean } from 'api/base/api'

import { biddingCurrencyToName } from 'utils/enumToName'
import { sortAdSize } from 'utils/sort'
import { UserContext } from 'utils/userContext'

import { FormNumberInput, FormRow, FormSelect, FormSwitch, FormTextInput } from 'components/form'

const { AuctionTypeEnum, AdUnitTypeEnum } = AdUnitBean

interface Props {
  formState: Partial<AdUnitBean>
  setFormState: (formState: Partial<AdUnitBean>) => void
  isCreation: boolean
  dspList: NamedBean[]
  siteList: SimpleSiteBean[]
  adSizeList: NamedBean[]
  dealList: NamedBean[]
  campaignList: NamedBean[]
}

export const MainTab: React.FC<Props> = ({
  formState,
  setFormState,
  isCreation,
  dspList,
  siteList,
  adSizeList,
  dealList,
  campaignList,
}) => {
  const canReadDsp = useContext(UserContext).canRead('dsp')

  const needClearBannerAdSizes = (
    oldCreativeType: AdUnitBean.AdUnitTypeEnum,
    newCreativeType: AdUnitBean.AdUnitTypeEnum
  ) => {
    if (newCreativeType === AdUnitTypeEnum.NATIVE) {
      return true
    }
    if (newCreativeType === AdUnitTypeEnum.MULTI) {
      return false
    }
    if (oldCreativeType === AdUnitTypeEnum.MULTI) {
      return newCreativeType !== AdUnitTypeEnum.BANNER
    }
    return true
  }
  const needClearVideoAdSize = (
    oldCreativeType: AdUnitBean.AdUnitTypeEnum,
    newCreativeType: AdUnitBean.AdUnitTypeEnum
  ) => {
    if (newCreativeType === AdUnitTypeEnum.NATIVE) {
      return true
    }
    if (newCreativeType === AdUnitTypeEnum.MULTI) {
      return false
    }
    if (oldCreativeType === AdUnitTypeEnum.MULTI) {
      return newCreativeType !== AdUnitTypeEnum.VIDEO
    }
    return true
  }

  const getOwnerRtbDetailsType = () => siteList.find((site) => site.id === formState.siteId)?.rtbDetailsType

  const getCreativeTypeOptions = () => [
    { value: AdUnitTypeEnum.BANNER, label: 'Banner' },
    { value: AdUnitTypeEnum.VIDEO, label: 'Video' },
    { value: AdUnitTypeEnum.MULTI, label: 'Multi' },
    { value: AdUnitTypeEnum.NATIVE, label: 'Native' },
  ]

  return (
    <>
      <FormRow>
        <FormSwitch
          span={2}
          label="Active"
          checked={formState.active}
          onChange={() => setFormState({ active: !formState.active })}
        />
        <FormSwitch
          span={2}
          label="Audit"
          checked={formState.test}
          onChange={() => setFormState({ test: !formState.test })}
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Name"
          value={formState.name}
          onChange={(name) => setFormState({ name })}
          required
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          label="Site/App"
          value={formState.siteId}
          onChange={(siteId) => setFormState({ siteId })}
          options={siteList.map((site) => ({ value: site.id, label: site.name }))}
          disabled={!isCreation}
          required
        />
      </FormRow>

      {getOwnerRtbDetailsType() === SiteBean.RtbDetailsTypeEnum.APP && (
        <FormRow>
          <FormTextInput
            span={8}
            label="Prebid Server Configuration ID"
            value={formState.configId}
            onChange={(configId) => setFormState({ configId })}
            required
          />
        </FormRow>
      )}

      <FormRow>
        <FormNumberInput
          span={4}
          label="Bid floor"
          value={formState.bidFloor}
          onChange={(bidFloor) => setFormState({ bidFloor })}
          required
          inputProps={{
            prefix: biddingCurrencyToName(siteList?.find((el) => el.id === formState.siteId)?.biddingCurrency),
            min: 0,
          }}
        />
        <FormSwitch
          span={3}
          label="Deals Only"
          checked={formState.privateAuction}
          onChange={() => setFormState({ privateAuction: !formState.privateAuction })}
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={4}
          label="Auction type"
          value={formState.auctionType}
          onChange={(auctionType) => setFormState({ auctionType, linkedCampaignIds: [] })}
          options={[
            { value: AuctionTypeEnum.FIRSTPRICE, label: 'First price' },
            { value: AuctionTypeEnum.SECONDPRICE, label: 'Second price' },
          ]}
          required
        />

        <FormSelect
          span={4}
          label="Creative type"
          value={formState.adUnitType}
          onChange={(adUnitType) =>
            setFormState({
              adUnitType,
              bannerAdSizeIds: needClearBannerAdSizes(formState.adUnitType || AdUnitTypeEnum.MULTI, adUnitType)
                ? []
                : formState.bannerAdSizeIds,
              videoAdSizeId: needClearVideoAdSize(formState.adUnitType || AdUnitTypeEnum.MULTI, adUnitType)
                ? undefined
                : formState.videoAdSizeId,
            })
          }
          options={getCreativeTypeOptions()}
          required
        />
      </FormRow>

      {getOwnerRtbDetailsType() !== SiteBean.RtbDetailsTypeEnum.APP && (
        <FormRow>
          {(formState.adUnitType === AdUnitTypeEnum.BANNER || formState.adUnitType === AdUnitTypeEnum.MULTI) && (
            <FormSelect
              span={8}
              label="Banner Ad sizes"
              value={formState.bannerAdSizeIds}
              onChange={(bannerAdSizeIds) => setFormState({ bannerAdSizeIds })}
              options={adSizeList.map((adSize) => ({ value: adSize.id, label: adSize.name }))}
              required
              selectProps={{
                mode: 'multiple',
                allowClear: true,
                filterSort: sortAdSize,
              }}
            />
          )}
          {(formState.adUnitType === AdUnitTypeEnum.VIDEO || formState.adUnitType === AdUnitTypeEnum.MULTI) && (
            <FormSelect
              span={8}
              label="Video Ad size"
              value={formState.videoAdSizeId}
              onChange={(videoAdSizeId) => setFormState({ videoAdSizeId })}
              options={adSizeList.map((adSize) => ({ value: adSize.id, label: adSize.name }))}
              required
              selectProps={{
                allowClear: true,
                filterSort: sortAdSize,
                showSearch: true,
              }}
            />
          )}
        </FormRow>
      )}

      <FormRow>
        <FormSelect
          span={8}
          label="Deals"
          value={formState.dealIds}
          onChange={(dealIds) => setFormState({ dealIds })}
          options={dealList.map((deal) => ({ value: deal.id, label: deal.name }))}
          selectProps={{ mode: 'multiple' }}
        />
      </FormRow>

      {canReadDsp && (
        <FormRow>
          <FormSelect
            span={8}
            label="DSPs"
            value={formState.dsps}
            onChange={(dsps) => setFormState({ dsps })}
            options={dspList.map((dsp) => ({ value: dsp.id, label: dsp.name }))}
            selectProps={{ mode: 'multiple' }}
          />
        </FormRow>
      )}
      {canReadDsp && formState.auctionType === AdUnitBean.AuctionTypeEnum.FIRSTPRICE && (
        <FormRow>
          <FormSelect
            span={8}
            label="Direct Campaigns"
            value={formState.linkedCampaignIds}
            onChange={(linkedCampaignIds) => setFormState({ linkedCampaignIds })}
            options={campaignList.map((dsp) => ({ value: dsp.id, label: dsp.name }))}
            selectProps={{ mode: 'multiple' }}
          />
        </FormRow>
      )}
    </>
  )
}
