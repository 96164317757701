import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export interface ListFilters {
  page: number
  id: number | undefined
  publicId: string
  name: string
  adSizeName: string
  publisherId: number | undefined
  siteId: number | undefined
  siteType: string | undefined
  creativeType: string | undefined
  directAdType: string | undefined
  linkedEntityId: number | undefined
  test: boolean | undefined
  active: boolean | undefined
  favorite: boolean | undefined
  shared: boolean | undefined
  bidFloor: number | undefined
  status: string | undefined
  range: string
  period: Dayjs | undefined
  cappingType: string | undefined
  passingType: string | undefined
}

const defaultFilters: ListFilters = {
  page: 0,
  id: undefined,
  publicId: '',
  name: '',
  adSizeName: '',
  publisherId: undefined,
  siteId: undefined,
  siteType: undefined,
  creativeType: undefined,
  directAdType: undefined,
  linkedEntityId: undefined,
  test: undefined,
  active: true,
  favorite: undefined,
  shared: undefined,
  bidFloor: undefined,
  status: undefined,
  range: 'LAST_12_MONTHS',
  period: undefined,
  cappingType: undefined,
  passingType: undefined,
}

const cache: { [key: string]: ListFilters } = {}

export function useListFilters(initialFilters?: Partial<ListFilters>) {
  const location = useLocation()
  const key = location.pathname.split('/').slice(0, 3).join('/')

  const [filters, setFilters] = useState<ListFilters>({ ...defaultFilters, ...cache[key], ...initialFilters })

  function setFilter<T extends keyof ListFilters>(filter: T, value: ListFilters[T]) {
    filters.page = 0
    setFilters((filters) => ({ ...filters, [filter]: value }))
  }

  useEffect(() => {
    cache[key] = filters
  }, [filters])

  return { filters, setFilter }
}
