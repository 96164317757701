import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export function useSelectListEntity<Entity extends { id?: number }>(
  fetchEntity: (id: number) => Promise<Entity>,
  getListUrl: (id?: number) => string,
  list: { id?: number }[]
) {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const isLoadingRef = useRef(false)
  const [selected, setSelected] = useState<Entity | undefined>()

  const selectEntity = (id?: number) => history.push(getListUrl(id))

  useEffect(() => {
    if (id) {
      isLoadingRef.current = true

      fetchEntity(+id)
        .then((data) => setSelected(data))
        .finally(() => {
          isLoadingRef.current = false
        })
    } else {
      setSelected(undefined)
    }
  }, [id])

  useEffect(() => {
    if (list[0]?.id && !selected && !isLoadingRef.current) {
      selectEntity(list[0].id)
    }
  }, [list])

  return { selected, selectEntity }
}
