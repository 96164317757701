import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

import { SavedReportBeanInList } from 'api/base/api'
import { reportApi } from 'api/report'

import { boolToText } from 'utils/boolToText'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  NumberFilter,
  SelectFilter,
  TextFilter,
  useListFilters,
  useSelectListEntity,
} from 'components/list'

export const SavedReportList: React.FC = () => {
  const { filters, setFilter } = useListFilters()
  const [list, { refreshList, totalEntities, isLoading }] = useGetList(() => reportApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity((id) => reportApi.entity(id), routes.savedReport.list, list)

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(2)

  const columns: ColumnsType<SavedReportBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
      filterIcon: <FilterIcon active={!!filters.id} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.id} onChange={(value) => setFilter('id', value)} filterProps={props} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.report.root(id)}>{name}</ListLink>,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Favorite',
      dataIndex: 'favorite',
      width: columnWidth,
      ellipsis: true,
      render: (_, { favorite }) => boolToText(favorite),
      filterIcon: <FilterIcon active={filters.favorite !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.favorite}
          onChange={(value) => setFilter('favorite', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Shared',
      dataIndex: 'shared',
      width: columnWidth,
      ellipsis: true,
      render: (_, { shared }) => boolToText(shared),
      filterIcon: <FilterIcon active={filters.shared !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.favorite}
          onChange={(value) => setFilter('favorite', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
  ]

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="saved report"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.report.root(selected.id)}
            additionalLinks={[
              {
                name: 'Delete',
                onClick: () => {
                  if (selected.id) {
                    reportApi.delete(selected.id).then(() => refreshList())
                  }
                },
              },
            ]}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Name', value: selected.name },
                  { name: 'Favorite', value: boolToText(selected.favorite) },
                  { name: 'Shared', value: boolToText(selected.shared) },
                ],
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
