import React from 'react'
import { useHistory } from 'react-router-dom'

import { DealBean, FilterBean } from 'api/base/api'
import { dealApi } from 'api/deal'

import { routes } from 'utils/routes'

import { FormActionButtons, FormContainer, FormFiltersTab } from 'components/form'

import { MainTab } from 'views/deal/form/MainTab'
import { useDealFormData } from 'views/deal/form/useDealFormData'

export const DealForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formName, formState, setFormState, publisherList, siteList, adUnitList } =
    useDealFormData()

  const redirectToList = () => {
    history.push(routes.deal.list())
  }

  const submit = () => {
    const bean = formState as DealBean

    if (isCreation) {
      dealApi.create(bean).then(redirectToList)
    } else {
      dealApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    dealApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer
      title={isCreation ? 'New Deal' : formName}
      isLoading={isFormLoading}
      tabs={[
        {
          name: 'Main',
          tab: (
            <MainTab
              formState={formState}
              setFormState={setFormState}
              isCreation={isCreation}
              publisherList={publisherList}
              siteList={siteList}
              adUnitList={adUnitList}
            />
          ),
        },
        {
          name: 'Filters',
          tab: (
            <FormFiltersTab
              filterBean={formState.filterBean as FilterBean}
              setFilterBean={(filterBean) => setFormState({ filterBean })}
            />
          ),
        },
      ]}
    >
      <FormActionButtons isCreation={isCreation} onBack={redirectToList} onDelete={remove} onSubmit={submit} />
    </FormContainer>
  )
}
