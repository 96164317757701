import { Card, Col } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { createMessage } from 'utils/createMessage'

import { FormContainer, FormRow, FormSelect } from 'components/form'

import { generateTag } from 'views/generateTag/generateTag'
import {
  generatedTagTypes,
  GenerateTagFormData,
  useGenerateTagFormData,
} from 'views/generateTag/useGenerateTagFormData'

export const GenerateTagForm: React.FC = () => {
  const {
    isFormLoading,
    formState,
    setFormState,
    publisherList,
    siteFilteredList,
    adUnitFilteredList,
    adSizeFilteredList,
  } = useGenerateTagFormData()

  const tag = formState.adUnitId ? generateTag(formState as GenerateTagFormData) : ''

  const copy = () => {
    navigator.clipboard.writeText(tag)
    createMessage('success', 'Text is copied to clipboard')
  }

  return (
    <FormContainer title="Generate Tag" isLoading={isFormLoading}>
      <FormRow>
        <FormSelect
          span={8}
          label="Type"
          value={formState.type}
          onChange={(type) => setFormState({ type })}
          options={generatedTagTypes.map((type) => ({ value: type, label: type }))}
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          label="Publisher"
          value={formState.publisherId}
          onChange={(publisherId) => setFormState({ publisherId, siteId: undefined, adUnitId: undefined })}
          options={publisherList.map((publisher) => ({ value: publisher.id, label: publisher.name }))}
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          label="Site/App"
          value={formState.siteId}
          onChange={(siteId) => setFormState({ siteId, adUnitId: undefined })}
          options={siteFilteredList.map((site) => ({ value: site.id, label: site.name }))}
          disabled={!formState.publisherId}
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          label="Ad Unit"
          value={formState.adUnitId}
          onChange={(adUnitId) => setFormState({ adUnitId })}
          options={adUnitFilteredList.map((adUnit) => ({ value: adUnit.id, label: adUnit.name }))}
          disabled={!formState.siteId}
        />
      </FormRow>

      {!!adSizeFilteredList.length && (
        <FormRow>
          <Col style={{ marginBottom: 24 }}>
            Possible sizes: {adSizeFilteredList.map((size) => size.name).join(', ')}
          </Col>
        </FormRow>
      )}

      {tag && (
        <FormRow>
          <Col>
            <Card
              title="Result"
              extra={
                <Link to="#" onClick={copy}>
                  Copy
                </Link>
              }
              bodyStyle={{ whiteSpace: 'pre', minWidth: 468 }}
            >
              {tag}
            </Card>
          </Col>
        </FormRow>
      )}
    </FormContainer>
  )
}
