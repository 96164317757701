import React from 'react'
import { useHistory } from 'react-router-dom'

import { PublisherBean } from 'api/base/api'
import { dspApi } from 'api/dsp'
import { publisherApi } from 'api/publisher'

import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import { FormActionButtons, FormCategoriesTab, FormContainer, FormDspMappingTab } from 'components/form'

import { MainTab } from 'views/publisher/form/MainTab'
import { usePublisherFormData } from 'views/publisher/form/usePublisherFormData'

export const PublisherForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formName, formState, setFormState } = usePublisherFormData()

  const redirectToList = () => {
    history.push(routes.publisher.list())
  }

  const [dspList] = useGetList(dspApi.listAll)

  const submit = () => {
    const bean = formState as PublisherBean

    if (isCreation) {
      publisherApi.create(bean).then(redirectToList)
    } else {
      publisherApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    publisherApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer
      title={isCreation ? 'New publisher' : formName}
      isLoading={isFormLoading}
      tabs={[
        { name: 'Main', tab: <MainTab formState={formState} setFormState={setFormState} isCreation={isCreation} /> },
        // filters removed to avoid conflicts with direct campaigns
        {
          name: 'Categories',
          tab: (
            <FormCategoriesTab
              version={formState.iabTaxonomyVersion}
              setVersion={(iabTaxonomyVersion) => setFormState({ iabTaxonomyVersion })}
              linked={formState.categories}
              setLinked={(categories) => setFormState({ categories })}
              blocked={formState.blockedCategories}
              setBlocked={(blockedCategories) => setFormState({ blockedCategories })}
            />
          ),
        },
        {
          name: 'DSP ID Mapping',
          tab: (
            <FormDspMappingTab
              entityId={formState.id}
              dspList={dspList}
              mappingList={formState.dspIdMappings || []}
              setMappingList={(dspIdMappings) => setFormState({ ...formState, dspIdMappings })}
            />
          ),
        },
      ]}
    >
      <FormActionButtons isCreation={isCreation} onBack={redirectToList} onDelete={remove} onSubmit={submit} />
    </FormContainer>
  )
}
