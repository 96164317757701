import { FilterBean } from 'api/base/api'
import { countryApi } from 'api/country'

import { boolToText } from 'utils/boolToText'
import { countryListTypeToName, deviceListToName, deviceListTypeToName } from 'utils/enumToName'
import { useGetList } from 'utils/useGetList'

export function useFiltersOverviewItems(filters: FilterBean | undefined) {
  const [countryList] = useGetList(() => countryApi.listAll())

  const items = filters?.active
    ? [
        { name: 'Active', value: boolToText(filters?.active) },
        { name: 'Device list type', value: deviceListTypeToName(filters?.deviceListType) },
        {
          name: 'Device list',
          value: filters?.deviceList?.map((deviceList) => deviceListToName(deviceList)).join(', '),
        },
        { name: 'Country list type', value: countryListTypeToName(filters?.countryListType) },
        {
          name: 'Country list',
          value: !countryList.length
            ? 'Loading...'
            : filters?.countryIds?.map((id) => countryList.find((bean) => bean.id === id)?.name).join(', '),
        },
        {
          name: 'Day part',
          value: filters?.dayPart
            ?.split(';')
            .filter((day) => day)
            .map((day) => {
              const [from, to] = day.split(',').map((time) => time.split(':')[0])
              return `${from} to ${to}`
            })
            .join(', '),
        },
      ]
    : [{ name: 'Active', value: boolToText(filters?.active) }]

  return items
}
