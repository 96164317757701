import React, { useContext, useEffect } from 'react'

import { NamedBean, SiteBean } from 'api/base/api'

import { UserContext } from 'utils/userContext'

import { FormNumberInput, FormRow, FormSelect, FormSwitch, FormTextAreaInput, FormTextInput } from 'components/form'

const { RtbDetailsTypeEnum } = SiteBean

interface Props {
  formState: Partial<SiteBean>
  setFormState: (formState: Partial<SiteBean>) => void
  isCreation: boolean
  publisherList: NamedBean[]
}

export const MainTab: React.FC<Props> = ({ formState, setFormState, isCreation, publisherList }) => {
  const canReadPublishers = useContext(UserContext).canRead('publisher')

  useEffect(() => {
    if (!canReadPublishers) {
      setFormState({ publisherId: 0 })
    }
  })

  return (
    <>
      <FormRow>
        <FormSwitch
          span={8}
          label="Active"
          checked={formState.active}
          onChange={() => setFormState({ active: !formState.active })}
        />
      </FormRow>

      {canReadPublishers && (
        <FormRow>
          <FormSelect
            span={8}
            label="Publisher"
            value={formState.publisherId}
            onChange={(publisherId) => setFormState({ publisherId })}
            options={publisherList.map((publisher) => ({ value: publisher.id, label: publisher.name }))}
            disabled={!isCreation}
            required
          />
        </FormRow>
      )}

      <FormRow>
        <FormTextInput
          span={8}
          label="Name"
          value={formState.name}
          onChange={(name) => setFormState({ name })}
          required
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Domain"
          placeholder="https://domain.com"
          value={formState.domain}
          onChange={(domain) => setFormState({ domain })}
          required
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          label="Type"
          value={formState.rtbDetailsType}
          onChange={(rtbDetailsType) => setFormState({ rtbDetailsType, bundle: undefined })}
          options={[
            { value: RtbDetailsTypeEnum.SITE, label: 'Site' },
            { value: RtbDetailsTypeEnum.APP, label: 'App' },
          ]}
          required
        />
      </FormRow>

      {formState.rtbDetailsType === RtbDetailsTypeEnum.APP && (
        <FormRow>
          <FormTextInput
            span={8}
            label="Bundle"
            placeholder="org.example.bundle"
            value={formState.bundle}
            onChange={(bundle) => setFormState({ bundle })}
          />
        </FormRow>
      )}
      <FormRow>
        <FormTextAreaInput
          span={8}
          textAreaProps={{ autoSize: { minRows: 3, maxRows: 5 } }}
          label="Restricted advertiser domains (comma-separated list)"
          value={formState.blockedDomains}
          onChange={(blockedDomains) => setFormState({ ...formState, blockedDomains })}
        />
      </FormRow>
      <FormRow>
        <FormNumberInput
          span={8}
          label="Sampling rate"
          value={formState.samplingRate}
          onChange={(samplingRate) => setFormState({ samplingRate })}
          inputProps={{ min: 1, controls: true }}
        />
      </FormRow>
    </>
  )
}
