import React from 'react'
import { useHistory } from 'react-router-dom'

import { adSizeApi } from 'api/adSize'
import { AdSizeBean } from 'api/base/api'

import { routes } from 'utils/routes'
import { sortAdSize } from 'utils/sort'

import { FormActionButtons, FormContainer, FormNumberInput, FormRow, FormSelect, FormSwitch } from 'components/form'

import { useAdSizeFormData } from 'views/adSize/form/useAdSizeFormData'

export const AdSizeForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formState, formName, setFormState, adSizeList } = useAdSizeFormData()

  const redirectToList = () => {
    history.push(routes.adSize.list())
  }

  const submit = () => {
    const bean = formState as AdSizeBean

    if (isCreation) {
      adSizeApi.create(bean).then(redirectToList)
    } else {
      adSizeApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    adSizeApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer title={isCreation ? 'New ad size' : formName} isLoading={isFormLoading}>
      <FormRow>
        <FormSwitch
          span={8}
          label="Active"
          checked={formState.active}
          onChange={() => setFormState({ active: !formState.active })}
        />
      </FormRow>

      <FormRow>
        <FormNumberInput
          span={4}
          label="Width"
          value={formState.width}
          onChange={(width) => setFormState({ width })}
          required
          inputProps={{ disabled: !isCreation }}
        />

        <FormNumberInput
          span={4}
          label="Height"
          value={formState.height}
          onChange={(height) => setFormState({ height })}
          required
          inputProps={{ disabled: !isCreation }}
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={8}
          value={formState.childIds}
          onChange={(childIds) => setFormState({ childIds })}
          label="Children"
          options={adSizeList
            ?.filter(({ id }) => id !== formState.id)
            .map((adSize) => ({ value: adSize.id, label: adSize.name }))}
          selectProps={{ mode: 'multiple', filterSort: sortAdSize }}
        />
      </FormRow>
      <FormRow>
        <FormSelect
          span={8}
          value={formState.parentIds}
          onChange={(parentIds) => setFormState({ parentIds })}
          label="Parents"
          options={adSizeList
            ?.filter(({ id }) => id !== formState.id)
            .map((adSize) => ({ value: adSize.id, label: adSize.name }))}
          selectProps={{ mode: 'multiple', filterSort: sortAdSize }}
        />
      </FormRow>

      <FormActionButtons isCreation={isCreation} onBack={redirectToList} onDelete={remove} onSubmit={submit} />
    </FormContainer>
  )
}
