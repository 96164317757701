import React, { useContext } from 'react'
import { Route } from 'react-router-dom'

import { AbilityEntity } from 'utils/ability'
import { UserContext } from 'utils/userContext'

interface Props {
  path: string
  component: React.ComponentType
  entity: AbilityEntity
}

export const AbilityRoute: React.FC<Props> = ({ path, component, entity }) => {
  const { canRead } = useContext(UserContext)

  if (!canRead(entity)) {
    return null
  }

  return <Route path={path} component={component} />
}
