import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { DirectAdRoot } from 'src/views/directAd'
import { DirectCampaignRoot } from 'src/views/directCampaign'

import { routes } from 'utils/routes'
import { UserContext } from 'utils/userContext'

import { AbilityRoute, AppLayout } from 'components/application'

import { AccountRoot } from 'views/account'
import { AdSizeRoot } from 'views/adSize'
import { AdUnitRoot } from 'views/adUnit'
import { ChangePasswordRoot } from 'views/changePassword'
import { DashboardRoot } from 'views/dashboard'
import { DealRoot } from 'views/deal'
import { DspRoot } from 'views/dsp'
import { GenerateTagRoot } from 'views/generateTag'
import { LogIn } from 'views/logIn'
import { LogOut } from 'views/logOut'
import { PaymentRoot } from 'views/payment'
import { PublisherRoot } from 'views/publisher'
import { ReportRoot } from 'views/report'
import { SavedReportRoot } from 'views/savedReport'
import { SiteRoot } from 'views/site'

export const App: React.FC = () => {
  const { isAuthorized } = useContext(UserContext)

  return isAuthorized ? (
    <AppLayout>
      <Switch>
        <AbilityRoute path={routes.account.root} component={AccountRoot} entity="account" />
        <AbilityRoute path={routes.adSize.root} component={AdSizeRoot} entity="adSize" />
        <AbilityRoute path={routes.adUnit.root} component={AdUnitRoot} entity="adUnit" />
        <AbilityRoute path={routes.deal.root} component={DealRoot} entity="deal" />
        <AbilityRoute path={routes.changePassword.root} component={ChangePasswordRoot} entity="changePassword" />
        <AbilityRoute path={routes.dashboard.root} component={DashboardRoot} entity="dashboard" />
        <AbilityRoute path={routes.dsp.root} component={DspRoot} entity="dsp" />
        <AbilityRoute path={routes.directAd.root} component={DirectAdRoot} entity="directAd" />
        <AbilityRoute path={routes.directCampaign.root} component={DirectCampaignRoot} entity="directCampaign" />
        <AbilityRoute path={routes.logOut.root} component={LogOut} entity="logout" />
        <AbilityRoute path={routes.payment.root} component={PaymentRoot} entity="payment" />
        <AbilityRoute path={routes.publisher.root} component={PublisherRoot} entity="publisher" />
        <AbilityRoute path={routes.report.root()} component={ReportRoot} entity="report" />
        <AbilityRoute path={routes.savedReport.root} component={SavedReportRoot} entity="savedReport" />
        <AbilityRoute path={routes.generateTag.root} component={GenerateTagRoot} entity="generatedTag" />
        <AbilityRoute path={routes.site.root} component={SiteRoot} entity="site" />
        <Redirect from="*" to={routes.dashboard.root} />
      </Switch>
    </AppLayout>
  ) : (
    <Switch>
      <Route path={routes.logIn.root} component={LogIn} />
      <Redirect from="*" to={routes.logIn.root} />
    </Switch>
  )
}
