import { useEffect, useState } from 'react'

import { ListFilters } from 'components/list/utils/useListFilters'

type ListResponse<Entity> = { totalElements?: number; content?: Entity[] }

export function useGetList<Entity>(fetchList: () => Promise<ListResponse<Entity> | Entity[]>, filters?: ListFilters) {
  const [list, setList] = useState<Entity[]>([])
  const [totalEntities, setTotalEntities] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const refreshList = () => {
    setIsLoading(true)
    fetchList()
      .then((response) => {
        setList(
          Array.isArray((response as ListResponse<Entity>).content)
            ? (response as ListResponse<Entity>).content ?? []
            : (response as Entity[])
        )
        setTotalEntities((response as ListResponse<Entity>).totalElements ?? 0)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(refreshList, [filters])

  return [list, { refreshList, totalEntities, isLoading }] as const
}
