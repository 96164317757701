import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { adSizeApi } from 'api/adSize'
import { adUnitApi } from 'api/adUnit'
import { AdUnitBean } from 'api/base/api'
import { dealApi } from 'api/deal'
import { directCampaignApi } from 'api/directCampaign'
import { dspApi } from 'api/dsp'
import { siteApi } from 'api/site'

import { useGetList } from 'utils/useGetList'

import { getDefaultFilterBean, useFormState } from 'components/form'

export function useAdUnitFormData() {
  const { id, siteId } = useParams<{ id: string; siteId: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<AdUnitBean>()

  useEffect(() => {
    if (id) {
      adUnitApi.entity(+id).then((bean) => {
        setFormName(bean.name)
        setFormState({ ...bean, filterBean: bean.filterBean ?? getDefaultFilterBean() })
      })
    } else {
      setFormState({
        siteId: siteId ? +siteId : undefined,
        active: true,
        test: false,
        auctionType: AdUnitBean.AuctionTypeEnum.FIRSTPRICE,
        privateAuction: false,
        adUnitType: AdUnitBean.AdUnitTypeEnum.BANNER,
        filterBean: getDefaultFilterBean(),
      })
    }
  }, [])

  const [dspList] = useGetList(() => dspApi.listAll())
  const [siteList] = useGetList(() => siteApi.listAll())
  const [adSizeList] = useGetList(() => adSizeApi.listAll())
  const [dealList] = useGetList(() => dealApi.listAll())
  const [campaignList] = useGetList(() => directCampaignApi.allNamed())

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
    dspList,
    siteList,
    adSizeList,
    dealList,
    campaignList,
  }
}
