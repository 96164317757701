import { ReportDictionaryElem } from 'views/report/utils/reportTypes'

export function formatDashboardResult(value: number, format: ReportDictionaryElem['format'], currency: string) {
  switch (format) {
    case 'rate':
      return `${Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value ?? 0)}%`
    case 'money':
      return `${currency === 'USD' ? '$' : '₩'}${Intl.NumberFormat('en-US', {
        maximumFractionDigits: 4,
        notation: 'compact',
      }).format(value ?? 0)}`
    case 'number':
      return Intl.NumberFormat('en-US', { maximumFractionDigits: 0, notation: 'compact' }).format(value ?? 0)
    default:
      return value
  }
}
