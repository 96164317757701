import { Col } from 'antd'
import React, { useState } from 'react'

import { FilterEntry, PublisherBean } from 'api/base/api'
import { categoriesApi } from 'api/categories'

import { sortAdSize } from 'utils/sort'
import { useGetList } from 'utils/useGetList'

import { FormNumberInput, FormRow, FormSelect, FormSwitch, FormTextInput } from 'components/form'

import { reportKeys } from 'views/report/utils/reportKeys'
import { ReportFilterOption } from 'views/report/utils/reportTypes'

const { IabTaxonomyVersionEnum } = PublisherBean

interface Props {
  filter: FilterEntry
  setFilter: (filter: FilterEntry) => void
  filterSelectOptions: { [key: string]: ReportFilterOption[] }
}

export const ReportFilterInput: React.FC<Props> = ({ filter, setFilter, filterSelectOptions }) => {
  const [version1List] = useGetList(() => categoriesApi.list(IabTaxonomyVersionEnum._10))
  const [version2List] = useGetList(() => categoriesApi.list(IabTaxonomyVersionEnum._22))
  const [taxonomyVersion, setTaxonomyVersion] = useState(IabTaxonomyVersionEnum._10)

  const updateFilterParams = (params: (string | number | boolean)[]) => {
    const newFilter = { ...filter }
    if (newFilter.filter) {
      newFilter.filter.params = params
    }
    setFilter(newFilter)
  }

  const reportKey = reportKeys.find(({ value }) => value === filter.keys?.[0])

  switch (reportKey?.filter) {
    case 'commaSeparated':
      return (
        <FormTextInput
          span={7}
          placeholder="Input comma separated list of the values"
          value={filter.filter?.params.join(',') ?? ''}
          onChange={(value) => updateFilterParams(value.split(','))}
        />
      )

    case 'boolean':
      return (
        <FormSwitch
          span={7}
          checked={filter.filter?.params[0]}
          onChange={() => updateFilterParams([!filter.filter?.params[0]])}
        />
      )

    case 'range':
    case 'hoursRange':
      return (
        <Col span={7}>
          <FormRow>
            <FormNumberInput
              span=""
              value={filter.filter?.params[0]}
              onChange={(hour) => updateFilterParams([hour ?? 0, filter.filter?.params[1]])}
              inputProps={{
                prefix: 'min:',
                min: 0,
                max: 24,
                style: { width: undefined },
              }}
            />
            <FormNumberInput
              span=""
              value={filter.filter?.params[1]}
              onChange={(hour) => updateFilterParams([filter.filter?.params[0], hour ?? 0])}
              inputProps={{
                prefix: 'max:',
                min: 0,
                max: 24,
                style: { width: undefined },
              }}
            />
          </FormRow>
        </Col>
      )

    case 'select':
      return (
        <FormSelect
          span={7}
          placeholder="Please select"
          value={filter.filter?.params}
          onChange={(params) => updateFilterParams(params)}
          options={(filterSelectOptions[reportKey.value] ?? []).map(({ value, label }) => ({ value, label }))}
          selectProps={{
            mode: 'multiple',
            allowClear: true,
            filterSort: reportKey.value === FilterEntry.KeysEnum.AdSize ? sortAdSize : undefined,
          }}
        />
      )

    case 'taxonomy':
      return (
        <>
          <FormSelect
            span={2}
            value={taxonomyVersion}
            onChange={(version) => {
              setTaxonomyVersion(version)
              updateFilterParams([])
            }}
            options={[
              { value: IabTaxonomyVersionEnum._10, label: '1.0' },
              { value: IabTaxonomyVersionEnum._22, label: '2.2' },
            ]}
          />
          <FormSelect
            span={5}
            placeholder="Please select"
            value={filter.filter?.params}
            onChange={(params) => updateFilterParams(params)}
            options={(taxonomyVersion === IabTaxonomyVersionEnum._10 ? version1List : version2List).map(
              ({ iabId, name }) => ({ value: iabId, label: name })
            )}
            selectProps={{
              mode: 'multiple',
              allowClear: true,
            }}
          />
        </>
      )

    default:
      return <Col span={7} />
  }
}
