import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { PaymentForm } from 'views/payment/form/PaymentForm'
import { PaymentList } from 'views/payment/list/PaymentList'

export const PaymentRoot: React.FC = () => (
  <Switch>
    <Route path={routes.payment.list()} component={PaymentList} />
    <Route path={routes.payment.create} component={PaymentForm} />
    <Route path={routes.payment.edit()} component={PaymentForm} />
    <Redirect from="*" to={routes.payment.list()} />
  </Switch>
)
