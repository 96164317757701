import { message } from 'antd'
import { NoticeType } from 'antd/es/message/interface'

export function createMessage(type: NoticeType, content: string) {
  message.open({ type, content, duration: 10 })
}

export function createMessageWithDuration(type: NoticeType, content: string, duration: number) {
  message.open({ type, content, duration })
}

export function createCloseableMessageWithDuration(type: NoticeType, content: string, duration: number) {
  message.open({
    type,
    content,
    duration,
    onClick: () => message.destroy(),
  })
}
