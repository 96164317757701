import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { DspBean } from 'api/base/api'
import { dspApi } from 'api/dsp'

import { getDefaultFilterBean, useFormState } from 'components/form'

const { BidRequestAdapterTypeEnum, CompressionTypeEnum, IabTaxonomyVersionEnum } = DspBean

export function useDspFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<DspBean>()

  useEffect(() => {
    if (id) {
      dspApi.entity(+id).then((bean) => {
        setFormName(bean.name)
        setFormState({ ...bean, filterBean: bean.filterBean ?? getDefaultFilterBean() })
      })
    } else {
      setFormState({
        active: true,
        unwrapVast: false,
        compressionType: CompressionTypeEnum.NONE,
        filterBean: getDefaultFilterBean(),
        bidRequestAdapterType: BidRequestAdapterTypeEnum.Default,
        iabTaxonomyVersion: IabTaxonomyVersionEnum._10,
        acceptKnownBuyerOnly: false,
        multiImp: false,
      })
    }
  }, [])

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
  }
}
