import { Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { DashboardStatBean, DashboardStatRequestBean } from 'api/base/api'
import { dashboardApi } from 'api/dashboard'

import { formatDashboardResult } from 'views/dashboard/utils/formatDashboardResult'
import { ReportDictionaryElem } from 'views/report/utils/reportTypes'
import { reportValues } from 'views/report/utils/reportValues'

const { ValuesEnum } = DashboardStatRequestBean

const statisticsValues = [
  ValuesEnum.NetImprPrice,
  ValuesEnum.PubReq,
  ValuesEnum.Impr,
  ValuesEnum.NetImprPriceAvg,
  ValuesEnum.PubBidRate,
  ValuesEnum.PubWinRate,
  ValuesEnum.FillRate,
]

interface Props {
  formState: DashboardStatRequestBean
}

export const DashboardStatistics: React.FC<Props> = ({ formState }) => {
  const [response, setResponse] = useState<DashboardStatBean>()

  useEffect(() => {
    dashboardApi
      .statistics({
        periodType: formState.periodType,
        startDate: formState.startDate,
        endDate: formState.endDate,
        currency: formState.currency,
        values: statisticsValues,
        filters: formState.filters,
        audit: formState.audit,
      })
      .then(setResponse)
  }, [formState])

  return (
    <Row gutter={10}>
      {statisticsValues
        .map((value) => reportValues.find((el) => el.value === value) as ReportDictionaryElem)
        .map((value) => (
          <Col style={{ flexBasis: 1, flexGrow: 1 }}>
            <Card title={value.label} size="small">
              {formatDashboardResult(response?.stats?.[value.value] ?? 0, value.format, formState.currency)}
            </Card>
          </Col>
        ))}
    </Row>
  )
}
