import { PublisherBean, PublisherControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class PublisherApi {
  api = new PublisherControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ page, id, name }: ListFilters) =>
    this.api.publisherFilteredList(id, name, { query: { size: LIST_PAGE_SIZE, page } })

  listAll = () => this.api.publisherListAll()

  entityForList = (id: number) => this.api.publisherForList(id)

  entity = (id: number) => this.api.publisher(id)

  create = (bean: PublisherBean) => this.api.createPublisher(bean)

  edit = (bean: PublisherBean) => this.api.updatePublisher(bean, bean.id as number)

  delete = (id: number) => this.api.deletePublisher(id)
}

export const publisherApi = new PublisherApi()
