import { AppstoreOutlined, FileOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import React from 'react'

import { AbilityEntity } from 'utils/ability'
import { routes } from 'utils/routes'

interface Item {
  key: string
  label: string
  icon?: React.ReactNode
  entity: AbilityEntity | undefined
  group?: true
}

export const menuItems: Item[] = [
  { key: 'Dashboard', label: 'Dashboard', entity: undefined, group: true },
  { key: routes.dashboard.root, label: 'Dashboard', icon: <AppstoreOutlined />, entity: 'dashboard' },
  { key: routes.payment.root, label: 'Payment', icon: <AppstoreOutlined />, entity: 'payment' },
  { key: 'Inventory', label: 'Inventory', entity: undefined, group: true },
  { key: routes.publisher.root, label: 'Publisher', icon: <AppstoreOutlined />, entity: 'publisher' },
  { key: routes.site.root, label: 'Site/App', icon: <AppstoreOutlined />, entity: 'site' },
  { key: routes.adUnit.root, label: 'Ad Unit', icon: <AppstoreOutlined />, entity: 'adUnit' },
  { key: routes.deal.root, label: 'Deal', icon: <AppstoreOutlined />, entity: 'deal' },

  { key: 'Reports', label: 'Reports', entity: undefined, group: true },
  { key: routes.report.root(), label: 'Report', icon: <FileOutlined />, entity: 'report' },
  { key: routes.savedReport.root, label: 'Saved reports', icon: <FileOutlined />, entity: 'savedReport' },

  { key: 'Setup', label: 'Setup', entity: undefined, group: true },
  { key: routes.generateTag.root, label: 'Generate Tag', icon: <SettingOutlined />, entity: 'generatedTag' },
  { key: routes.directAd.root, label: 'Direct Ad', icon: <SettingOutlined />, entity: 'directAd' },
  { key: routes.directCampaign.root, label: 'Direct Campaign', icon: <SettingOutlined />, entity: 'directCampaign' },
  { key: routes.adSize.root, label: 'Ad Size', icon: <SettingOutlined />, entity: 'adSize' },
  { key: routes.dsp.root, label: 'DSP', icon: <SettingOutlined />, entity: 'dsp' },
  { key: routes.account.root, label: 'Account', icon: <UserOutlined />, entity: 'account' },
  { key: routes.changePassword.root, label: 'Change Password', icon: <UserOutlined />, entity: 'changePassword' },
]
