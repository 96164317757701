import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { accountApi } from 'api/account'
import { AccountBean } from 'api/base/api'
import { publisherApi } from 'api/publisher'

import { useGetList } from 'utils/useGetList'

import { useFormState } from 'components/form'

export function useAccountFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<AccountBean>()

  useEffect(() => {
    if (id) {
      accountApi.entity(+id).then((bean) => {
        setFormName(bean.name)
        setFormState(bean)
      })
    } else {
      setFormState({
        role: AccountBean.RoleEnum.Publisher,
      })
    }
  }, [])

  const [publisherList] = useGetList(() => publisherApi.listAll())

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
    publisherList,
  }
}
