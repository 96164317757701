import { ImageControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class ImageApi {
  api = new ImageControllerApi(configuration, configuration.basePath, customFetch)

  upload = (image: Blob) => {
    const formData = new FormData()
    formData.append('image', image)

    return fetch(`${configuration.basePath}/api/image`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'secure-cookie': '*' },
      body: formData,
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      }
      throw response
    })
  }

  delete = (id: number) => this.api.deleteImage(id)
}

export const imageApi = new ImageApi()
