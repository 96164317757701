import { DealBean, DealControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class DealApi {
  api = new DealControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ id, page, publicId, name, publisherId, bidFloor }: ListFilters) =>
    this.api.filteredDealList(id, publicId, name, publisherId, bidFloor, { query: { size: LIST_PAGE_SIZE, page } })

  listAll = () => this.api.dealListAll()

  entityForList = (id: number) => this.api.retrieveDealForList(id)

  entity = (id: number) => this.api.retrieveDeal(id)

  create = (bean: DealBean) => this.api.createDeal(bean)

  edit = (bean: DealBean) => this.api.updateDeal(bean, bean.id as number)

  delete = (id: number) => this.api.deleteDeal(id)
}

export const dealApi = new DealApi()
