import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { AdUnitList } from 'views/adUnit/AdUnitList'
import { AdUnitForm } from 'views/adUnit/form/AdUnitForm'

export const AdUnitRoot: React.FC = () => (
  <Switch>
    <Route path={routes.adUnit.list('forRoute')} component={AdUnitList} />
    <Route path={routes.adUnit.create} component={AdUnitForm} />
    <Route path={routes.adUnit.createWithSite()} component={AdUnitForm} />
    <Route path={routes.adUnit.edit()} component={AdUnitForm} />
    <Redirect from="*" to={routes.adUnit.list()} />
  </Switch>
)
