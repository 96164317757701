import { Button, Col } from 'antd'
import React from 'react'

import { FilterEntry, PublisherBean, SavedReportBean, StatRequestBean } from 'api/base/api'

import { FormPeriodPicker, FormRow, FormSelect, FormSortableSelect, FormSwitch } from 'components/form'

import { ReportFilters } from 'views/report/form/ReportFilters'
import { SavedReportSelect } from 'views/report/form/SavedReportSelect'
import { reportKeys } from 'views/report/utils/reportKeys'
import { reportValues } from 'views/report/utils/reportValues'

const { UserCurrencyEnum } = PublisherBean

interface Props {
  generate: (formData: StatRequestBean) => void
  isGenerating: boolean
  canReadGross: boolean
  formState: Partial<StatRequestBean>
  setFormState: (formState: Partial<StatRequestBean>) => void
  savedReport: Partial<SavedReportBean>
  setSavedReport: (formState: Partial<SavedReportBean>) => void
  timeZones: string[]
}

export const ReportFormMainTab: React.FC<Props> = ({
  generate,
  isGenerating,
  canReadGross,
  formState,
  setFormState,
  savedReport,
  setSavedReport,
  timeZones,
}) => (
  <>
    <SavedReportSelect savedReport={savedReport} setSavedReport={setSavedReport} formState={formState} />

    <FormRow>
      <FormPeriodPicker
        periodType={formState.periodType}
        setPeriodType={(periodType) => setFormState({ periodType })}
        startDate={formState.startDate}
        setStartDate={(startDate) => setFormState({ startDate })}
        endDate={formState.endDate}
        setEndDate={(endDate) => setFormState({ endDate })}
      />
    </FormRow>

    <FormRow>
      <FormSortableSelect
        span={12}
        label="Group By"
        value={formState.keys ?? []}
        onChange={(keys) => setFormState({ keys })}
        options={reportKeys.map((reportKey) => ({
          label: reportKey.label,
          value: reportKey.value as FilterEntry.KeysEnum,
        }))}
      />
    </FormRow>

    <FormRow>
      <FormSortableSelect
        span={12}
        label="Values"
        value={formState.values ?? []}
        onChange={(values) => setFormState({ values })}
        options={reportValues
          .filter((reportValue) => canReadGross || reportValue.revenueType !== 'gross')
          .map((reportValue) => ({
            label: reportValue.label,
            value: reportValue.value as StatRequestBean.ValuesEnum,
          }))}
      />
    </FormRow>

    <FormRow>
      <FormSelect
        span={6}
        label="Currency"
        value={formState.currency}
        onChange={(currency) => setFormState({ currency })}
        options={[
          { value: UserCurrencyEnum.USD, label: 'USD' },
          { value: UserCurrencyEnum.KRW, label: 'KRW' },
        ]}
      />
      <FormSelect
        span={4}
        label="Time Zone"
        value={formState.timeZone}
        onChange={(timeZone) => setFormState({ timeZone })}
        options={timeZones.map((zone) => ({ value: zone, label: zone }))}
        selectProps={{ filterSort: undefined, showSearch: true }}
      />

      <FormSwitch
        span={6}
        label="Audit"
        checked={formState.audit}
        onChange={() => setFormState({ audit: !formState.audit })}
        colProps={{ style: { paddingLeft: 30 } }}
      />
    </FormRow>

    <ReportFilters filters={formState.filters ?? []} setFilters={(filters) => setFormState({ filters })} />

    <FormRow>
      <Col>
        <Button type="primary" onClick={() => generate(formState as Required<StatRequestBean>)} disabled={isGenerating}>
          Generate
        </Button>
      </Col>
    </FormRow>
  </>
)
