import {
  AccountBean,
  AdUnitBean,
  DealBean,
  DealBeanInList,
  DirectAdBean,
  DirectAdBeanInList,
  DirectCampaignBeanInList,
  FilterBean,
  PaymentAdjustmentBean,
  PaymentBeanInList,
  PublisherBean,
  SiteBean,
  TypedDspIdMappingBean,
} from 'api/base/api'

import CurrencyTypeEnum = DealBeanInList.CurrencyTypeEnum

import DealTypeEnum = DealBean.DealTypeEnum
import DspIdMappingCreativeTypeEnum = TypedDspIdMappingBean.DspIdMappingCreativeTypeEnum
import CappingTypeEnum = DirectCampaignBeanInList.CappingTypeEnum
import CappingPassingTypeEnum = DirectCampaignBeanInList.CappingPassingTypeEnum

const { RoleEnum } = AccountBean
const { AuctionTypeEnum, AdUnitTypeEnum } = AdUnitBean
const { ContentTypeEnum, DirectAdCreativeTypeEnum } = DirectAdBean
const { BiddingCurrencyEnum, RtbDetailsTypeEnum } = SiteBean
const { CountryListTypeEnum, DeviceListEnum, DeviceListTypeEnum } = FilterBean
const { IabTaxonomyVersionEnum } = PublisherBean
const { StatusEnum: PaymentStatusEnum } = PaymentBeanInList
const { ReasonEnum: AdjustmentReasonEnum } = PaymentAdjustmentBean

export function dealTypeToName(value?: DealBean.DealTypeEnum) {
  switch (value) {
    case DealTypeEnum.PREFERREDDEAL:
      return 'Preferred Deal'
    case DealTypeEnum.PRIVATEAUCTION:
      return 'Private Auction'
    default:
      return ''
  }
}

export function auctionTypeToName(value?: AdUnitBean.AuctionTypeEnum) {
  switch (value) {
    case AuctionTypeEnum.FIRSTPRICE:
      return 'First price'
    case AuctionTypeEnum.SECONDPRICE:
      return 'Second price'
    default:
      return ''
  }
}

export function adUnitTypeToName(value?: AdUnitBean.AdUnitTypeEnum) {
  switch (value) {
    case AdUnitTypeEnum.BANNER:
      return 'Banner'
    case AdUnitTypeEnum.VIDEO:
      return 'Video'
    case AdUnitTypeEnum.MULTI:
      return 'Multi'
    case AdUnitTypeEnum.NATIVE:
      return 'Native'
    default:
      return ''
  }
}

export function creativeTypeToName(value?: DirectAdBean.DirectAdCreativeTypeEnum) {
  switch (value) {
    case DirectAdCreativeTypeEnum.BANNER:
      return 'Banner'
    case DirectAdCreativeTypeEnum.VIDEO:
      return 'Video'
    default:
      return ''
  }
}

export function contentTypeToName(value?: DirectAdBean.ContentTypeEnum) {
  switch (value) {
    case ContentTypeEnum.IMAGE:
      return 'Image'
    case ContentTypeEnum.VIDEOFILE:
      return 'Video File'
    case ContentTypeEnum.VASTURL:
      return 'VAST URL'
    case ContentTypeEnum.ADM:
      return 'Ad markup'
    default:
      return ''
  }
}

export function roleToName(value?: AccountBean.RoleEnum) {
  switch (value) {
    case RoleEnum.Admin:
      return 'Admin'
    case RoleEnum.Publisher:
      return 'Publisher'
    default:
      return ''
  }
}

export function deviceListTypeToName(value?: FilterBean.DeviceListTypeEnum) {
  switch (value) {
    case DeviceListTypeEnum.INCLUDEALL:
      return 'Include all'
    case DeviceListTypeEnum.INCLUDESELECTED:
      return 'Include selected'
    case DeviceListTypeEnum.EXCLUDESELECTED:
      return 'Exclude selected'
    default:
      return ''
  }
}

export function deviceListToName(value?: FilterBean.DeviceListEnum) {
  switch (value) {
    case DeviceListEnum.CONNECTEDDEVICE:
      return 'Connected device'
    case DeviceListEnum.PC:
      return 'PC'
    case DeviceListEnum.CONNECTEDTV:
      return 'Connected TV'
    case DeviceListEnum.PHONE:
      return 'Phone'
    case DeviceListEnum.MOBILETABLET:
      return 'Mobile tablet'
    case DeviceListEnum.SETTOPBOX:
      return 'Set-top box'
    case DeviceListEnum.TABLET:
      return 'Tablet'
    default:
      return ''
  }
}

export function countryListTypeToName(value?: FilterBean.CountryListTypeEnum) {
  switch (value) {
    case CountryListTypeEnum.INCLUDEALL:
      return 'Include all'
    case CountryListTypeEnum.INCLUDESELECTED:
      return 'Include selected'
    case CountryListTypeEnum.EXCLUDESELECTED:
      return 'Exclude selected'
    default:
      return ''
  }
}

export function biddingCurrencyToName(value?: SiteBean.BiddingCurrencyEnum) {
  switch (value) {
    case BiddingCurrencyEnum.USD:
      return '$'
    case BiddingCurrencyEnum.KRW:
      return '₩'
    default:
      return '$'
  }
}

export function currencyToName(value?: CurrencyTypeEnum) {
  switch (value) {
    case CurrencyTypeEnum.USD:
      return '$'
    case CurrencyTypeEnum.KRW:
      return '₩'
    default:
      return '$'
  }
}

export function rtbDetailsTypeToName(value?: SiteBean.RtbDetailsTypeEnum) {
  switch (value) {
    case RtbDetailsTypeEnum.SITE:
      return 'Site'
    case RtbDetailsTypeEnum.APP:
      return 'App'
    default:
      return ''
  }
}

export function directAdCreativeTypeToName(value?: DirectAdBeanInList.DirectAdCreativeTypeEnum) {
  switch (value) {
    case DirectAdCreativeTypeEnum.BANNER:
      return 'Banner'
    case DirectAdCreativeTypeEnum.VIDEO:
      return 'Video'
    default:
      return ''
  }
}

export function taxonomyVersionToName(value?: PublisherBean.IabTaxonomyVersionEnum) {
  switch (value) {
    case IabTaxonomyVersionEnum._10:
      return '1.0'
    case IabTaxonomyVersionEnum._22:
      return '2.2'
    default:
      return ''
  }
}

export function paymentStatusToName(value?: PaymentBeanInList.StatusEnum) {
  switch (value) {
    case PaymentStatusEnum.DRAFT:
      return 'Draft'
    case PaymentStatusEnum.PAID:
      return 'Paid'
    case PaymentStatusEnum.PENDING:
      return 'Pending'
    default:
      return ''
  }
}

export function paymentAdjustmentReasonToName(value?: PaymentAdjustmentBean.ReasonEnum) {
  switch (value) {
    case AdjustmentReasonEnum.DISCREPANCY:
      return 'Discrepancy'
    case AdjustmentReasonEnum.INVALIDTRAFFIC:
      return 'Invalid traffic'
    case AdjustmentReasonEnum.OTHER:
      return 'Other'
    default:
      return ''
  }
}

export function mappingTypeToName(value?: TypedDspIdMappingBean.DspIdMappingCreativeTypeEnum) {
  switch (value) {
    case DspIdMappingCreativeTypeEnum.ALL:
      return 'All'
    case DspIdMappingCreativeTypeEnum.BANNER:
      return 'Banner'
    case DspIdMappingCreativeTypeEnum.VIDEO:
      return 'Video'
    case DspIdMappingCreativeTypeEnum.NATIVE:
      return 'Native'
    default:
      return ''
  }
}

export function cappingTypeToName(value?: DirectCampaignBeanInList.CappingTypeEnum) {
  switch (value) {
    case CappingTypeEnum.DAY:
      return 'Day'
    case CappingTypeEnum.MONTH:
      return 'Month'
    case CappingTypeEnum.TOTAL:
      return 'Total'
    default:
      return ''
  }
}

export function passingTypeToName(value?: DirectCampaignBeanInList.CappingPassingTypeEnum) {
  switch (value) {
    case CappingPassingTypeEnum.EVEN:
      return 'Even'
    case CappingPassingTypeEnum.ASAP:
      return 'ASAP'
    default:
      return ''
  }
}
