import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

import { adSizeApi } from 'api/adSize'
import { AdSizeBean } from 'api/base/api'

import { boolToText } from 'utils/boolToText'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  NumberFilter,
  SelectFilter,
  TextFilter,
  useListFilters,
  useSelectListEntity,
} from 'components/list'

export const AdSizeList: React.FC = () => {
  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => adSizeApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity((id) => adSizeApi.entity(id), routes.adSize.list, list)

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(3)

  const columns: ColumnsType<AdSizeBean> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
      filterIcon: <FilterIcon active={!!filters.id} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.id} onChange={(value) => setFilter('id', value)} filterProps={props} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.adSize.edit(id)}>{name}</ListLink>,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Width',
      dataIndex: 'width',
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: 'Height',
      dataIndex: 'height',
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (_, { active }) => boolToText(active),
      width: columnWidth,
      ellipsis: true,
      filterIcon: <FilterIcon active={filters.active !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.active}
          onChange={(value) => setFilter('active', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Active', value: true },
              { text: 'Inactive', value: false },
            ])
          }
          filterProps={props}
        />
      ),
    },
  ]

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="ad size"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.adSize.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.adSize.edit(selected.id)}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Active', value: boolToText(selected.active) },
                  { name: 'Width', value: selected.width },
                  { name: 'Height', value: selected.height },
                  { name: 'Parents', value: selected.parentNames?.join(' ') },
                  { name: 'Children', value: selected.childNames?.join(' ') },
                ],
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
