import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { Report } from 'views/report/Report'

export const ReportRoot: React.FC = () => (
  <Switch>
    <Route path={routes.report.root('forRoute')} component={Report} />
    <Redirect from="*" to={routes.report.root()} />
  </Switch>
)
