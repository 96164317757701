import { Button, Col, Popconfirm, Row, Space } from 'antd'
import React from 'react'

interface Props {
  isCreation: boolean
  isLoading?: boolean
  onBack: () => void
  onDelete?: () => void
  onSubmit?: () => void
}

export const FormActionButtons: React.FC<Props> = ({ isCreation, isLoading, onBack, onDelete, onSubmit }) => (
  <Row justify="space-between">
    <Col>
      <Space>
        <Button type="default" onClick={onBack}>
          Cancel
        </Button>
        {onSubmit && (
          <Button type="primary" onClick={onSubmit} disabled={isLoading}>
            {isCreation ? 'Create' : 'Save'}
          </Button>
        )}
      </Space>
    </Col>

    {!isCreation && onDelete && (
      <Col>
        <Popconfirm
          title="Deletion"
          description="Are you sure?"
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
          placement="leftBottom"
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      </Col>
    )}
  </Row>
)
