import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { adUnitApi, AdUnitFilters } from 'api/adUnit'
import { AdUnitBean, DirectCampaignBean } from 'api/base/api'
import { directAdApi } from 'api/directAd'
import { directCampaignApi } from 'api/directCampaign'
import { publisherApi } from 'api/publisher'
import { siteApi } from 'api/site'

import { useGetList } from 'utils/useGetList'

import { getDefaultFilterBean, useFormState } from 'components/form'

import CappingTypeEnum = DirectCampaignBean.CappingTypeEnum
import CappingPassingTypeEnum = DirectCampaignBean.CappingPassingTypeEnum
import AuctionTypeEnum = AdUnitBean.AuctionTypeEnum

export function useDirectCampaignFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<DirectCampaignBean>()

  useEffect(() => {
    if (id) {
      directCampaignApi.entity(+id).then((bean) => {
        setFormName(bean.name ?? '')
        setFormState(bean)
      })
    } else {
      setFormState({
        active: true,
        startedAt: dayjs().toDate(),
        endedAt: dayjs().toDate(),
        cappingType: CappingTypeEnum.MONTH,
        cappingPassingType: CappingPassingTypeEnum.EVEN,
        targeting: getDefaultFilterBean(),
      })
    }
  }, [])

  const [publisherList, { isLoading: publishersIsLoading }] = useGetList(() => publisherApi.listAll())
  const [siteList, { isLoading: sitesIsLoading }] = useGetList(() => siteApi.listAll())
  const [adUnitList, { isLoading: adUnitsIsLoading }] = useGetList(() =>
    adUnitApi.filteredList({ auctionType: AuctionTypeEnum.FIRSTPRICE } as AdUnitFilters)
  )
  const [directAdList, { isLoading: directAdsIsLoading }] = useGetList(() => directAdApi.listAll())

  return {
    isCreation: !id,
    isFormLoading: !formState || publishersIsLoading || sitesIsLoading || adUnitsIsLoading || directAdsIsLoading,
    formName,
    formState: formState ?? {},
    setFormState,
    publisherList,
    siteList,
    adUnitList,
    directAdList,
  }
}
