import { Divider } from 'antd'
import React from 'react'

interface Props {
  data: React.ReactNode[]
}

export const ListOverviewArray: React.FC<Props> = ({ data }) =>
  data.length ? (
    <div>
      {data.map((item, index) => (
        <React.Fragment key={item?.toString()}>
          {item}
          {index !== data.length - 1 && <Divider style={{ margin: 0 }} />}
        </React.Fragment>
      ))}
    </div>
  ) : (
    <>Empty</>
  )
