import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Layout, theme } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { routes } from 'utils/routes'

import { SidebarMenu } from 'components/application/appLayout/SidebarMenu'

import styles from 'components/application/appLayout/appLayout.module.scss'

interface Props {
  children: React.ReactNode
}

export const AppLayout: React.FC<Props> = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <Layout className={styles.container}>
      <Layout.Sider
        className={styles.sidebar}
        collapsible
        collapsed={isCollapsed}
        trigger={null}
        breakpoint="lg"
        onBreakpoint={(broken) => setIsCollapsed(broken)}
        collapsedWidth="0"
      >
        <SidebarMenu />
      </Layout.Sider>

      <Layout className={styles.layout}>
        <Layout.Header className={styles.header} style={{ background: colorBgContainer }}>
          {isCollapsed ? (
            <MenuUnfoldOutlined className={styles.sidebarToggle} onClick={() => setIsCollapsed(!isCollapsed)} />
          ) : (
            <MenuFoldOutlined className={styles.sidebarToggle} onClick={() => setIsCollapsed(!isCollapsed)} />
          )}

          <h1 className={styles.title}>Admaru admin panel</h1>

          <div className={styles.logOutContainer}>
            <Link to={routes.logOut.root} className={styles.logOut}>
              Log out
            </Link>
          </div>
        </Layout.Header>

        <Layout.Content className={styles.content}>{children}</Layout.Content>

        <Layout.Footer />
      </Layout>
    </Layout>
  )
}
