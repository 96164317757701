import { InfoControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class GenerateTagApi {
  api = new InfoControllerApi(configuration, configuration.basePath, customFetch)

  getSspUrl = () => this.api.getSspUrl() as unknown as Promise<{ sspUrl: string }>
}

export const generateTagApi = new GenerateTagApi()
