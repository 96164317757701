import { Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { DashboardStatRequestBean, DashboardTopBean, DashboardTopRequestBean } from 'api/base/api'
import { dashboardApi } from 'api/dashboard'

import { DashboardTopChart } from 'views/dashboard/top/DashboardTopChart'

const { KeysEnum, ValuesEnum } = DashboardTopRequestBean

export const topKeys = [
  KeysEnum.PublisherName,
  KeysEnum.SiteName,
  KeysEnum.AdUnitName,
  KeysEnum.DspName,
  KeysEnum.AdSize,
  KeysEnum.Country,
]

export const topValues = [ValuesEnum.NetImprPrice]

interface Props {
  formState: DashboardStatRequestBean
}

export const DashboardTop: React.FC<Props> = ({ formState }) => {
  const [response, setResponse] = useState<DashboardTopBean>()

  useEffect(() => {
    dashboardApi
      .top({
        periodType: formState.periodType,
        startDate: formState.startDate,
        endDate: formState.endDate,
        currency: formState.currency,
        audit: formState.audit,
        filters: formState.filters,
        keys: topKeys,
        limit: 5,
        values: topValues,
      })
      .then(setResponse)
  }, [formState])

  return (
    <Row gutter={10}>
      {topKeys.map((topKey) => (
        <DashboardTopChart
          key={topKey}
          topKey={topKey}
          topValues={topValues}
          response={response}
          currency={formState.currency}
        />
      ))}
    </Row>
  )
}
