import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PaymentBean } from 'api/base/api'
import { dspApi } from 'api/dsp'
import { paymentApi } from 'api/payment'
import { publisherApi } from 'api/publisher'

import { useGetList } from 'utils/useGetList'

import { useFormState } from 'components/form'

export function usePaymentFormData() {
  const { id } = useParams<{ id: string }>()

  const [formState, setFormState] = useFormState<PaymentBean>()

  useEffect(() => {
    if (id) {
      paymentApi.entity(+id).then((bean) => setFormState(bean))
    } else {
      setFormState({ period: dayjs().subtract(1, 'month').format('MMM YYYY') })
    }
  }, [])

  const [dspList] = useGetList(() => dspApi.listAll())
  const [publisherList] = useGetList(() => publisherApi.listAll())

  const [KRWRate, setKRWRate] = useState<number | null>(null)

  useEffect(() => {
    if (!formState?.period) {
      setKRWRate(null)
      return
    }

    paymentApi.getKRWRate(formState.period).then((bean) => setKRWRate(bean.rateKRW ?? null))
  }, [formState?.period])

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formState: formState ?? {},
    setFormState,
    dspList,
    publisherList,
    KRWRate,
  }
}
