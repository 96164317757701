import { Tag } from 'antd'
import React, { useState } from 'react'

import styles from 'components/form/inputs/formSortableSelect/formSortableSelect.module.scss'

interface Props {
  label: React.ReactNode
  closable: boolean
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onDragEnd: () => void
}

export const FormSortableSelectTag: React.FC<Props> = ({ label, closable, onClose, onDragEnd }) => {
  const [isDragging, setIsDragging] = useState(false)

  // using real class names for styles
  // and using their copy without "+" sign for querySelect
  const className = `${styles.draggable} ${isDragging ? styles.dragging : ''} ${`${styles.draggable} ${
    isDragging ? styles.dragging : ''
  }`.replaceAll('+', '')}`

  return (
    <Tag
      draggable
      onDragStart={(e) => {
        e.dataTransfer.effectAllowed = 'move'
        setIsDragging(true)
      }}
      onDragEnd={() => {
        setIsDragging(false)
        onDragEnd()
      }}
      onMouseDown={(e) => e.stopPropagation()}
      closable={closable}
      onClose={onClose}
      className={className}
    >
      {label}
    </Tag>
  )
}
