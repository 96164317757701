/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import { Alert } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { UserContextProvider } from 'utils/userContext'

import './index.scss'

import { App } from './App'

const { ErrorBoundary } = Alert

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <UserContextProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </UserContextProvider>
  </BrowserRouter>
)
