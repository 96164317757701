import {
  EmailControllerApi,
  ReportControllerApi,
  SavedReportBean,
  ScheduledReportBean,
  StatRequestBean,
} from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class ReportApi {
  api = new ReportControllerApi(configuration, configuration.basePath, customFetch)

  scheduleApi = new EmailControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ id, name, favorite, shared, page }: ListFilters) =>
    this.api.filteredReportList(id, name, favorite, shared, { query: { size: LIST_PAGE_SIZE, page } })

  listAll = () => this.api.getAllReports()

  entity = (id: number) => this.api.getReportById(id)

  saveReport = (bean: SavedReportBean) => this.api.saveReport(bean)

  delete = (id: number) => this.api.deleteReport(id)

  statistics = (bean: StatRequestBean) => this.api.statisticsRequests(bean)

  saveSchedule = (body: ScheduledReportBean) => this.scheduleApi.sendMailWithReport(body)

  deleteSchedule = (id: number) => this.scheduleApi.deleteScheduledReport(id)

  getReportSchedule = (id: number) => this.scheduleApi.getScheduledReportQuery(id)
}

export const reportApi = new ReportApi()
