import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { DirectCampaignBean, FilterBean } from 'api/base/api'
import { directCampaignApi } from 'api/directCampaign'

import { routes } from 'utils/routes'

import {
  FormActionButtons,
  FormContainer,
  FormDatePicker,
  FormFiltersTab,
  FormNumberInput,
  FormRow,
  FormSelect,
  FormSwitch,
  FormTextInput,
} from 'components/form'

import { DirectCampaignAdUnitTab } from 'views/directCampaign/form/tabs/DirectCampaignAdUnitTab'
import { useDirectCampaignFormData } from 'views/directCampaign/form/useDirectCampaignFormData'

export const DirectCampaignForm: React.FC = () => {
  const history = useHistory()
  const {
    isCreation,
    isFormLoading,
    formName,
    formState,
    setFormState,
    publisherList,
    siteList,
    adUnitList,
    directAdList,
  } = useDirectCampaignFormData()

  const redirectToList = () => {
    history.push(routes.directCampaign.list())
  }

  const submit = () => {
    const bean = formState as DirectCampaignBean
    if (isCreation) {
      directCampaignApi.create(bean).then(redirectToList)
    } else {
      directCampaignApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    directCampaignApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer
      title={isCreation ? 'New Direct Campaign' : formName}
      isLoading={isFormLoading}
      tabs={[
        {
          name: 'Main',
          tab: (
            <>
              <FormRow>
                <FormSwitch
                  span={8}
                  label="Active"
                  checked={formState.active}
                  onChange={() => setFormState({ active: !formState.active })}
                />
              </FormRow>

              <FormRow>
                <FormTextInput
                  span={8}
                  label="Name"
                  value={formState.name}
                  onChange={(name) => setFormState({ name })}
                  required
                />
              </FormRow>

              <FormRow>
                <FormDatePicker
                  label="Campaign Start"
                  value={dayjs(formState.startedAt)}
                  onChange={(value) => setFormState({ startedAt: dayjs(value).toDate() })}
                  datePickerProps={{
                    disabledDate: (date) => +date > +dayjs(formState.endedAt),
                    format: 'DD MMM YYYY HH:mm',
                    showTime: true,
                    allowClear: false,
                  }}
                  span={4}
                />
                <FormDatePicker
                  span={4}
                  label="Campaign End"
                  disabled={!formState.endedAt}
                  value={formState.endedAt ? dayjs(formState.endedAt) : undefined}
                  onChange={(value) => setFormState({ endedAt: value ? dayjs(value).toDate() : undefined })}
                  datePickerProps={{
                    disabledDate: (date) => +date < +dayjs(formState.startedAt),
                    format: 'DD MMM YYYY HH:mm',
                    placeholder: 'Unlimited',
                    showTime: true,
                    allowClear: false,
                  }}
                />
                <FormSwitch
                  span={4}
                  label="Unlimited interval"
                  checked={!formState.endedAt}
                  onChange={() => setFormState({ endedAt: formState.endedAt ? undefined : dayjs().toDate() })}
                />
              </FormRow>

              <FormRow>
                <FormSelect
                  span={4}
                  label="Capping type"
                  value={formState.cappingType}
                  onChange={(cappingType) => setFormState({ cappingType })}
                  options={[
                    { value: DirectCampaignBean.CappingTypeEnum.DAY, label: 'Day' },
                    { value: DirectCampaignBean.CappingTypeEnum.MONTH, label: 'Month' },
                    { value: DirectCampaignBean.CappingTypeEnum.TOTAL, label: 'Total' },
                  ]}
                  required
                />

                <FormSelect
                  span={4}
                  label="Passing type"
                  value={formState.cappingPassingType}
                  onChange={(cappingPassingType) => setFormState({ cappingPassingType })}
                  options={[
                    { value: DirectCampaignBean.CappingPassingTypeEnum.EVEN, label: 'Even' },
                    { value: DirectCampaignBean.CappingPassingTypeEnum.ASAP, label: 'ASAP' },
                  ]}
                  required
                />
              </FormRow>

              <FormRow>
                <FormNumberInput
                  span={4}
                  label="Impression Limit"
                  value={formState.impressionLimit}
                  onChange={(impressionLimit) => setFormState({ impressionLimit })}
                  required
                  inputProps={{
                    min: 0,
                  }}
                />
                <FormNumberInput
                  span={4}
                  label="CPM"
                  value={formState.usdCpm}
                  onChange={(usdCpm) => setFormState({ usdCpm })}
                  required
                  inputProps={{
                    prefix: '$',
                    min: 0,
                  }}
                />
              </FormRow>

              <FormRow>
                <FormSelect
                  span={8}
                  label="Direct Ads"
                  value={formState.linkedAds || []}
                  onChange={(linkedAds) => setFormState({ linkedAds })}
                  options={directAdList.map((directAd) => ({ value: directAd.id, label: directAd.name }))}
                  selectProps={{ showSearch: true, mode: 'multiple', allowClear: true }}
                />
              </FormRow>
            </>
          ),
        },
        {
          name: 'Ad Units',
          tab: (
            <DirectCampaignAdUnitTab
              formState={formState}
              setFormState={setFormState}
              publisherList={publisherList}
              siteList={siteList}
              adUnitList={adUnitList}
            />
          ),
        },
        {
          name: 'Filters',
          tab: (
            <FormFiltersTab
              filterBean={formState.targeting as FilterBean}
              dayPartExcluded
              setFilterBean={(targeting) => setFormState({ targeting })}
            />
          ),
        },
      ]}
    >
      <FormActionButtons isCreation={isCreation} onBack={redirectToList} onDelete={remove} onSubmit={submit} />
    </FormContainer>
  )
}
