import { Space } from 'antd'
import React from 'react'

import { DashboardStatRequestBean } from 'api/base/api'

import { DashboardForm } from 'views/dashboard/form/DashboardForm'
import { useDashboardFormData } from 'views/dashboard/form/useDashboardFormData'
import { DashboardStatistics } from 'views/dashboard/statistics/DashboardStatistics'
import { DashboardTop } from 'views/dashboard/top/DashboardTop'
import { DashboardTrend } from 'views/dashboard/trend/DashboardTrend'

export const Dashboard: React.FC = () => {
  const { isFormLoading, formState, setFormState } = useDashboardFormData()

  return (
    <Space direction="vertical">
      <DashboardForm isFormLoading={isFormLoading} formState={formState} setFormState={setFormState} />
      <DashboardStatistics formState={formState as DashboardStatRequestBean} />
      <DashboardTrend formState={formState as DashboardStatRequestBean} />
      <DashboardTop formState={formState as DashboardStatRequestBean} />
    </Space>
  )
}
