import React from 'react'

import { PublisherBean } from 'api/base/api'

import { FormNumberInput, FormRow, FormSelect, FormTextAreaInput, FormTextInput } from 'components/form'

const { UserCurrencyEnum, BiddingCurrencyEnum } = PublisherBean

interface Props {
  formState: Partial<PublisherBean>
  setFormState: (formState: Partial<PublisherBean>) => void
  isCreation: boolean
}

export const MainTab: React.FC<Props> = ({ formState, setFormState, isCreation }) => (
  <>
    <FormRow>
      <FormTextInput
        span={8}
        label="Name"
        value={formState.name}
        onChange={(name) => setFormState({ name })}
        required
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="Public ID"
        value={formState.publicId}
        onChange={(publicId) => setFormState({ publicId })}
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="Website"
        placeholder="https://website.com"
        value={formState.website}
        onChange={(website) => setFormState({ website })}
        required
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="E-mail"
        value={formState.email}
        onChange={(email) => setFormState({ email })}
        required
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="Company"
        value={formState.company}
        onChange={(company) => setFormState({ company })}
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="Description"
        value={formState.description}
        onChange={(description) => setFormState({ description })}
      />
    </FormRow>

    <FormRow>
      <FormNumberInput
        span={8}
        label="Revenue Share"
        value={formState.revenueShare}
        onChange={(revenueShare) => setFormState({ revenueShare })}
        required
        inputProps={{
          prefix: '%',
          min: 0,
          max: 100,
        }}
      />
    </FormRow>

    <FormRow>
      <FormSelect
        span={4}
        label="User currency"
        value={formState.userCurrency}
        onChange={(userCurrency) => setFormState({ userCurrency })}
        options={[
          { value: UserCurrencyEnum.USD, label: 'USD' },
          { value: UserCurrencyEnum.KRW, label: 'KRW' },
        ]}
        disabled={!isCreation}
        required
      />

      <FormSelect
        span={4}
        label="Bidding currency"
        value={formState.biddingCurrency}
        onChange={(biddingCurrency) => setFormState({ biddingCurrency })}
        options={[
          { value: BiddingCurrencyEnum.USD, label: 'USD' },
          { value: BiddingCurrencyEnum.KRW, label: 'KRW' },
        ]}
        disabled={!isCreation}
        required
      />
    </FormRow>
    <FormRow>
      <FormTextAreaInput
        span={8}
        textAreaProps={{ autoSize: { minRows: 3, maxRows: 5 } }}
        label="Restricted advertiser domains (comma-separated list)"
        value={formState.blockedDomains}
        onChange={(blockedDomains) => setFormState({ ...formState, blockedDomains })}
      />
    </FormRow>
  </>
)
