import React from 'react'

import { PaymentBeanInList } from 'api/base/api'

const { StatusEnum } = PaymentBeanInList

interface Props {
  status: PaymentBeanInList.StatusEnum | undefined
}

function getColor(status: PaymentBeanInList.StatusEnum | undefined) {
  switch (status) {
    case StatusEnum.PENDING:
      return '#757575'
    case StatusEnum.DRAFT:
      return '#bac134'
    case StatusEnum.PAID:
      return '#74c134'
    default:
      return ''
  }
}

export const PaymentStatusIndicator: React.FC<Props> = ({ status }) => (
  <div
    style={{
      display: 'inline-block',
      width: 10,
      height: 10,
      borderRadius: 5,
      marginRight: 3,
      background: getColor(status),
    }}
  />
)
