import { IabCategoryControllerApi, PublisherBean } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { cacheApiMethod } from 'utils/cacheApiMethod'

class CategoriesApi {
  api = new IabCategoryControllerApi(configuration, configuration.basePath, customFetch)

  list = (version: PublisherBean.IabTaxonomyVersionEnum) =>
    cacheApiMethod(`iabCategories${version}`, () => this.api.iabCategoryList(version as unknown as string))
}

export const categoriesApi = new CategoriesApi()
