import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { accountApi } from 'api/account'
import { AccountBean } from 'api/base/api'

import { createMessage } from 'utils/createMessage'
import { routes } from 'utils/routes'

import { FormActionButtons, FormContainer, FormRow, FormTextInput, useFormState } from 'components/form'

export const ChangePasswordForm: React.FC = () => {
  const history = useHistory()
  const [formState, setFormState] = useFormState<AccountBean>()

  const [passwordConfirmed, setPasswordConfirmed] = useState('')

  useEffect(() => {
    accountApi.checkUserInfo().then((bean) => {
      setFormState(bean.accountBean as AccountBean)
    })
  }, [])

  const redirectToList = () => {
    history.push(routes.adUnit.root)
  }

  const submit = () => {
    const bean = formState as AccountBean

    if (passwordConfirmed !== bean.password && (passwordConfirmed || bean.password)) {
      createMessage('error', 'Passwords do not match')
      return
    }

    accountApi.edit(bean).then(redirectToList)
  }

  return (
    <FormContainer title="Change password" isLoading={!formState}>
      <FormRow>
        <FormTextInput
          span={8}
          label="Password"
          value={formState?.password}
          onChange={(password) => setFormState({ password })}
          inputProps={{ type: 'Password' }}
          required
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Confirm password"
          value={passwordConfirmed}
          onChange={(password) => setPasswordConfirmed(password)}
          inputProps={{ type: 'Password' }}
          required
        />
      </FormRow>

      <FormActionButtons isCreation onBack={redirectToList} onSubmit={submit} />
    </FormContainer>
  )
}
