import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { DspList } from 'views/dsp/DspList'
import { DspForm } from 'views/dsp/form/DspForm'

export const DspRoot: React.FC = () => (
  <Switch>
    <Route path={routes.dsp.list('forRoute')} component={DspList} />
    <Route path={routes.dsp.create} component={DspForm} />
    <Route path={routes.dsp.edit()} component={DspForm} />
    <Redirect from="*" to={routes.dsp.list()} />
  </Switch>
)
