import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

import { DealBeanInList } from 'api/base/api'
import { dealApi } from 'api/deal'
import { publisherApi } from 'api/publisher'

import { auctionTypeToName, currencyToName, dealTypeToName } from 'utils/enumToName'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  NumberFilter,
  SelectFilter,
  TextFilter,
  useFiltersOverviewItems,
  useListFilters,
  useSelectListEntity,
} from 'components/list'

export const DealList: React.FC = () => {
  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => dealApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity((id) => dealApi.entityForList(id), routes.deal.list, list)

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(3)

  const columns: ColumnsType<DealBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
      filterIcon: <FilterIcon active={!!filters.id} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.id} onChange={(value) => setFilter('id', value)} filterProps={props} />
      ),
    },
    {
      title: 'Public ID',
      dataIndex: 'dealId',
      width: columnWidth,
      ellipsis: true,
      render: (_, { dealId }) => dealId,
      filterIcon: <FilterIcon active={!!filters.publicId} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.publicId} onChange={(value) => setFilter('publicId', value)} filterProps={props} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.deal.edit(id)}>{name}</ListLink>,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'publisherOwnerId',
      width: columnWidth,
      ellipsis: true,
      render: (_, { ownerName }) => ownerName,
      filterIcon: <FilterIcon active={!!filters.publisherId} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.publisherId}
          onChange={(value) => setFilter('publisherId', value)}
          getOptions={() =>
            publisherApi.listAll().then((beans) => beans.map(({ id, name }) => ({ text: name as string, value: id })))
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Bid Floor',
      dataIndex: 'bidFloor',
      width: columnWidth,
      ellipsis: true,
      render: (_, { bidFloor }) => bidFloor,
      filterIcon: <FilterIcon active={!!filters.bidFloor} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.bidFloor} onChange={(value) => setFilter('bidFloor', value)} filterProps={props} />
      ),
    },
  ]

  const filtersOverviewItems = useFiltersOverviewItems(selected?.filterBean)

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="deal"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.deal.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.deal.edit(selected.id)}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Public ID', value: selected.dealId },
                  { name: 'Name', value: selected.name },
                  { name: 'Bid Floor', value: `${currencyToName(selected.currencyType)} ${selected.bidFloor}` },
                  { name: 'Auction Type', value: auctionTypeToName(selected.auctionType) },
                  { name: 'Has priority', value: dealTypeToName(selected.dealType) },
                  { name: 'Owner', value: selected.ownerName },
                  { name: 'Linked Ad Units', value: selected.linkedAdUnits?.map((adUnit) => adUnit.name).join(', ') },
                  { name: 'White list advertiser domains', value: selected.whiteListDomains },
                  { name: 'White list seat IDs', value: selected.whiteListSeatIds },
                ],
              },
              {
                name: 'Filters',
                items: filtersOverviewItems,
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
