import React, { useContext } from 'react'

import { StatRequestBean } from 'api/base/api'

import { UserContext } from 'utils/userContext'

import { FormContainer } from 'components/form'

import { ReportFormMainTab } from 'views/report/form/tabs/ReportFormMainTab'
import { ReportFormScheduleTab } from 'views/report/form/tabs/ReportFormScheduleTab'
import { useReportFormData } from 'views/report/form/useReportFormData'

interface Props {
  generate: (formData: StatRequestBean) => void
  isGenerating: boolean
}

export const ReportForm: React.FC<Props> = ({ generate, isGenerating }) => {
  const { isFormLoading, formState, setFormState, savedReport, setSavedReport, timeZones } = useReportFormData()

  const canReadGross = useContext(UserContext).canRead('grossReport')

  const tabs = [
    {
      name: 'Report Parameters',
      tab: (
        <ReportFormMainTab
          generate={generate}
          isGenerating={isGenerating}
          canReadGross={canReadGross}
          formState={formState}
          setFormState={setFormState}
          savedReport={savedReport}
          setSavedReport={setSavedReport}
          timeZones={timeZones}
        />
      ),
    },
  ]

  if (savedReport.id !== -1)
    tabs.push({
      name: 'Scheduler',
      tab: <ReportFormScheduleTab reportId={savedReport.id ?? 0} timeZones={timeZones} />,
    })

  return <FormContainer title={savedReport.id === -1 ? 'New Report' : 'Report'} isLoading={isFormLoading} tabs={tabs} />
}
