import { Col, Row, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

import { PublisherBeanInList } from 'api/base/api'
import { publisherApi } from 'api/publisher'

import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  NumberFilter,
  TextFilter,
  useFiltersOverviewItems,
  useListFilters,
  useSelectListEntity,
} from 'components/list'
import { useCategoriesOverviewItems } from 'components/list/utils/useCategoriesOverviewItems'
import { useDspMappingOverviewItems } from 'components/list/utils/useDspMappingOverviewItems'

export const PublisherList: React.FC = () => {
  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => publisherApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity(
    (id) => publisherApi.entityForList(id),
    routes.publisher.list,
    list
  )

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(4)

  const columns: ColumnsType<PublisherBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
      filterIcon: <FilterIcon active={!!filters.id} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.id} onChange={(value) => setFilter('id', value)} filterProps={props} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.publisher.edit(id)}>{name}</ListLink>,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: 'Sites/Apps',
      align: 'center',
      width: columnWidth,
      render: (_, { id, siteCount }) => (
        <ListLink
          to={{
            pathname: routes.site.list(),
            state: { publisherId: id },
          }}
        >
          <Tooltip title="Show sites">{siteCount}</Tooltip>
        </ListLink>
      ),
    },
    {
      title: 'Ad Units',
      align: 'center',
      width: columnWidth,
      render: (_, { id, adUnitCount }) => (
        <ListLink
          to={{
            pathname: routes.adUnit.list(),
            state: { publisherId: id },
          }}
        >
          <Tooltip title="Show ad units">{adUnitCount}</Tooltip>
        </ListLink>
      ),
    },
  ]

  const filtersOverviewItems = useFiltersOverviewItems(selected?.filterBean)
  const categoriesOverviewItems = useCategoriesOverviewItems(
    selected?.iabTaxonomyVersion,
    selected?.categories ?? [],
    selected?.blockedCategories ?? []
  )
  const dspMappingOverviewItems = useDspMappingOverviewItems(selected?.dspIdMappingBeans)

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="publisher"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.publisher.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.publisher.edit(selected.id)}
            additionalLinks={[{ name: 'Create site', to: routes.site.createWithPublisher(selected.id) }]}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Name', value: selected.name },
                  { name: 'Website', value: selected.website },
                  { name: 'Email', value: selected.email },
                  { name: 'User currency', value: selected.userCurrency },
                  { name: 'Bidding currency', value: selected.biddingCurrency },
                  { name: 'Company', value: selected.company },
                  { name: 'Description', value: selected.description },
                  { name: 'Blocked domains', value: selected.blockedDomains },
                  { name: 'Revenue share', value: `${(selected?.revenueShare as number) * 100}%` },
                ],
              },
              {
                name: 'Filters',
                items: filtersOverviewItems,
              },
              {
                name: 'Categories',
                items: categoriesOverviewItems,
              },
              {
                name: 'Dsp Mappings',
                items: dspMappingOverviewItems,
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
