import { StatRequestBean } from 'api/base/api'

import { ReportDictionaryElem } from 'views/report/utils/reportTypes'

const { ValuesEnum } = StatRequestBean

export const reportValues: ReportDictionaryElem[] = [
  {
    value: ValuesEnum.PubReq,
    label: 'Requests',
    format: 'number',
  },
  {
    value: ValuesEnum.PubBid,
    label: 'Bids',
    format: 'number',
  },
  {
    value: ValuesEnum.PubBidRate,
    label: 'Bid Rate',
    format: 'rate',
  },
  {
    value: ValuesEnum.PubBidFloorAvg,
    label: 'Bid Floor Avg',
    format: 'money',
  },
  {
    value: ValuesEnum.PubBidPrice,
    label: 'Bid Revenue',
    format: 'money',
  },
  {
    value: ValuesEnum.PubBidPriceAvg,
    label: 'Bid CPM Avg',
    format: 'money',
  },
  {
    value: ValuesEnum.PubWin,
    label: 'Wins',
    format: 'number',
  },
  {
    value: ValuesEnum.DspWinPriceAvg,
    label: 'Win CPM Avg',
    format: 'money',
  },
  {
    value: ValuesEnum.PubWinRate,
    label: 'Win Rate',
    format: 'rate',
  },
  {
    value: ValuesEnum.DspReq,
    label: 'DSP Requests',
    format: 'number',
  },
  {
    value: ValuesEnum.DspBid,
    label: 'DSP Bids',
    format: 'number',
  },
  {
    value: ValuesEnum.DspBidRate,
    label: 'DSP Bid Rate',
    format: 'rate',
  },
  {
    value: ValuesEnum.DspBidPrice,
    label: 'DSP Bid Revenue',
    format: 'money',
  },
  {
    value: ValuesEnum.DspBidPriceAvg,
    label: 'DSP Bid CPM Avg',
    format: 'money',
  },
  {
    value: ValuesEnum.DspError,
    label: 'DSP Bid Errors',
    format: 'number',
  },
  {
    value: ValuesEnum.DspWinRate,
    label: 'DSP Win Rate',
    format: 'rate',
  },
  {
    value: ValuesEnum.DspWinPrice,
    label: 'Win Revenue',
    format: 'money',
  },
  {
    value: ValuesEnum.Render,
    label: 'Renders',
    format: 'number',
  },
  {
    value: ValuesEnum.Impr,
    label: 'Imprs',
    format: 'number',
  },
  {
    value: ValuesEnum.ImprRate,
    label: 'Impr Rate',
    format: 'rate',
  },
  {
    value: ValuesEnum.FillRate,
    label: 'Fill Rate',
    format: 'rate',
  },
  {
    value: ValuesEnum.ImprPrice,
    label: 'Gross Revenue',
    format: 'money',
    revenueType: 'gross',
  },
  {
    value: ValuesEnum.NetImprPrice,
    label: 'Net Revenue',
    format: 'money',
  },
  {
    value: ValuesEnum.ImprPriceAvg,
    label: 'Gross Impr CPM Avg',
    format: 'money',
    revenueType: 'gross',
  },
  {
    value: ValuesEnum.NetImprPriceAvg,
    label: 'Net Impr CPM Avg',
    format: 'money',
  },
  {
    value: ValuesEnum.Profit,
    label: 'Profit',
    format: 'money',
    revenueType: 'gross',
  },

  {
    value: ValuesEnum.VastError,
    label: 'VAST error',
    format: 'number',
  },
  {
    value: ValuesEnum.VastStart,
    label: 'VAST start',
    format: 'number',
  },
  {
    value: ValuesEnum.VastFirstQ,
    label: 'VAST first q',
    format: 'number',
  },
  {
    value: ValuesEnum.VastMid,
    label: 'VAST mid',
    format: 'number',
  },
  {
    value: ValuesEnum.VastThirdQ,
    label: 'VAST third q',
    format: 'number',
  },
  {
    value: ValuesEnum.VastComplete,
    label: 'VAST complete',
    format: 'number',
  },
  {
    value: ValuesEnum.VastCloseLinear,
    label: 'VAST close linear',
    format: 'number',
  },
  {
    value: ValuesEnum.VastSkip,
    label: 'VAST skip',
    format: 'number',
  },
  {
    value: ValuesEnum.VastPause,
    label: 'VAST pause',
    format: 'number',
  },
  {
    value: ValuesEnum.DspClick,
    label: 'DSP Clicks',
    format: 'number',
  },
  {
    value: ValuesEnum.DspCtr,
    label: 'DSP CTR',
    format: 'rate',
  },
  {
    value: ValuesEnum.DirectAdClick,
    label: 'Direct Ad Clicks',
    format: 'number',
  },
  {
    value: ValuesEnum.DirectAdCtr,
    label: 'Direct Ad CTR',
    format: 'rate',
  },
  {
    value: ValuesEnum.VastClick,
    label: 'VAST Clicks',
    format: 'number',
  },
  {
    value: ValuesEnum.VastCtr,
    label: 'VAST CTR',
    format: 'rate',
  },
  {
    value: ValuesEnum.Interstitial,
    label: 'Interstitial',
    format: 'number',
  },
  {
    value: ValuesEnum.Rewarded,
    label: 'Rewarded',
    format: 'number',
  },
  {
    value: ValuesEnum.WinError,
    label: 'Win Errors',
    format: 'number',
  },
  {
    value: ValuesEnum.RenderError,
    label: 'Render Errors',
    format: 'number',
  },
  {
    value: ValuesEnum.ImprError,
    label: 'Impression Errors',
    format: 'number',
  },
  {
    value: ValuesEnum.ExternalImpr,
    label: 'Confirmed Impressions',
    format: 'number',
    revenueType: 'gross',
  },
  {
    value: ValuesEnum.ExternalRevenue,
    label: 'Confirmed Revenue',
    format: 'money',
    revenueType: 'gross',
  },
  {
    value: ValuesEnum.VastRetrieve,
    label: 'VAST retrieve',
    format: 'number',
  },
  {
    value: ValuesEnum.PlayerLoading,
    label: 'Player.prepareForAds',
    format: 'number',
  },
  {
    value: ValuesEnum.VideoJsLoaded,
    label: 'Player.tryToPlayPreroll',
    format: 'number',
  },
  {
    value: ValuesEnum.PluginsLoaded,
    label: 'Player.playPrerollAd',
    format: 'number',
  },
  {
    value: ValuesEnum.PlayerError,
    label: 'Player Errors',
    format: 'number',
  },
]
