import { Row } from 'antd'
import React from 'react'

interface Props {
  style?: React.CSSProperties
  children: React.ReactNode
}

export const FormRow: React.FC<Props> = ({ style, children }) => (
  <Row gutter={10} style={style}>
    {children}
  </Row>
)
