import { Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { DashboardChartBean, DashboardChartRequestBean, DashboardStatRequestBean } from 'api/base/api'
import { dashboardApi } from 'api/dashboard'

import { DashboardTrendChart } from 'views/dashboard/trend/DashboardTrendChart'

const { ValuesEnum } = DashboardChartRequestBean

const revenueValues = [ValuesEnum.NetImprPrice, ValuesEnum.NetImprPriceAvg]
const trafficValues = [ValuesEnum.PubReq, ValuesEnum.PubBid, ValuesEnum.PubWin, ValuesEnum.Impr]

interface Props {
  formState: DashboardStatRequestBean
}

export const DashboardTrend: React.FC<Props> = ({ formState }) => {
  const [response, setResponse] = useState<DashboardChartBean>()

  useEffect(() => {
    dashboardApi
      .trend({
        periodType: formState.periodType,
        startDate: formState.startDate,
        endDate: formState.endDate,
        currency: formState.currency,
        audit: formState.audit,
        filters: formState.filters,
        values: [...revenueValues, ...trafficValues],
      })
      .then(setResponse)
  }, [formState])

  return (
    <Row gutter={10}>
      <DashboardTrendChart
        title="Revenue Trend"
        values={revenueValues}
        response={response}
        currency={formState.currency}
        format="money"
      />

      <DashboardTrendChart
        title="Traffic Trend"
        values={trafficValues}
        response={response}
        currency={formState.currency}
        format="number"
      />
    </Row>
  )
}
