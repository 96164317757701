import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { SavedReportList } from 'views/savedReport/SavedReportList'

export const SavedReportRoot: React.FC = () => (
  <Switch>
    <Route path={routes.savedReport.list('forRoute')} component={SavedReportList} />
    <Redirect from="*" to={routes.savedReport.list()} />
  </Switch>
)
