import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { adUnitApi } from 'api/adUnit'
import { AdUnitBeanInList } from 'api/base/api'
import { publisherApi } from 'api/publisher'
import { siteApi } from 'api/site'

import { boolToText } from 'utils/boolToText'
import { adUnitTypeToName, auctionTypeToName, biddingCurrencyToName } from 'utils/enumToName'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  FilterIcon,
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  NumberFilter,
  SelectFilter,
  TextFilter,
  useFiltersOverviewItems,
  useListFilters,
  useSelectListEntity,
} from 'components/list'
import { useDspMappingOverviewItems } from 'components/list/utils/useDspMappingOverviewItems'

export const AdUnitList: React.FC = () => {
  const location = useLocation<{ publisherId: number | undefined; siteId: number | undefined }>()
  const { filters, setFilter } = useListFilters(
    location.state && {
      publisherId: location.state.publisherId,
      siteId: location.state.siteId,
    }
  )
  const [list, { totalEntities, isLoading }] = useGetList(() => adUnitApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity((id) => adUnitApi.entityForList(id), routes.adUnit.list, list)

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(4)

  const columns: ColumnsType<AdUnitBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
      filterIcon: <FilterIcon active={!!filters.id} />,
      filterDropdown: (props) => (
        <NumberFilter value={filters.id} onChange={(value) => setFilter('id', value)} filterProps={props} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.adUnit.edit(id)}>{name}</ListLink>,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Publisher',
      dataIndex: 'siteId',
      width: columnWidth,
      ellipsis: true,
      render: (_, { publisherName }) => publisherName,
      filterIcon: <FilterIcon active={!!filters.publisherId} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.publisherId}
          onChange={(value) => setFilter('publisherId', value)}
          getOptions={() =>
            publisherApi.listAll().then((beans) => beans.map(({ id, name }) => ({ text: name as string, value: id })))
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Site/App',
      dataIndex: 'siteId',
      width: columnWidth,
      ellipsis: true,
      render: (_, { siteName }) => siteName,
      filterIcon: <FilterIcon active={!!filters.siteId} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.siteId}
          onChange={(value) => setFilter('siteId', value)}
          getOptions={() =>
            siteApi.listAll().then((beans) => beans.map(({ id, name }) => ({ text: name as string, value: id })))
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Audit',
      dataIndex: 'test',
      width: columnWidth,
      ellipsis: true,
      render: (_, { test }) => boolToText(test),
      filterIcon: <FilterIcon active={filters.test !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.test}
          onChange={(value) => setFilter('test', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: columnWidth,
      ellipsis: true,
      render: (_, { active }) => boolToText(active),
      filterIcon: <FilterIcon active={filters.active !== undefined} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.active}
          onChange={(value) => setFilter('active', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Active', value: true },
              { text: 'Inactive', value: false },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
  ]

  const filtersOverviewItems = useFiltersOverviewItems(selected?.filterBean)
  const dspMappingOverviewItems = useDspMappingOverviewItems(selected?.dspIdMappingBeans)

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="ad unit"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.adUnit.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.adUnit.edit(selected.id)}
            additionalLinks={[
              {
                to: {
                  pathname: routes.generateTag.root,
                  state: {
                    publisherId: selected.publisherId,
                    siteId: selected.siteId,
                    adUnitId: selected.id,
                  },
                },
                name: 'Generate tag',
              },
            ]}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Active', value: boolToText(selected.active) },
                  { name: 'Audit', value: boolToText(selected.test) },
                  { name: 'Site/App', value: selected.siteName },
                  { name: 'Name', value: selected.name },
                  {
                    name: 'Bid floor',
                    value: `${biddingCurrencyToName(selected.biddingCurrency)} ${selected.bidFloor}`,
                  },
                  { name: 'Auction type', value: auctionTypeToName(selected.auctionType) },
                  { name: 'Private Auction', value: boolToText(selected.privateAuction) },
                  { name: 'Creative type', value: adUnitTypeToName(selected.adUnitType) },
                  { name: 'Banner Ad sizes', value: selected.bannerAdSizeNames?.join(', ') },
                  { name: 'Video Ad size', value: selected.videoAdSizeName },
                  { name: 'Deals', value: selected.dealNames?.join(', ') },
                  { name: 'DSPs', value: selected.dspNames?.join(', ') },
                  { name: 'Direct Campaigns', value: selected.linkedCampaignNames?.join(', ') },
                ],
              },
              {
                name: 'Filters',
                items: filtersOverviewItems,
              },
              {
                name: 'Dsp Mappings',
                items: dspMappingOverviewItems,
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
