import { Col, Divider, Spin } from 'antd'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { PaymentAdjustmentBean, PaymentBean } from 'api/base/api'
import { paymentApi } from 'api/payment'

import { routes } from 'utils/routes'
import { UserContext } from 'utils/userContext'

import {
  FormActionButtons,
  FormContainer,
  FormDatePicker,
  FormEntityList,
  FormNumberInput,
  FormRow,
  FormSelect,
} from 'components/form'

import { usePaymentFormData } from 'views/payment/form/usePaymentFormData'

const { StatusEnum } = PaymentBean
const { ReasonEnum } = PaymentAdjustmentBean

export const PaymentForm: React.FC = () => {
  const history = useHistory()
  const { canRead } = useContext(UserContext)
  const { isCreation, isFormLoading, formState, setFormState, dspList, publisherList, KRWRate } = usePaymentFormData()

  const redirectToList = () => {
    history.push(routes.payment.list())
  }

  const submit = () => {
    const bean = formState as PaymentBean

    if (isCreation) {
      paymentApi.create(bean).then(redirectToList)
    } else {
      paymentApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    paymentApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer title="Edit payment" isLoading={isFormLoading}>
      {isCreation && (
        <FormRow>
          <FormSelect
            span={8}
            label="Publisher"
            placeholder="Select publisher"
            value={formState.sellerId}
            onChange={(sellerId) => setFormState({ sellerId })}
            options={publisherList.map(({ id, name }) => ({ value: id, label: name }))}
          />
        </FormRow>
      )}

      {isCreation && (
        <FormRow>
          <FormDatePicker
            span={8}
            label="Period"
            value={formState.period ? dayjs(formState.period) : dayjs(Date.now())}
            onChange={(value) => setFormState({ period: value?.format('MMM YYYY') })}
            datePickerProps={{ picker: 'month', format: 'MMM YYYY' }}
          />
        </FormRow>
      )}

      <FormRow>
        <FormSelect
          span={8}
          label="Status"
          placeholder="Select status"
          value={formState.status}
          onChange={(status) =>
            setFormState({
              status,
              paidDate: status === StatusEnum.PAID ? dayjs(Date.now()).format('MMM D, YYYY') : undefined,
            })
          }
          options={[
            { label: 'Draft', value: StatusEnum.DRAFT.toString() },
            { label: 'Pending', value: StatusEnum.PENDING.toString() },
            { label: 'Paid', value: StatusEnum.PAID.toString() },
          ]}
        />

        {formState.status === StatusEnum.PAID && (
          <FormDatePicker
            span={8}
            label="Paid date"
            value={formState.paidDate ? dayjs(formState.paidDate) : undefined}
            onChange={(value) => setFormState({ paidDate: value?.format('YYYY-MM-DD') })}
            datePickerProps={{ picker: 'date', format: 'MMM D, YYYY' }}
          />
        )}
      </FormRow>

      <FormRow>
        <Col span={12}>
          <Divider orientation="left">Adjustments</Divider>
        </Col>
      </FormRow>

      <FormEntityList
        label={
          <>
            <Col span={4} style={{ marginBottom: 10 }}>
              Reason
            </Col>
            <Col span={4} style={{ marginBottom: 10 }}>
              DSP
            </Col>
            <Col span={4} style={{ marginBottom: 10 }}>
              Amount in USD
            </Col>
          </>
        }
        list={formState.adjustments ?? []}
        updateList={(adjustments) => setFormState({ adjustments })}
        getNewEntity={() => ({} as PaymentAdjustmentBean)}
        renderRow={(bean, index) => (
          <>
            <FormSelect
              span={4}
              placeholder="Select reason"
              value={bean.reason}
              onChange={(reason) => {
                const adjustments = [...(formState.adjustments ?? [])]
                adjustments[index].reason = reason
                setFormState({ adjustments })
              }}
              options={[
                { label: 'Discrepancy', value: ReasonEnum.DISCREPANCY },
                { label: 'Invalid traffic', value: ReasonEnum.INVALIDTRAFFIC },
                { label: 'Other', value: ReasonEnum.OTHER },
              ]}
            />

            <FormSelect
              span={4}
              placeholder="Select DSP"
              value={bean.dspId}
              onChange={(dspId) => {
                const adjustments = [...(formState.adjustments ?? [])]
                adjustments[index].dspId = dspId
                setFormState({ adjustments })
              }}
              options={dspList.map(({ id, name }) => ({ value: id, label: name }))}
            />

            <FormNumberInput
              span={4}
              value={bean.amountUSD}
              onChange={(amountUSD) => {
                const adjustments = [...(formState.adjustments ?? [])]
                // @ts-ignore
                adjustments[index].amountUSD = amountUSD
                setFormState({ adjustments })
              }}
              inputProps={{ prefix: '$' }}
            />

            <Col style={{ marginTop: 4, opacity: 0.7 }}>
              =&nbsp;&nbsp;
              {KRWRate ? (
                Intl.NumberFormat('en-US', { maximumFractionDigits: 4, currency: 'KRW', style: 'currency' }).format(
                  (bean.amountUSD ?? 0) * (KRWRate ?? 1)
                )
              ) : (
                <Spin />
              )}
            </Col>
          </>
        )}
      />

      <FormActionButtons
        isCreation={isCreation}
        onBack={redirectToList}
        onDelete={canRead('paymentEdit') ? remove : undefined}
        onSubmit={canRead('paymentEdit') ? submit : undefined}
      />
    </FormContainer>
  )
}
