import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { SiteBean } from 'api/base/api'
import { publisherApi } from 'api/publisher'
import { siteApi } from 'api/site'

import { useGetList } from 'utils/useGetList'

import { getDefaultFilterBean, useFormState } from 'components/form'

const { IabTaxonomyVersionEnum } = SiteBean

export function useSiteFormData() {
  const { id, publisherId } = useParams<{ id: string; publisherId: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<SiteBean>()

  useEffect(() => {
    if (id) {
      siteApi.entity(+id).then((bean) => {
        setFormName(bean.name ?? '')
        setFormState({ ...bean, filterBean: bean.filterBean ?? getDefaultFilterBean() } ?? {})
      })
    } else {
      setFormState({
        active: true,
        name: '',
        publisherId: publisherId ? +publisherId : undefined,
        filterBean: getDefaultFilterBean(),
        iabTaxonomyVersion: IabTaxonomyVersionEnum._10,
      })
    }
  }, [])

  const [publisherList] = useGetList(() => publisherApi.listAll())

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
    publisherList,
  }
}
