import dayjs from 'dayjs'
import React from 'react'

import { StatRequestBean } from 'api/base/api'

import { FormDatePicker } from 'components/form/inputs/FormDatePicker'
import { FormSelect } from 'components/form/inputs/FormSelect'

const { PeriodTypeEnum } = StatRequestBean

const options = [
  {
    label: 'Today',
    value: PeriodTypeEnum.TODAY,
  },
  {
    label: 'Yesterday',
    value: PeriodTypeEnum.YESTERDAY,
  },
  {
    label: 'Last 3 Days',
    value: PeriodTypeEnum.LAST3DAYS,
  },
  {
    label: 'Last 7 Days',
    value: PeriodTypeEnum.LAST7DAYS,
  },
  {
    label: 'Last 30 Days',
    value: PeriodTypeEnum.LAST30DAYS,
  },
  {
    label: 'This Month',
    value: PeriodTypeEnum.THISMONTH,
  },
  {
    label: 'Last Month',
    value: PeriodTypeEnum.LASTMONTH,
  },
  {
    label: 'Custom Range',
    value: PeriodTypeEnum.CUSTOMRANGE,
  },
]

interface Props {
  periodType?: StatRequestBean.PeriodTypeEnum
  setPeriodType: (periodType: StatRequestBean.PeriodTypeEnum) => void
  startDate?: string
  setStartDate: (startDate: string) => void
  endDate?: string
  setEndDate: (endDate: string) => void
  spans?: number[]
}

export const FormPeriodPicker: React.FC<Props> = ({
  periodType,
  setPeriodType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  spans,
}) => (
  <>
    <FormSelect
      span={
        periodType === PeriodTypeEnum.CUSTOMRANGE ? (spans ? spans[0] - spans[1] - spans[2] : 6) : spans ? spans[0] : 12
      }
      label="Period"
      value={periodType}
      onChange={(value) => setPeriodType(value)}
      options={options}
      selectProps={{ filterSort: undefined }}
    />

    {periodType === PeriodTypeEnum.CUSTOMRANGE && (
      <>
        <FormDatePicker
          span={spans ? spans[1] : 3}
          label="Start Date"
          value={dayjs(startDate)}
          onChange={(value) => setStartDate(dayjs(value).format('YYYY-MM-DD'))}
          datePickerProps={{ disabledDate: (date) => +date > +dayjs(endDate), style: { width: '100%' } }}
        />
        <FormDatePicker
          span={spans ? spans[2] : 3}
          label="End Date"
          value={dayjs(endDate)}
          onChange={(value) => setEndDate(dayjs(value).format('YYYY-MM-DD'))}
          datePickerProps={{
            disabledDate: (date) => +date < +dayjs(startDate),
            style: { width: '100%' },
          }}
        />
      </>
    )}
  </>
)
