import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

import { DspBeanInList } from 'api/base/api'
import { dspApi } from 'api/dsp'

import { boolToText } from 'utils/boolToText'
import { taxonomyVersionToName } from 'utils/enumToName'
import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import {
  getListColumnWidth,
  ListLink,
  ListOverview,
  ListTable,
  useFiltersOverviewItems,
  useListFilters,
  useSelectListEntity,
} from 'components/list'
import { useCategoriesOverviewItems } from 'components/list/utils/useCategoriesOverviewItems'

export const DspList: React.FC = () => {
  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => dspApi.list(filters), filters)
  const { selected, selectEntity } = useSelectListEntity((id) => dspApi.entityForList(id), routes.dsp.list, list)

  const { idWidth, nameWidth, columnWidth } = getListColumnWidth(1)

  const columns: ColumnsType<DspBeanInList> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: idWidth,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { id, name }) => <ListLink to={routes.dsp.edit(id)}>{name}</ListLink>,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: columnWidth,
      ellipsis: true,
      render: (_, { active }) => boolToText(active),
    },
  ]

  const filtersOverviewItems = useFiltersOverviewItems(selected?.filterBean)
  const categoriesOverviewItems = useCategoriesOverviewItems(
    selected?.iabTaxonomyVersion,
    selected?.categories ?? [],
    selected?.blockedCategories ?? []
  )

  return (
    <Row gutter={20}>
      <Col span={16}>
        <ListTable
          entityName="DSP"
          columns={columns}
          list={list}
          isLoading={isLoading}
          selected={selected}
          selectEntity={selectEntity}
          currentPage={filters.page}
          totalEntities={totalEntities}
          onPageChange={(page) => setFilter('page', page)}
          createLink={routes.dsp.create}
        />
      </Col>

      {selected && (
        <Col span={8}>
          <ListOverview
            title={selected.name}
            editLink={routes.dsp.edit(selected.id)}
            sections={[
              {
                name: 'Main',
                items: [
                  { name: 'Active', value: boolToText(selected.active) },
                  { name: 'Name', value: selected.name },
                  { name: 'Endpoint url', value: selected.endpointUrl },
                  { name: 'Multiple impression', value: boolToText(selected.multiImp) },
                  { name: 'User sync endpoint', value: selected.userSyncEndpoint },
                  { name: 'User sync UID macro', value: selected.userSyncUidMacro },
                  { name: 'Registration ID', value: selected.registrationId },
                  { name: 'Unwrap VAST', value: boolToText(selected.unwrapVast) },
                  { name: 'Compression', value: selected.compressionType },
                  { name: 'DSP adapter', value: selected.bidRequestAdapterType },
                  {
                    name: 'Response timeout',
                    value: selected.timeout === undefined ? 'not specified' : `${selected.timeout} ms`,
                  },
                  {
                    name: 'QPS Limit',
                    value: selected.qpsLimit === undefined ? 'not specified' : `${selected.qpsLimit} query per second`,
                  },
                  { name: 'Taxonomy version', value: taxonomyVersionToName(selected.iabTaxonomyVersion) },
                  {
                    name: 'Sampling rate',
                    value: selected.samplingRate === undefined ? 'not specified' : selected.samplingRate,
                  },
                ],
              },
              {
                name: 'Filters',
                items: filtersOverviewItems,
              },
              {
                name: 'Categories',
                items: categoriesOverviewItems,
              },
            ]}
          />
        </Col>
      )}
    </Row>
  )
}
