import { DatePicker } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import { Dayjs } from 'dayjs'
import React from 'react'

interface Props {
  value: Dayjs | undefined
  onChange: (value: Dayjs | undefined) => void
  filterProps: FilterDropdownProps
  allowClear?: boolean
}

export const DateFilter: React.FC<Props> = ({ value, onChange, filterProps, allowClear }) => (
  <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
    <DatePicker
      picker="month"
      value={value}
      onChange={(value) => {
        onChange(value || undefined)
        filterProps.close()
      }}
      placeholder="Filter"
      allowClear={allowClear}
      style={{ minWidth: 180 }}
    />
  </div>
)
