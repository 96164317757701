import React from 'react'
import { useHistory } from 'react-router-dom'

import { SiteBean } from 'api/base/api'
import { dspApi } from 'api/dsp'
import { siteApi } from 'api/site'

import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import { FormActionButtons, FormCategoriesTab, FormContainer, FormDspMappingTab } from 'components/form'

import { MainTab } from 'views/site/form/MainTab'
import { useSiteFormData } from 'views/site/form/useSiteFormData'

export const SiteForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formName, formState, setFormState, publisherList } = useSiteFormData()

  const redirectToList = () => {
    history.push(routes.site.list())
  }

  const [dspList] = useGetList(dspApi.listAll)

  const submit = () => {
    const bean = formState as SiteBean

    if (isCreation) {
      siteApi.create(bean).then(redirectToList)
    } else {
      siteApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    siteApi.delete(formState.id as number).then(redirectToList)
  }

  return (
    <FormContainer
      title={isCreation ? 'New site/app' : formName}
      isLoading={isFormLoading}
      tabs={[
        {
          name: 'Main',
          tab: (
            <MainTab
              formState={formState}
              setFormState={setFormState}
              isCreation={isCreation}
              publisherList={publisherList}
            />
          ),
        },
        // filters removed to avoid conflicts with direct campaigns
        {
          name: 'Categories',
          tab: (
            <FormCategoriesTab
              version={formState.iabTaxonomyVersion}
              setVersion={(iabTaxonomyVersion) => setFormState({ iabTaxonomyVersion })}
              linked={formState.categories}
              setLinked={(categories) => setFormState({ categories })}
              blocked={formState.blockedCategories}
              setBlocked={(blockedCategories) => setFormState({ blockedCategories })}
            />
          ),
        },
        {
          name: 'DSP ID Mapping',
          tab: (
            <FormDspMappingTab
              entityId={formState.id}
              dspList={dspList}
              mappingList={formState.dspIdMappings || []}
              setMappingList={(dspIdMappings) => setFormState({ ...formState, dspIdMappings })}
            />
          ),
        },
      ]}
    >
      <FormActionButtons isCreation={isCreation} onBack={redirectToList} onDelete={remove} onSubmit={submit} />
    </FormContainer>
  )
}
