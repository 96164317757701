import { adSizeApi } from 'api/adSize'
import { adUnitApi } from 'api/adUnit'
import { FilterEntry } from 'api/base/api'
import { countryApi } from 'api/country'
import { countrySubdivisionApi } from 'api/countrySubdivision'
import { dealApi } from 'api/deal'
import { directAdApi } from 'api/directAd'
import { directCampaignApi } from 'api/directCampaign'
import { dspApi } from 'api/dsp'
import { publisherApi } from 'api/publisher'
import { siteApi } from 'api/site'

import { ReportDictionaryElem, ReportFilterOption } from 'views/report/utils/reportTypes'

const { KeysEnum } = FilterEntry

function optionsGenerator<Entity>(
  fetchList: () => Promise<Entity[]>,
  mapEntity: (entity: Entity) => ReportFilterOption
) {
  return () => fetchList().then((beans) => beans.map((bean) => mapEntity(bean)))
}

export const reportKeys: ReportDictionaryElem[] = [
  {
    value: KeysEnum.AdSize,
    label: 'Creative Ad Size',
    labelPlural: 'Ad Sizes',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(adSizeApi.listAll, ({ id, name }) => ({ value: id, label: name })),
  },
  {
    value: KeysEnum.AdUnitName,
    label: 'Ad Unit',
    labelPlural: 'Ad Units',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(adUnitApi.listAll, ({ id, name }) => ({ value: id, label: name })),
  },
  {
    value: KeysEnum.AdUnit,
    label: 'Ad Unit ID',
    format: 'string',
    filter: 'commaSeparated',
  },
  {
    value: KeysEnum.DealPublicId,
    label: 'Deal Public ID',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(dealApi.listAll, ({ id, name }) => ({ value: id, label: name })),
  },
  {
    value: KeysEnum.Country,
    label: 'Country',
    labelPlural: 'Countries',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(countryApi.listAll, ({ iso3, name }) => ({ value: iso3, label: name })),
  },
  {
    value: KeysEnum.CountrySubdivision,
    label: 'Country Subdivision',
    labelPlural: 'Country Subdivisions',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(countrySubdivisionApi.listAll, ({ iso, name }) => ({ value: iso, label: name })),
  },
  {
    value: KeysEnum.CreativeType,
    label: 'Creative Type',
    format: 'string',
    filter: 'select',
    getFilterOptions: () => Promise.resolve(['Banner', 'Video'].map((name) => ({ value: name, label: name }))),
  },
  {
    value: KeysEnum.VideoType,
    label: 'Video type',
    format: 'string',
    filter: 'select',
    getFilterOptions: () => Promise.resolve(['VAST', 'VPAID'].map((name) => ({ value: name, label: name }))),
  },
  {
    value: KeysEnum.AdUnitType,
    label: 'Ad Unit Type',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve(['Banner', 'Video', 'Multi-format'].map((name) => ({ value: name, label: name }))),
  },
  {
    value: KeysEnum.Day,
    label: 'Day',
    format: 'string',
  },
  {
    value: KeysEnum.Device,
    label: 'Device Type',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve(
        [
          'Unknown',
          'Mobile/Tablet',
          'Personal Computer',
          'Connected TV',
          'Phone',
          'Tablet',
          'Connected Device',
          'Set-Top Box',
        ].map((name) => ({ value: name, label: name }))
      ),
  },
  {
    value: KeysEnum.ReqType,
    label: 'Request Type',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve([
        { label: 'Prebid.js', value: 'Adapter' },
        { label: 'Static Tag', value: 'Tag' },
        { label: 'VAST URL', value: 'VAST' },
        { label: 'OpenRTB', value: 'OpenRTB' },
        { label: 'PBS Mobile', value: 'PBS Mobile' },
        { label: 'Ad Server', value: 'Ad Server' },
      ]),
  },
  {
    value: KeysEnum.Domain,
    label: 'Domain',
    format: 'string',
    filter: 'commaSeparated',
  },
  {
    value: KeysEnum.AdvertiserDomains,
    label: 'Advertiser Domain',
    format: 'string',
    filter: 'commaSeparated',
  },
  {
    value: KeysEnum.DspName,
    label: 'DSP',
    labelPlural: 'DSPs',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(dspApi.listAll, ({ id, name }) => ({ value: id, label: name })),
  },
  {
    value: KeysEnum.Dsp,
    label: 'DSP ID',
    format: 'string',
    filter: 'commaSeparated',
  },
  {
    value: KeysEnum.DirectAdName,
    label: 'Direct Ad',
    labelPlural: 'Direct Ads',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(directAdApi.listAll, ({ id, name }) => ({ value: id, label: name })),
  },
  {
    value: KeysEnum.DirectAd,
    label: 'Direct Ad ID',
    format: 'string',
    filter: 'commaSeparated',
  },
  {
    value: KeysEnum.DirectCampaignName,
    label: 'Direct Campaign',
    labelPlural: 'Direct Campaigns',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(directCampaignApi.allNamed, ({ id, name }) => ({ value: id, label: name })),
  },
  {
    value: KeysEnum.DirectCampaign,
    label: 'Direct Campaign ID',
    format: 'string',
    filter: 'commaSeparated',
  },
  {
    value: KeysEnum.DspNoBidReason,
    label: 'DSP No Bid Reason',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve(
        ['NO_DSP_RESPONSE', 'NO_SEATS_IN_RESPONSE', 'NO_BIDS_IN_SEAT', 'ALL_BIDS_ARE_INVALID'].map((name) => ({
          value: name,
          label: name
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' '),
        }))
      ),
  },
  {
    value: KeysEnum.DspErrorReason,
    label: 'DSP Error Reason',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve(
        [
          'DSP_BAD_REQUEST',
          'DSP_INTERNAL_ERROR',
          'DSP_CONNECTION_REFUSED',
          'INVALID_ENDPOINT',
          'DSP_ERROR',
          'DSP_TIMEOUT',
          'DSP_CONNECTION_CLOSED',
          'UNKNOWN',
        ].map((name) => ({
          value: name,
          label: name
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' '),
        }))
      ),
  },
  {
    value: KeysEnum.DspTaxonomyVersion,
    label: 'DSP Taxonomy Version',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve([
        { value: '1.0', label: '1.0' },
        { value: '2.2', label: '2.2' },
      ]),
  },
  {
    value: KeysEnum.DspCategories,
    label: 'DSP Category Names',
    format: 'string',
    filter: 'taxonomy',
  },
  {
    value: KeysEnum.Hour,
    label: 'Hour',
    format: 'string',
    filter: 'hoursRange',
  },
  {
    value: KeysEnum.Os,
    label: 'OS',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve(
        ['Android', 'iOS', 'Windows', 'MacOS', 'Ubuntu', 'Other', 'Unknown'].map((name) => ({
          value: name,
          label: name,
        }))
      ),
  },
  {
    value: KeysEnum.Browser,
    label: 'Browser',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve(
        [
          'Chrome',
          'Firefox',
          'Opera',
          'Safari',
          'Safari UIWebView',
          'Edge',
          'Android',
          'Android WebView',
          'Samsung',
          'Samsung Mobile',
          'Samsung WebView',
          'Other',
          'Unknown',
        ].map((name) => ({
          value: name,
          label: name,
        }))
      ),
  },
  {
    value: KeysEnum.PublisherName,
    label: 'Publisher',
    labelPlural: 'Publishers',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(publisherApi.listAll, ({ id, name }) => ({ value: id, label: name })),
  },
  {
    value: KeysEnum.Publisher,
    label: 'Publisher ID',
    format: 'string',
    filter: 'commaSeparated',
  },
  {
    value: KeysEnum.SiteName,
    label: 'Site/App',
    labelPlural: 'Sites/Apps',
    format: 'string',
    filter: 'select',
    getFilterOptions: optionsGenerator(siteApi.listAll, ({ id, name }) => ({ value: id, label: name })),
  },
  {
    value: KeysEnum.Site,
    label: 'Site/App ID',
    format: 'string',
    filter: 'commaSeparated',
  },
  {
    value: KeysEnum.SiteTaxonomyVersion,
    label: 'Site Taxonomy Version',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve([
        { value: '1.0', label: '1.0' },
        { value: '2.2', label: '2.2' },
      ]),
  },
  {
    value: KeysEnum.SiteCategories,
    label: 'Site Category Names',
    format: 'string',
    filter: 'taxonomy',
  },
  {
    value: KeysEnum.PublisherUserIdType,
    label: 'Publisher User ID Type',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve([
        { value: 'No User ID', label: 'No User ID' },
        { value: 'New User ID', label: 'New User ID' },
        { value: 'Existing User ID', label: 'Existing User ID' },
      ]),
  },
  {
    value: KeysEnum.DspUserIdType,
    label: 'DSP User ID Type',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve([
        { value: 'No Buyer ID', label: 'No Buyer ID' },
        { value: 'Existing Buyer ID', label: 'Existing Buyer ID' },
      ]),
  },
  {
    value: KeysEnum.EventErrorReason,
    label: 'Event Error Reason',
    format: 'string',
    filter: 'select',
    getFilterOptions: () =>
      Promise.resolve(
        [
          'Duplicate event',
          'Incorrect price',
          'Invalid tracking value',
          'No notification to DSP',
          'Aerospike connection failed',
          'No value for the tracking key or value has expired',
          'Internal Server Error',
        ].map((name) => ({ value: name, label: name }))
      ),
  },
  {
    value: KeysEnum.ErrorStatus,
    label: 'Player Error Status',
    format: 'string',
    filter: 'commaSeparated',
  },
]
