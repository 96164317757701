import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { PublisherForm } from 'views/publisher/form/PublisherForm'
import { PublisherList } from 'views/publisher/PublisherList'

export const PublisherRoot: React.FC = () => (
  <Switch>
    <Route path={routes.publisher.list('forRoute')} component={PublisherList} />
    <Route path={routes.publisher.create} component={PublisherForm} />
    <Route path={routes.publisher.edit()} component={PublisherForm} />
    <Redirect from="*" to={routes.publisher.list()} />
  </Switch>
)
