import React, { useEffect, useState } from 'react'

import { FilterEntry, StatsFilter } from 'api/base/api'

import { getUniqueId } from 'utils/getUniqueId'

import { FormEntityList, FormSelect } from 'components/form'

import { ReportFilterInput } from 'views/report/form/ReportFilterInput'
import { reportKeys } from 'views/report/utils/reportKeys'
import { ReportFilterOption } from 'views/report/utils/reportTypes'

const { TypeEnum } = StatsFilter

interface Props {
  filters: FilterEntry[]
  setFilters: (filters: FilterEntry[]) => void
}

export const ReportFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const [filterSelectOptions, setFilterSelectOptions] = useState<{ [key: string]: ReportFilterOption[] }>({})

  useEffect(() => {
    reportKeys.forEach((key) => {
      if (key.filter === 'select') {
        key
          .getFilterOptions?.()
          .then((options) =>
            setFilterSelectOptions((filterSelectOptions) => ({ ...filterSelectOptions, [key.value]: options }))
          )
      }
    })
  }, [])

  const getFilterType = (filterKey: FilterEntry.KeysEnum) => {
    const reportKey = reportKeys.find(({ value }) => value === filterKey)

    switch (reportKey?.filter) {
      case 'boolean':
        return TypeEnum.EQUAL

      case 'range':
        return TypeEnum.RANGE

      case 'hoursRange':
        return TypeEnum.HOUR

      case 'taxonomy':
        return TypeEnum.INTERSECTIONWITHLIST

      default:
        return TypeEnum.INLIST
    }
  }

  return (
    <FormEntityList
      label="Filters"
      list={filters}
      idKey={(filter) => filter.keys?.[0] ?? getUniqueId()}
      updateList={(list) => setFilters(list)}
      getNewEntity={() => ({ keys: [] })}
      renderRow={(filter, index) => (
        <>
          <FormSelect
            span={5}
            placeholder="Please select"
            value={filter.keys?.[0]}
            options={reportKeys
              .filter((reportKey) => reportKey.filter)
              .map((reportKey) => ({
                value: reportKey.value,
                label: reportKey.label,
                disabled: filters.some((filter) => filter.keys?.[0] === reportKey.value),
              }))}
            onChange={(filterKey) => {
              const newFilters = [...filters]
              newFilters[index] = {
                keys: [filterKey],
                filter: { type: getFilterType(filterKey), params: [] },
              }
              setFilters(newFilters)
            }}
            selectProps={{ showSearch: true }}
          />

          <ReportFilterInput
            filter={filter}
            setFilter={(filter) => {
              const newFilters = [...filters]
              newFilters[index] = filter
              setFilters(newFilters)
            }}
            filterSelectOptions={filterSelectOptions}
          />
        </>
      )}
    />
  )
}
