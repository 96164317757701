import { InfoControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { cacheApiMethod } from 'utils/cacheApiMethod'

class GeneralInfoApi {
  api = new InfoControllerApi(configuration, configuration.basePath, customFetch)

  allTimeZones = () => cacheApiMethod('time-zones', () => this.api.getTimeZones())
}

export const generalInfoApi = new GeneralInfoApi()
