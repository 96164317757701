import { CheckboxOptionType, Col, Form, Radio } from 'antd'
import React from 'react'

interface Props<T> {
  span?: string | number
  label?: string
  value?: T
  onChange: (value: T) => void
  options: CheckboxOptionType[]
  required?: boolean
  disabled?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  radioGroupProps?: React.ComponentProps<typeof Radio.Group>
}

export const FormRadioGroup = <T,>({
  span,
  label,
  value,
  onChange,
  options,
  required,
  disabled,
  colProps,
  itemProps,
  radioGroupProps,
}: Props<T>) => (
  <Col span={span} {...colProps}>
    <Form.Item label={label} required={required} {...itemProps}>
      <Radio.Group
        optionType="button"
        value={value}
        onChange={(e) => onChange(e.target.value as T)}
        options={options}
        disabled={disabled}
        {...radioGroupProps}
      />
    </Form.Item>
  </Col>
)
