import { ExclamationCircleFilled } from '@ant-design/icons'
import { Card, Col, Divider, Row } from 'antd'
import React from 'react'

import { PaymentBeanInList, PublisherBean } from 'api/base/api'

import { paymentAdjustmentReasonToName } from 'utils/enumToName'

const { UserCurrencyEnum } = PublisherBean

interface Props {
  payment: PaymentBeanInList
  currency: PublisherBean.UserCurrencyEnum
  currencySign: string
  convertCurrency: (usdAmount: number | undefined, krwRate: number | undefined) => number
}

export const PaymentListExpandable: React.FC<Props> = ({ payment, currency, currencySign, convertCurrency }) => {
  function format(value: number) {
    return Intl.NumberFormat('en-US', {
      maximumFractionDigits: 4,
      currency: currency.toString(),
      style: 'currency',
    }).format(value)
  }

  return (
    <Row gutter={10}>
      <Col xs={12}>
        <Card title={`Adjustments (${currency})`} size="small">
          {payment.adjustments?.map((adjustment) => (
            <React.Fragment key={adjustment.id}>
              <Row justify="space-between">
                <Col>
                  {paymentAdjustmentReasonToName(adjustment.reason)}
                  {adjustment.dspName && ` identified by ${adjustment.dspName}`}
                </Col>
                <Col>{format(convertCurrency(adjustment.amountUSD, payment.rateKRW))}</Col>
              </Row>
              <Divider style={{ margin: 3 }} />
            </React.Fragment>
          ))}

          <Row justify="space-between" style={{ fontWeight: 'bold' }}>
            <Col>Total Adjustments ({currency})</Col>
            <Col>
              {format(
                payment.adjustments?.reduce((acc, item) => acc + convertCurrency(item.amountUSD, payment.rateKRW), 0) ??
                  0
              )}
            </Col>
          </Row>
        </Card>
      </Col>

      <Col xs={12}>
        <Card title={`Adjusted Net Revenue (${currency})`} size="small">
          <Row justify="space-between">
            <Col>Net revenue</Col>
            <Col>
              {currencySign}
              {currency === UserCurrencyEnum.USD ? payment.calculatedCostUSD : payment.calculatedCostKRW}
            </Col>
          </Row>
          <Divider style={{ margin: 3 }} />
          <Row justify="space-between">
            <Col>Adjustments</Col>
            <Col>
              {format(
                payment.adjustments?.reduce((acc, item) => acc + convertCurrency(item.amountUSD, payment.rateKRW), 0) ??
                  0
              )}
            </Col>
          </Row>
          <Divider style={{ margin: 3 }} />
          <Row justify="space-between" style={{ fontWeight: 'bold' }}>
            <Col>
              <div>Total Adjusted net revenue ({currency})</div>
              <div style={{ fontSize: 11, fontWeight: 'normal' }}>
                <ExclamationCircleFilled /> This amount isn&apos;t final until the payment has been issued.
              </div>
            </Col>
            <Col>
              {currencySign}
              {currency === UserCurrencyEnum.USD ? payment.finalCostUSD : payment.finalCostKRW}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}
