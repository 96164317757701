import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SavedReportBean, StatRequestBean } from 'api/base/api'
import { reportApi } from 'api/report'

import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import { FormRow, FormSelect, FormSwitch, FormTextInput } from 'components/form'

interface Props {
  savedReport: Partial<SavedReportBean>
  setSavedReport: (savedReport: Partial<SavedReportBean>) => void
  formState: Partial<StatRequestBean>
}

export const SavedReportSelect: React.FC<Props> = ({ savedReport, setSavedReport, formState }) => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [reportList, { refreshList }] = useGetList(reportApi.listAll)

  const edit = () => {
    reportApi
      .saveReport({ ...savedReport, statRequestBeanJson: JSON.stringify(formState) } as SavedReportBean)
      .then((response) => {
        refreshList()
        setIsModalOpen(false)
        history.push(routes.report.root(response.id))
      })
  }

  const create = () => {
    reportApi
      .saveReport({ ...savedReport, id: undefined, statRequestBeanJson: JSON.stringify(formState) } as SavedReportBean)
      .then((response) => {
        refreshList()
        setIsModalOpen(false)
        history.push(routes.report.root(response.id))
      })
  }

  return (
    <>
      <FormRow style={{ alignItems: 'center' }}>
        <FormSelect
          span={6}
          label="Saved Reports"
          value={savedReport?.id}
          onChange={(id) => history.push(routes.report.root(id === -1 ? undefined : id))}
          selectProps={{ showSearch: true, filterSort: undefined }}
          options={[
            { value: -1, label: 'Create new report' },
            ...reportList
              .map((report) => ({ value: report.id, label: report.name }))
              .sort((a, b) => ((a.label?.toLowerCase() as string) > (b.label?.toLowerCase() as string) ? 1 : -1)),
          ]}
        />

        <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ marginTop: 3 }}>
          Save report
        </Button>
      </FormRow>

      <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} style={{ minWidth: '50%' }} footer={null}>
        <FormRow>
          <FormTextInput
            span={12}
            label="Report Name"
            required
            value={savedReport.name}
            onChange={(name) => setSavedReport({ name })}
          />

          <FormSwitch
            span={4}
            label="Favorite"
            checked={savedReport.favorite}
            onChange={() => setSavedReport({ favorite: !savedReport.favorite })}
            colProps={{ style: { paddingLeft: 30 } }}
          />

          <FormSwitch
            span={4}
            label="Shared"
            checked={savedReport.shared}
            onChange={() => setSavedReport({ shared: !savedReport.shared })}
            colProps={{ style: { paddingLeft: 30 } }}
          />
        </FormRow>

        <FormRow>
          {savedReport.id !== -1 && (
            <Button type="primary" onClick={edit}>
              Save
            </Button>
          )}
          <Button type="primary" onClick={create} style={{ marginLeft: 8 }}>
            {savedReport.id === -1 ? 'Create new report' : 'Save as new'}
          </Button>
        </FormRow>
      </Modal>
    </>
  )
}
