import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { adSizeApi } from 'api/adSize'
import { AdSizeBean } from 'api/base/api'

import { useGetList } from 'utils/useGetList'

import { useFormState } from 'components/form'

export function useAdSizeFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<AdSizeBean>()
  const [adSizeList] = useGetList(() => adSizeApi.listAll())

  useEffect(() => {
    if (id) {
      adSizeApi.entity(+id).then((bean) => {
        setFormName(bean.name ?? '')
        setFormState(bean)
      })
    } else {
      setFormState({
        active: true,
      })
    }
  }, [])

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
    adSizeList,
  }
}
