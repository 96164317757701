import { Col } from 'antd'
import React from 'react'

import { NamedBean, TypedDspIdMappingBean } from 'api/base/api'

import { mappingTypeToName } from 'utils/enumToName'

import { FormEntityList } from 'components/form/inputs/FormEntityList'
import { FormSelect } from 'components/form/inputs/FormSelect'
import { FormTextInput } from 'components/form/inputs/FormTextInput'

import DspIdMappingCreativeTypeEnum = TypedDspIdMappingBean.DspIdMappingCreativeTypeEnum

interface Props {
  entityId?: number
  dspList: NamedBean[]
  mappingList: TypedDspIdMappingBean[]
  setMappingList: (value: TypedDspIdMappingBean[]) => void
}

export const FormTypedDspMappingTab: React.FC<Props> = ({ mappingList, dspList, entityId, setMappingList }) => {
  const creativeTypeOptions = [
    DspIdMappingCreativeTypeEnum.ALL,
    DspIdMappingCreativeTypeEnum.BANNER,
    DspIdMappingCreativeTypeEnum.VIDEO,
    DspIdMappingCreativeTypeEnum.NATIVE,
  ]

  return (
    <FormEntityList
      label={
        <>
          <Col span={6} style={{ marginBottom: 10 }}>
            DSP
          </Col>
          <Col span={6} style={{ marginBottom: 10 }}>
            Creative Type
          </Col>
          <Col span={4} style={{ marginBottom: 10 }}>
            ID
          </Col>
        </>
      }
      list={mappingList}
      updateList={(newMapping) => setMappingList(newMapping)}
      getNewEntity={() => ({ entityId, dspId: undefined, entityIdInDsp: '' })}
      renderRow={(dspMapping, index) => (
        <>
          <FormSelect
            span={6}
            value={dspMapping.dspId}
            onChange={(dspId) =>
              setMappingList(mappingList.map((mapping, i) => (i === index ? { ...dspMapping, dspId } : mapping)))
            }
            options={dspList
              .filter((dsp) => {
                const isNotSelectedInMapping = !mappingList.some(
                  (mapping) =>
                    mapping.dspId === dsp.id &&
                    (mapping.dspIdMappingCreativeType === DspIdMappingCreativeTypeEnum.ALL ||
                      mapping.dspIdMappingCreativeType === dspMapping.dspIdMappingCreativeType)
                )
                return dsp.id === dspMapping.dspId || isNotSelectedInMapping
              })
              .map((dsp) => ({ value: dsp.id, label: dsp.name }))}
          />

          <FormSelect
            span={6}
            value={dspMapping.dspIdMappingCreativeType}
            onChange={(dspIdMappingCreativeType) =>
              setMappingList(
                mappingList
                  .map((mapping, i) => (i === index ? { ...dspMapping, dspIdMappingCreativeType } : mapping))
                  .filter(
                    (mapping) =>
                      dspIdMappingCreativeType !== DspIdMappingCreativeTypeEnum.ALL ||
                      !(
                        mapping.dspId === dspMapping.dspId &&
                        mapping.dspIdMappingCreativeType !== DspIdMappingCreativeTypeEnum.ALL
                      )
                  )
              )
            }
            options={creativeTypeOptions
              .filter(
                (mappingType) =>
                  !mappingList.some(
                    (mapping) =>
                      mapping.dspId === dspMapping.dspId &&
                      mapping.dspIdMappingCreativeType === mappingType &&
                      !(mappingType === dspMapping.dspIdMappingCreativeType)
                  )
              )
              .map((mappingType) => ({ value: mappingType, label: mappingTypeToName(mappingType) }))}
          />

          <FormTextInput
            span={4}
            value={dspMapping.entityIdInDsp}
            onChange={(entityIdInDsp) =>
              setMappingList(
                mappingList.map((mapping, i) =>
                  i === index
                    ? {
                        ...dspMapping,
                        entityIdInDsp,
                      }
                    : mapping
                )
              )
            }
          />
        </>
      )}
    />
  )
}
