import { Select } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import React, { useEffect, useRef, useState } from 'react'

interface Props<Value> {
  value: Value
  onChange: (value: Value) => void
  getOptions: () => Promise<{ text: string; value: Value }[]>
  filterProps: FilterDropdownProps
  allowClear?: boolean
}

export const SelectFilter = <Value extends string | number | boolean | undefined>({
  value,
  onChange,
  getOptions,
  filterProps,
  allowClear,
}: Props<Value>) => {
  const [options, setOptions] = useState<{ text: string; value: Value }[]>([])
  const wasRequestedRef = useRef(false)

  useEffect(() => {
    if (filterProps.visible && !wasRequestedRef.current) {
      wasRequestedRef.current = true
      getOptions().then(setOptions)
    }
  }, [filterProps.visible])

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Select
        value={value}
        onChange={(e: Value) => {
          onChange(e)
          filterProps.close()
        }}
        placeholder="Filter"
        showSearch
        allowClear={allowClear}
        style={{ minWidth: 180 }}
        filterOption={(input, option) =>
          ((option?.children as unknown as string) ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        {options.map(({ text, value }) => (
          <Select.Option key={text} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}
