import { BaseOptionType } from 'antd/es/select'

export const sortAdSize = (a: BaseOptionType, b: BaseOptionType) => {
  const aLeft = Number(a.label.toString().split('x')[0])
  const aRight = Number(a.label.toString().split('x')[1])
  const bLeft = Number(b.label.toString().split('x')[0])
  const bRight = Number(b.label.toString().split('x')[1])

  if (aLeft === bLeft) return aRight < bRight ? -1 : 1
  return aLeft < bLeft ? -1 : 1
}
